import * as fromTemplates from "../actions/template.actions";
import { ITemplate } from "../../services/uwl/template.service";

export const FEATURE_KEY = "templates";

export interface TemplateState {
  entities: { [id: string]: ITemplate };
  loading: boolean;
  loaded: boolean;
}

export const initialState: TemplateState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromTemplates.TemplateActions,
): TemplateState {
  switch (action.type) {
    case fromTemplates.DELETE_TEMPLATE:
    case fromTemplates.UPDATE_TEMPLATE:
    case fromTemplates.CREATE_TEMPLATE:
    case fromTemplates.LOAD_TEMPLATES: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromTemplates.LOAD_TEMPLATES_SUCCESS: {
      const templates = action.payload;
      const entities = templates.reduce(
        (entities: { [id: string]: ITemplate }, template: ITemplate) => {
          return {
            ...entities,
            [template.name]: template,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromTemplates.UPDATE_TEMPLATE_SUCCESS:
    case fromTemplates.CREATE_TEMPLATE_SUCCESS: {
      const template = action.payload;
      const entities = {
        ...state.entities,
        [template.name]: template,
      };
      return {
        ...state,
        entities,
        loading: false,
        loaded: true,
      };
    }

    case fromTemplates.DELETE_TEMPLATE_SUCCESS: {
      const template = action.payload;
      const { [template.name]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
      };
    }

    case fromTemplates.DELETE_TEMPLATE_FAIL:
    case fromTemplates.UPDATE_TEMPLATE_FAIL:
    case fromTemplates.CREATE_TEMPLATE_FAIL:
    case fromTemplates.LOAD_TEMPLATES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getTemplateEntities = (state: TemplateState) => state.entities;
export const getTemplatesLoading = (state: TemplateState) => state.loading;
export const getTemplatesLoaded = (state: TemplateState) => state.loaded;
