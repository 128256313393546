import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { GematikTaskDto } from "gematik-task-api";
import { TaskTableComponent } from "../components/task-table/task-table.component";
import { Observable, Subscription } from "rxjs";
import { GematikTask } from "../../models/gematik-task.model";

import { Store } from "@ngrx/store";
import * as fromStore from "../../store";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-tasks-page",
  templateUrl: "./tasks-page.component.html",
  styleUrls: ["./tasks-page.component.scss"],
})
export class TasksPageComponent implements OnInit, OnDestroy {
  @ViewChild("taskTable") eventTable: TaskTableComponent;

  tasksAsync: GematikTaskDto[];
  completedTasksAsync: GematikTaskDto[];
  tasks: GematikTaskDto[];
  completedTasks: GematikTaskDto[] = [];
  selectedTask: GematikTaskDto;
  componentName = "TasksPageComponent";
  taskTarget: string;
  title: string;

  showButtons = true;
  task: any;

  activeTab: boolean = true;

  form: FormGroup = this.fb.group({
    searchStr: [""],
  });

  type: string;

  subscriptions: Subscription[] = [];

  openTasks$: Observable<GematikTaskDto[]>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromStore.UwlState>,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("taskTarget")) {
          this.taskTarget = paramMap.get("taskTarget");
          this.initTasks();
        }
      }),
    );
  }

  private initTasks() {
    if (this.activeTab) {
      if (this.taskTarget === "my") {
        this.type = "my";
        this.openTasks$ = this.store.select(fromStore.getOpenMyTasks);
      } else {
        this.type = "group";
        this.openTasks$ = this.store.select(fromStore.getOpenGroupTasks);
      }
    } else if (!this.activeTab) {
      if (this.taskTarget === "my") {
        this.type = "my";
        this.store.dispatch(new fromStore.LoadMyCompletedTasks());
        this.subscriptions.push(
          this.store.select(fromStore.getMyCompletedTasks).subscribe((tasks) => {
            this.mapTasks(tasks);
          }),
        );
      } else {
        this.type = "group";
        this.store.dispatch(new fromStore.LoadGroupCompletedTasks());
        this.subscriptions.push(
          this.store.select(fromStore.getGroupCompletedTasks).subscribe((tasks) => {
            this.mapTasks(tasks);
          }),
        );
      }
    }

    this.title = "uwl-tasks-" + this.taskTarget;
  }

  private mapTasks(tasks: GematikTask[]) {
    const tasksArr: GematikTask[] = [];
    tasks.forEach((task) => {
      const t: GematikTask = {
        ...task,
        outcome: task.outcome || "task_outcome_SUBMIT",
      };
      tasksArr.push(t);
    });
    this.completedTasks = tasksArr;
  }

  refreshTasks(event: any) {}

  getTaskIdFilter() {
    return this.taskTarget;
  }

  openFormForTask(task: any, taskTarget: string) {}

  clicked(event: any) {
    this.activeTab = event.index;
    window.scroll(0, 0);
    this.initTasks();
  }

  onToggleView(): void {
    this.activeTab = !this.activeTab;
    window.scroll(0, 0);
    this.initTasks();
  }

  onClearFilter(event: any): void {
    this.searchStr.setValue("");
  }

  get searchStr(): FormControl {
    return this.form.get("searchStr") as FormControl;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
