import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

// store
import { Store } from "@ngrx/store";
import * as fromStore from "../../store";

import { GematikIncident } from "../../models/gematik-incident.model";

@Component({
  selector: "incidents-page",
  templateUrl: "./incidents-page.component.html",
  styleUrls: ["./incidents-page.component.scss"],
})
export class IncidentsPageComponent implements OnInit {
  incidents$: Observable<GematikIncident[]>;
  title: string;

  constructor(private store: Store<fromStore.UwlState>) {}

  ngOnInit(): void {
    this.title = "incidents";
    this.incidents$ = this.store.select(fromStore.getIncidents);
  }
}
