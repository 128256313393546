import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { Alert } from "gematik-shared";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getAll(): Observable<Alert[]> {
    return this.http.get<Alert[]>(this.env.backendUrl + "/uwl/alerts");
  }

  create(alert: Alert): Observable<Alert> {
    return this.http.post<Alert>(this.env.backendUrl + "/uwl/alerts", alert);
  }

  update(alert: Alert): Observable<Alert> {
    return this.http.put<Alert>(`${this.env.backendUrl}/uwl/alerts/${alert.id}`, alert);
  }

  delete(alert: Alert): Observable<Alert> {
    return this.http.delete<Alert>(`${this.env.backendUrl}/uwl/alerts/${alert.id}`);
  }
}
