import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromMyRight from "../reducers/my-rights.reducer";

export const getMyRightsState = createFeatureSelector<fromMyRight.MyRightState>(
  fromMyRight.FEATURE_KEY,
);

// My Applications
export const getMyApplicationsEntities = createSelector(
  getMyRightsState,
  fromMyRight.getMyApplicationsEntities,
);

export const getMyEnabledApplications = createSelector(getMyApplicationsEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .filter((myApplication) => !myApplication.disabled)
    .sort((a, b) => a.index - b.index);
});

export const getMyApplications = createSelector(getMyApplicationsEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .sort((a, b) => a.index - b.index);
});

// My Bookmarks
export const getMyBookmarksEntities = createSelector(
  getMyRightsState,
  fromMyRight.getMyBookmarksEntities,
);

export const getMyEnabledBookmarks = createSelector(getMyBookmarksEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .filter((myBookmark) => !myBookmark.disabled)
    .sort((a, b) => a.index - b.index);
});

export const getMyBookmarks = createSelector(getMyBookmarksEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .sort((a, b) => a.index - b.index);
});
