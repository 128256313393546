<h1 mat-dialog-title>
  {{
    (data.dto.name | translate) +
      " " +
      ("processName" | translate) +
      " - " +
      ("buid" | translate) +
      ": " +
      data.dto.buid
  }}
</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 right">
      <a (click)="openHelpDialog(data.dto.processDocumentationUrl)"
        ><mat-icon
          class="material-icons"
          *ngIf="data.dto.processDocumentationUrl"
          svgIcon="signpost"
          [ngbTooltip]="'tooltip_process_documentation_link' | translate"
          placement="left"
        ></mat-icon
      ></a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 center">
      <gem-bpmn-diagram [diagram]="data.diagram"></gem-bpmn-diagram>
    </div>
  </div>
  <div class="row"><div class="col-sm-12 center">&nbsp;</div></div>
  <div class="row">
    <div class="col-sm-12 center">
      <p>
        {{ "partner" | translate }}: {{ data.dto.partner + "&nbsp;|&nbsp;" }}
        {{ "partnerType" | translate }}: {{ data.dto.partnerType + "&nbsp;|&nbsp;" }}
        {{ "partnerRole" | translate }}: {{ data.dto.role }}
      </p>
      <div class="d-flex justify-content-center">
        <div>
          {{
            data.dto.customProcessContentOneLabel
              ? data.dto.customProcessContentOneLabel
              : ("customContentOne" | translate)
          }}:&nbsp;
        </div>
        <div
          [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.dto.customProcessContentOne)"
        ></div>
        <div>
          &nbsp;|&nbsp;{{
            data.dto.customProcessContentTwoLabel
              ? data.dto.customProcessContentTwoLabel
              : ("customContentTwo" | translate)
          }}:&nbsp;
        </div>
        <div
          [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.dto.customProcessContentTwo)"
        ></div>
        <div>
          &nbsp;|&nbsp;{{
            data.dto.customProcessContentThreeLabel
              ? data.dto.customProcessContentThreeLabel
              : ("customContentThree" | translate)
          }}:&nbsp;
        </div>
        <div
          [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.dto.customProcessContentThree)"
        ></div>
      </div>
    </div>
  </div>
  <div class="row"><div class="col-sm-12 center">&nbsp;</div></div>
  <div class="row">
    <div class="col-sm-12 left">
      <h5>{{ "legende" | translate }}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 left">
      <img class="bpmn-icon-legend" src="/gematik-shared/assets/images/bpmn/bpmn-activity.png" />
      {{ "current_process_step" | translate }}
    </div>
    <div class="col-sm-12 left">
      <img
        class="bpmn-icon-legend"
        src="/gematik-shared/assets/images/bpmn/bpmn-activity-incident.png"
      />
      {{ "incident_process_step" | translate }}
    </div>
  </div>
</div>
<div mat-dialog-actions class="mb-2">
  <button class="btn btn-primary" (click)="confirmAction()" cdkFocusInitial>Ok</button>
</div>
