import { Action } from "@ngrx/store";
import { GematikTask } from "../../models/gematik-task.model";
import { GematikTaskTotals } from "../../models/gematik-task-totals.model";

// Load All Open Tasks
export const LOAD_ALL_OPEN_TASKS = "[Tasks] Load All Open Tasks";
export const LOAD_ALL_OPEN_TASKS_SUCCESS = "[Tasks] Load All Open Tasks Success";
export const LOAD_ALL_OPEN_TASKS_FAIL = "[Tasks] Load All Open Tasks Fail";

export class LoadAllOpenTasks implements Action {
  readonly type = LOAD_ALL_OPEN_TASKS;
}

export class LoadAllOpenTasksSuccess implements Action {
  readonly type = LOAD_ALL_OPEN_TASKS_SUCCESS;
  constructor(public payload: { groupTasks: GematikTask[]; myTasks: GematikTask[] }) {}
}

export class LoadAllOpenTasksFail implements Action {
  readonly type = LOAD_ALL_OPEN_TASKS_FAIL;
  constructor(public payload: any) {}
}

// Fetch Specific Open Tasks
export const LOAD_OPEN_TASKS = "[Tasks] Load Open Tasks";
export const LOAD_OPEN_TASKS_SUCCESS = "[Tasks] Load Open Tasks Success";
export const LOAD_OPEN_TASKS_FAIL = "[Tasks] Load Open Tasks Fail";

export class LoadOpenTasks implements Action {
  readonly type = LOAD_OPEN_TASKS;
  constructor(public payload: string) {}
}

export class LoadOpenTasksSuccess implements Action {
  readonly type = LOAD_OPEN_TASKS_SUCCESS;
  constructor(
    public payload: { groupTasks: GematikTask[]; myTasks: GematikTask[] },
    public backendUrl: string,
  ) {}
}

export class LoadOpenTasksFail implements Action {
  readonly type = LOAD_OPEN_TASKS_FAIL;
  constructor(public payload: any) {}
}

// Load My Completed Tasks
export const LOAD_MY_COMPLETED_TASKS = "[Tasks] Load My Completed Tasks";
export const LOAD_MY_COMPLETED_TASKS_SUCCESS = "[Tasks] Load My Completed Tasks Success";
export const LOAD_MY_COMPLETED_TASKS_FAIL = "[Tasks] Load My Completed Tasks Fail";

export class LoadMyCompletedTasks implements Action {
  readonly type = LOAD_MY_COMPLETED_TASKS;
}

export class LoadMyCompletedTasksSuccess implements Action {
  readonly type = LOAD_MY_COMPLETED_TASKS_SUCCESS;
  constructor(public payload: { groupTasks: GematikTask[]; myTasks: GematikTask[] }) {}
}

export class LoadMyCompletedTasksFail implements Action {
  readonly type = LOAD_MY_COMPLETED_TASKS_FAIL;
  constructor(public payload: any) {}
}

// Load Group Completed Tasks
export const LOAD_GROUP_COMPLETED_TASKS = "[Tasks] Load Group Completed Tasks";
export const LOAD_GROUP_COMPLETED_TASKS_SUCCESS = "[Tasks] Load Group Completed Tasks Success";
export const LOAD_GROUP_COMPLETED_TASKS_FAIL = "[Tasks] Load Group Completed Tasks Fail";

export class LoadGroupCompletedTasks implements Action {
  readonly type = LOAD_GROUP_COMPLETED_TASKS;
}

export class LoadGroupCompletedTasksSuccess implements Action {
  readonly type = LOAD_GROUP_COMPLETED_TASKS_SUCCESS;
  constructor(public payload: { groupTasks: GematikTask[]; myTasks: GematikTask[] }) {}
}

export class LoadGroupCompletedTasksFail implements Action {
  readonly type = LOAD_GROUP_COMPLETED_TASKS_FAIL;
  constructor(public payload: any) {}
}

// Totals
export const LOAD_TASK_TOTALS = "[Tasks] Load Task Totals";
export const LOAD_TASK_TOTALS_SUCCESS = "[Tasks] Load Task Totals Success";
export const LOAD_TASK_TOTALS_FAIL = "[Tasks] Load Task Totals Fail";

export class LoadTaskTotals implements Action {
  readonly type = LOAD_TASK_TOTALS;
}

export class LoadTaskTotalsSuccess implements Action {
  readonly type = LOAD_TASK_TOTALS_SUCCESS;
  constructor(public payload: GematikTaskTotals) {}
}

export class LoadTaskTotalsFail implements Action {
  readonly type = LOAD_TASK_TOTALS_FAIL;
  constructor(public payload: any) {}
}

export type TaskActions =
  | LoadAllOpenTasks
  | LoadAllOpenTasksSuccess
  | LoadAllOpenTasksFail
  | LoadOpenTasks
  | LoadOpenTasksSuccess
  | LoadOpenTasksFail
  | LoadMyCompletedTasks
  | LoadMyCompletedTasksSuccess
  | LoadMyCompletedTasksFail
  | LoadGroupCompletedTasks
  | LoadGroupCompletedTasksSuccess
  | LoadGroupCompletedTasksFail
  | LoadTaskTotals
  | LoadTaskTotalsSuccess
  | LoadTaskTotalsFail;
