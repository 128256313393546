import { Action } from "@ngrx/store";
import {
  SuiteCrmAssignmentGroupIdentity,
  SuiteCrmPartner,
  SuiteCrmPartnerAddress,
  SuiteCrmPartnerAddressMail,
  SuiteCrmPartnerAddressPhone,
} from "gematik-shared";

// Load Assignment Group Identities
export const LOAD_ASSIGNMENT_GROUP_IDENTITIES =
  "[Personal Profile] Load Assignment Group Identities";
export const LOAD_ASSIGNMENT_GROUP_IDENTITIES_SUCCESS =
  "[Personal Profile] Load Assignment Group Identities Success";
export const LOAD_ASSIGNMENT_GROUP_IDENTITIES_FAIL =
  "[Personal Profile] Load Assignment Group Identities Fail";

export class LoadAssignmentGroupIdentities implements Action {
  readonly type = LOAD_ASSIGNMENT_GROUP_IDENTITIES;
}

export class LoadAssignmentGroupIdentitiesSuccess implements Action {
  readonly type = LOAD_ASSIGNMENT_GROUP_IDENTITIES_SUCCESS;
  constructor(public payload: SuiteCrmAssignmentGroupIdentity[]) {}
}

export class LoadAssignmentGroupIdentitiesFail implements Action {
  readonly type = LOAD_ASSIGNMENT_GROUP_IDENTITIES_FAIL;
  constructor(public payload: any) {}
}

// Load Assignment Group Identity Addresses
export const LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES =
  "[Personal Profile] Load Assignment Group Identity Addresses";
export const LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES_SUCCESS =
  "[Personal Profile] Load Assignment Group Identity Addresses Success";
export const LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES_FAIL =
  "[Personal Profile] Load Assignment Group Identity Addresses Fail";

export class LoadAssignmentGroupIdentityAddresses implements Action {
  readonly type = LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES;
  constructor(public agiId: string) {}
}

export class LoadAssignmentGroupIdentityAddressesSuccess implements Action {
  readonly type = LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES_SUCCESS;
  constructor(public payload: SuiteCrmPartnerAddress[], public agiId: string) {}
}

export class LoadAssignmentGroupIdentityAddressesFail implements Action {
  readonly type = LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES_FAIL;
  constructor(public payload: any) {}
}

// Create Assignment Group Identity Address
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS =
  "[Personal Profile] Create Assignment Group Identity Address";
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS =
  "[Personal Profile] Create Assignment Group Identity Address Success";
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL =
  "[Personal Profile] Create Assignment Group Identity Address Fail";

export class CreateAssignmentGroupIdentityAddress implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS;
  constructor(public payload: SuiteCrmPartnerAddress, public agiId: string) {}
}

export class CreateAssignmentGroupIdentityAddressSuccess implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS;
  constructor(public payload: SuiteCrmPartnerAddress, public agiId: string) {}
}

export class CreateAssignmentGroupIdentityAddressFail implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL;
  constructor(public payload: any) {}
}

// Update Assignment Group Identity Address
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS =
  "[Personal Profile] Update Assignment Group Identity Address";
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS =
  "[Personal Profile] Update Assignment Group Identity Address Success";
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL =
  "[Personal Profile] Update Assignment Group Identity Address Fail";

export class UpdateAssignmentGroupIdentityAddress implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS;
  constructor(public payload: SuiteCrmPartnerAddress, public agiId: string) {}
}

export class UpdateAssignmentGroupIdentityAddressSuccess implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS;
  constructor(public payload: SuiteCrmPartnerAddress, public agiId: string) {}
}

export class UpdateAssignmentGroupIdentityAddressFail implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL;
  constructor(public payload: any) {}
}

// Delete Assignment Group Identity Address
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS =
  "[Personal Profile] Delete Assignment Group Identity Address";
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS =
  "[Personal Profile] Delete Assignment Group Identity Address Success";
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL =
  "[Personal Profile] Delete Assignment Group Identity Address Fail";

export class DeleteAssignmentGroupIdentityAddress implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS;
  constructor(public agiId: string, public addressId: string) {}
}

export class DeleteAssignmentGroupIdentityAddressSuccess implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS;
  constructor(public agiId: string, public addressId: string) {}
}

export class DeleteAssignmentGroupIdentityAddressFail implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL;
  constructor(public payload: any) {}
}

// Create Assignment Group Identity Address Mail
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL =
  "[Personal Profile] Create Assignment Group Identity Address Mail";
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS =
  "[Personal Profile] Create Assignment Group Identity Address Mail Success";
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL =
  "[Personal Profile] Create Assignment Group Identity Address Mail Fail";

export class CreateAssignmentGroupIdentityAddressMail implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public agiId: string,
    public addressId: string,
  ) {}
}

export class CreateAssignmentGroupIdentityAddressMailSuccess implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public agiId: string,
    public addressId: string,
  ) {}
}

export class CreateAssignmentGroupIdentityAddressMailFail implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL;
  constructor(public payload: any) {}
}

// Update Assignment Group Identity Address Mail
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL =
  "[Personal Profile] Update Assignment Group Identity Address Mail";
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS =
  "[Personal Profile] Update Assignment Group Identity Address Mail Success";
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL =
  "[Personal Profile] Update Assignment Group Identity Address Mail Fail";

export class UpdateAssignmentGroupIdentityAddressMail implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public agiId: string,
    public addressId: string,
    public mailId: string,
  ) {}
}

export class UpdateAssignmentGroupIdentityAddressMailSuccess implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public agiId: string,
    public addressId: string,
    public mailId: string,
  ) {}
}

export class UpdateAssignmentGroupIdentityAddressMailFail implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL;
  constructor(public payload: any) {}
}

// Delete Assignment Group Identity Address Mail
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL =
  "[Personal Profile] Delete Assignment Group Identity Address Mail";
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS =
  "[Personal Profile] Delete Assignment Group Identity Address Mail Success";
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL =
  "[Personal Profile] Delete Assignment Group Identity Address Mail Fail";

export class DeleteAssignmentGroupIdentityAddressMail implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL;
  constructor(public agiId: string, public addressId: string, public mailId: string) {}
}

export class DeleteAssignmentGroupIdentityAddressMailSuccess implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS;
  constructor(public agiId: string, public addressId: string, public mailId: string) {}
}

export class DeleteAssignmentGroupIdentityAddressMailFail implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL;
  constructor(public payload: any) {}
}

// Create Assignment Group Identity Address Phone
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE =
  "[Personal Profile] Create Assignment Group Identity Address Phone";
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS =
  "[Personal Profile] Create Assignment Group Identity Address Phone Success";
export const CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL =
  "[Personal Profile] Create Assignment Group Identity Address Phone Fail";

export class CreateAssignmentGroupIdentityAddressPhone implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public agiId: string,
    public addressId: string,
  ) {}
}

export class CreateAssignmentGroupIdentityAddressPhoneSuccess implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public agiId: string,
    public addressId: string,
  ) {}
}

export class CreateAssignmentGroupIdentityAddressPhoneFail implements Action {
  readonly type = CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL;
  constructor(public payload: any) {}
}

// Update Assignment Group Identity Address Phone
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE =
  "[Personal Profile] Update Assignment Group Identity Address Phone";
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS =
  "[Personal Profile] Update Assignment Group Identity Address Phone Success";
export const UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL =
  "[Personal Profile] Update Assignment Group Identity Address Phone Fail";

export class UpdateAssignmentGroupIdentityAddressPhone implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public agiId: string,
    public addressId: string,
    public phoneId: string,
  ) {}
}

export class UpdateAssignmentGroupIdentityAddressPhoneSuccess implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public agiId: string,
    public addressId: string,
    public phoneId: string,
  ) {}
}

export class UpdateAssignmentGroupIdentityAddressPhoneFail implements Action {
  readonly type = UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL;
  constructor(public payload: any) {}
}

// Delete Assignment Group Identity Address Phone
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE =
  "[Personal Profile] Delete Assignment Group Identity Address Phone";
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS =
  "[Personal Profile] Delete Assignment Group Identity Address Phone Success";
export const DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL =
  "[Personal Profile] Delete Assignment Group Identity Address Phone Fail";

export class DeleteAssignmentGroupIdentityAddressPhone implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE;
  constructor(public agiId: string, public addressId: string, public phoneId: string) {}
}

export class DeleteAssignmentGroupIdentityAddressPhoneSuccess implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS;
  constructor(public agiId: string, public addressId: string, public phoneId: string) {}
}

export class DeleteAssignmentGroupIdentityAddressPhoneFail implements Action {
  readonly type = DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL;
  constructor(public payload: any) {}
}

export type PersonalProfileActions =
  | LoadAssignmentGroupIdentities
  | LoadAssignmentGroupIdentitiesSuccess
  | LoadAssignmentGroupIdentitiesFail
  | LoadAssignmentGroupIdentityAddresses
  | LoadAssignmentGroupIdentityAddressesSuccess
  | LoadAssignmentGroupIdentityAddressesFail
  | CreateAssignmentGroupIdentityAddress
  | CreateAssignmentGroupIdentityAddressSuccess
  | CreateAssignmentGroupIdentityAddressFail
  | UpdateAssignmentGroupIdentityAddress
  | UpdateAssignmentGroupIdentityAddressSuccess
  | UpdateAssignmentGroupIdentityAddressFail
  | DeleteAssignmentGroupIdentityAddress
  | DeleteAssignmentGroupIdentityAddressSuccess
  | DeleteAssignmentGroupIdentityAddressFail
  | CreateAssignmentGroupIdentityAddressMail
  | CreateAssignmentGroupIdentityAddressMailSuccess
  | CreateAssignmentGroupIdentityAddressMailFail
  | UpdateAssignmentGroupIdentityAddressMail
  | UpdateAssignmentGroupIdentityAddressMailSuccess
  | UpdateAssignmentGroupIdentityAddressMailFail
  | DeleteAssignmentGroupIdentityAddressMail
  | DeleteAssignmentGroupIdentityAddressMailSuccess
  | DeleteAssignmentGroupIdentityAddressMailFail
  | CreateAssignmentGroupIdentityAddressPhone
  | CreateAssignmentGroupIdentityAddressPhoneSuccess
  | CreateAssignmentGroupIdentityAddressPhoneFail
  | UpdateAssignmentGroupIdentityAddressPhone
  | UpdateAssignmentGroupIdentityAddressPhoneSuccess
  | UpdateAssignmentGroupIdentityAddressPhoneFail
  | DeleteAssignmentGroupIdentityAddressPhone
  | DeleteAssignmentGroupIdentityAddressPhoneSuccess
  | DeleteAssignmentGroupIdentityAddressPhoneFail;
