<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ "help_config" | translate }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="{{ selectedEntity ? 'col-md-12 col-lg-8' : 'col-md-12 col-lg-12' }}">
    <div class="row">
      <div class="col-sm-12">
        <a class="btn btn-primary add-entry-btn" (click)="addEntityClicked()">+</a>
      </div>
      <div class="col-sm-12">
        <help-config-table
          [entityList]="help$ | async"
          (entitySelected)="selectEntity($event)"
          (deleteEntityClicked)="deleteEntity($event)"
        >
        </help-config-table>
      </div>
    </div>
  </div>
  <div *ngIf="selectedEntity" class="col-md-12 col-lg-4">
    <div class="selected-entity-wrapper-not">
      <div class="sticky-top" style="top: 50px">
        <help-config-form
          [entity]="selectedEntity"
          (entityCreatedEdited)="createEditEntity($event)"
        >
        </help-config-form>
      </div>
    </div>
  </div>
</div>
