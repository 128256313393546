import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenHelperService } from "gematik-form-library";

@Component({
  selector: "oauth-redirect",
  templateUrl: "./oauth-redirect.component.html",
  styleUrls: ["./oauth-redirect.component.scss"],
})
export class OauthRedirectComponent implements OnInit {
  username: string;
  loggedIn: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public tokenHelper: TokenHelperService,
  ) {}
  ngOnInit() {
    this.loggedIn = this.tokenHelper.isLoggedIn();
  }
}
