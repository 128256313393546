import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as actions from "../actions/my-rights.actions";
import * as fromServices from "../../services";

@Injectable()
export class MyRightsEffects {
  constructor(private actions$: Actions, private service: fromServices.RightsService) {}

  loadMyRights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LOAD_MY_RIGHTS),
      switchMap((payload: any) => {
        return this.service.getMyRights().pipe(
          map((response) => {
            return new actions.LoadMyRightsSuccess(response);
          }),
          catchError((error) => of(new actions.LoadMyRightsFail(error))),
        );
      }),
    );
  });

  updateMyRightApplications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UPDATE_MY_RIGHT_APPLICATIONS),
      map((action: actions.UpdateMyRightApplications) => action.payload),
      switchMap((payload) => {
        return this.service.updateMyRights(payload, "APPLICATION").pipe(
          map((response) => new actions.UpdateMyRightApplicationsSuccess(response)),
          catchError((error) => of(new actions.UpdateMyRightApplicationsFail(error))),
        );
      }),
    );
  });

  updateMyRightBoomarks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UPDATE_MY_RIGHT_BOOKMARKS),
      map((action: actions.UpdateMyRightBookmarks) => action.payload),
      switchMap((payload) => {
        return this.service.updateMyRights(payload, "BOOKMARK").pipe(
          map((response) => new actions.UpdateMyRightBookmarksSuccess(response)),
          catchError((error) => of(new actions.UpdateMyRightBookmarksFail(error))),
        );
      }),
    );
  });
}
