<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <div class="d-flex align-items-center">
          <h1>{{ "templates" | translate }}</h1>
          <!-- <mat-icon
            class="nav-icons"
            svgIcon="gem-refresh"
            (click)="onRefresh()"
            style="cursor: pointer"
          ></mat-icon> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="{{ selectedEntity ? 'col-md-12 col-lg-8' : 'col-md-12 col-lg-12' }}">
    <div class="row">
      <div class="col-sm-12">
        <a class="btn btn-primary add-entry-btn" (click)="addEntityClicked()">+</a>
      </div>
      <div class="col-sm-12">
        <templates-table
          [entityList]="templates$ | async"
          (entitySelected)="selectEntity($event)"
          (deleteEntityClicked)="deleteEntity($event)"
        >
        </templates-table>
      </div>
    </div>
  </div>
  <div *ngIf="selectedEntity" class="col-md-12 col-lg-4">
    <div class="selected-entity-wrapper-not">
      <div class="sticky-top" style="top: 50px">
        <templates-form [entity]="selectedEntity" (entityCreatedEdited)="createEditEntity($event)">
        </templates-form>
      </div>
    </div>
  </div>
</div>
