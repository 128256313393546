import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromHelp from "../reducers/help.reducer";

export const getHelpState = createFeatureSelector<fromHelp.HelpState>(fromHelp.FEATURE_KEY);

export const getHelpEntities = createSelector(getHelpState, fromHelp.getHelpEntities);

export const getHelp = createSelector(getHelpEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getHelpLoaded = createSelector(getHelpState, fromHelp.getHelpLoaded);

export const getHelpLoading = createSelector(getHelpState, fromHelp.getHelpLoading);
