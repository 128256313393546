import { Action } from "@ngrx/store";
import { Group } from "../../services/uwl/security.service";

// Load Endpoints
export const LOAD_GROUPS = "[Security] Load Groups";
export const LOAD_GROUPS_SUCCESS = "[Security] Load Groups Success";
export const LOAD_GROUPS_FAIL = "[Security] Load Groups Fail";

export class LoadGroups implements Action {
  readonly type = LOAD_GROUPS;
  constructor() {}
}

export class LoadGroupsSuccess implements Action {
  readonly type = LOAD_GROUPS_SUCCESS;
  constructor(public payload: Group[]) {}
}

export class LoadGroupsFail implements Action {
  readonly type = LOAD_GROUPS_FAIL;
  constructor(public payload: any) {}
}

export type SecurityActions = LoadGroups | LoadGroupsSuccess | LoadGroupsFail;
