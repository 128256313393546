import { RxStompService } from "./rx-stomp.service";
import { gematikRxStompConfig } from "./gematik-rx-stomp.config";
import { EnvService } from "gematik-form-library";

export function rxStompServiceFactory(env: EnvService) {
  const rxStomp = new RxStompService();
  rxStomp.configure({
    brokerURL: env.messageBrokerUrl,
    // brokerURL: "ws://localhost:15674/ws",
    //brokerURL: "wss://queue-api.pip.dev.gematik.solutions:443/ws",

    connectHeaders: {},

    heartbeatIncoming: 0,
    heartbeatOutgoing: 20000,

    reconnectDelay: 200,

    debug: (msg: string): void => {
      // console.log(new Date(), msg);
    },
  });
  rxStomp.activate();
  return rxStomp;
}
