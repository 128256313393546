import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromProcesses from "../reducers/process.reducer";

// Active Processes
export const getActiveProcessesState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.activeProcesses,
);

export const getActiveSubscriberProcessEntities = createSelector(
  getActiveProcessesState,
  fromProcesses.getSubscriberProcessEntities,
);

export const getActiveMyProcessEntities = createSelector(
  getActiveProcessesState,
  fromProcesses.getMyProcessEntities,
);

export const getActiveSubscriberProcessCount = createSelector(
  getActiveSubscriberProcessEntities,
  (entities) => Object.keys(entities).length,
);

export const getActiveMyProcessCount = createSelector(
  getActiveMyProcessEntities,
  (entities) => Object.keys(entities).length,
);

export const getActiveSubscriberProcesses = createSelector(
  getActiveSubscriberProcessEntities,
  (entities) => {
    return Object.keys(entities).map((id) => entities[id]);
  },
);

export const getActiveMyProcesses = createSelector(getActiveMyProcessEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

// Completed Processes
export const getCompletedProcessesState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.completedProcesses,
);

export const getCompletedSubscriberProcessEntities = createSelector(
  getCompletedProcessesState,
  fromProcesses.getSubscriberProcessEntities,
);

export const getCompletedMyProcessEntities = createSelector(
  getCompletedProcessesState,
  fromProcesses.getMyProcessEntities,
);

export const getCompletedSubscriberProcesses = createSelector(
  getCompletedSubscriberProcessEntities,
  (entities) => {
    return Object.keys(entities).map((id) => entities[id]);
  },
);

export const getCompletedMyProcesses = createSelector(getCompletedMyProcessEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
