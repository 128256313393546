import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";

export interface Help {
  id: number;
  helpkey: string;
  title: string;
  lang: string;
  html: string;
}

@Injectable({
  providedIn: "root",
})
export class HelpService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getAll(): Observable<Help[]> {
    return this.http.get<Help[]>(this.env.backendUrl + "/uwl/help-config");
  }

  create(help: Help): Observable<Help> {
    return this.http.post<Help>(this.env.backendUrl + "/uwl/help-config", help);
  }

  update(help: Help): Observable<Help> {
    return this.http.post<Help>(`${this.env.backendUrl}/uwl/help-config`, help);
  }

  delete(help: Help): Observable<Help> {
    return this.http.delete<Help>(`${this.env.backendUrl}/uwl/help-config/${help.id}`);
  }
}
