import * as fromProcesses from "../actions/process.actions";
import { GematikProcess } from "../../models/gematik-process.model";

export interface ProcessState {
  myProcesses: {
    entities: { [id: string]: GematikProcess };
  };
  subscriberProcesses: {
    entities: { [id: string]: GematikProcess };
  };
  loading: boolean;
  loaded: boolean;
}

export const initialState: ProcessState = {
  myProcesses: {
    entities: {},
  },
  subscriberProcesses: {
    entities: {},
  },
  loading: false,
  loaded: false,
};

export function processActiveReducer(
  state = initialState,
  action: fromProcesses.ProcessActions,
): ProcessState {
  switch (action.type) {
    case fromProcesses.LOAD_ACTIVE_PROCESSES:
    case fromProcesses.LOAD_ALL_ACTIVE_PROCESSES: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromProcesses.LOAD_ALL_ACTIVE_PROCESSES_SUCCESS: {
      const processes = action.payload;

      const subscriberProcessEntities = processes.subscriberProcesses.reduce(
        (entities: { [id: string]: GematikProcess }, process: GematikProcess) => {
          return {
            ...entities,
            [process.id]: process,
          };
        },
        {
          ...state.subscriberProcesses.entities,
        },
      );

      const myProcessesEntities = processes.myProcesses.reduce(
        (entities: { [id: string]: GematikProcess }, process: GematikProcess) => {
          return {
            ...entities,
            [process.id]: process,
          };
        },
        {
          ...state.myProcesses.entities,
        },
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        subscriberProcesses: {
          entities: subscriberProcessEntities,
        },
        myProcesses: {
          entities: myProcessesEntities,
        },
      };
    }

    case fromProcesses.LOAD_ACTIVE_PROCESSES_SUCCESS: {
      const processes = action.payload;

      // remove
      const myProcesses = Object.entries(state.myProcesses.entities)
        .filter((entity) => entity[1].backendUrl !== action.backendUrl)
        .map((entity) => entity[1]);

      const subscriberProcesses = Object.entries(state.subscriberProcesses.entities)
        .filter((entity) => entity[1].backendUrl !== action.backendUrl)
        .map((entity) => entity[1]);

      // add
      myProcesses.push(...action.payload.myProcesses);
      subscriberProcesses.push(...action.payload.subscriberProcesses);

      // reduce
      const myProcessesEntities = myProcesses.reduce(
        (entities: { [id: string]: GematikProcess }, process: GematikProcess) => {
          return {
            ...entities,
            [process.id]: process,
          };
        },
        {},
      );

      const subscriberProcessesEntities = subscriberProcesses.reduce(
        (entities: { [id: string]: GematikProcess }, process: GematikProcess) => {
          return {
            ...entities,
            [process.id]: process,
          };
        },
        {},
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        subscriberProcesses: {
          entities: subscriberProcessesEntities,
        },
        myProcesses: {
          entities: myProcessesEntities,
        },
      };
    }
    case fromProcesses.LOAD_ACTIVE_PROCESSES_FAIL:
    case fromProcesses.LOAD_ALL_ACTIVE_PROCESSES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getSubscriberProcessEntities = (state: ProcessState) =>
  state.subscriberProcesses.entities;
export const getMyProcessEntities = (state: ProcessState) => state.myProcesses.entities;

export function processCompletedReducer(
  state = initialState,
  action: fromProcesses.ProcessActions,
): ProcessState {
  switch (action.type) {
    case fromProcesses.LOAD_SUBSCRIBER_COMPLETED_PROCESSES:
    case fromProcesses.LOAD_MY_COMPLETED_PROCESSES: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromProcesses.LOAD_MY_COMPLETED_PROCESSES_SUCCESS: {
      const tasks = action.payload;
      const myProcessesEntities = tasks.myProcesses.reduce(
        (entities: { [id: string]: GematikProcess }, process: GematikProcess) => {
          return {
            ...entities,
            [process.id]: process,
          };
        },
        {
          ...state.myProcesses.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        myProcesses: {
          entities: myProcessesEntities,
        },
      };
    }
    case fromProcesses.LOAD_SUBSCRIBER_COMPLETED_PROCESSES_SUCCESS: {
      const tasks = action.payload;
      const subscriberProcessesEntities = tasks.subscriberProcesses.reduce(
        (entities: { [id: string]: GematikProcess }, process: GematikProcess) => {
          return {
            ...entities,
            [process.id]: process,
          };
        },
        {
          ...state.subscriberProcesses.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        subscriberProcesses: {
          entities: subscriberProcessesEntities,
        },
      };
    }
    case fromProcesses.LOAD_SUBSCRIBER_COMPLETED_PROCESSES_FAIL:
    case fromProcesses.LOAD_MY_COMPLETED_PROCESSES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
