import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { GematikTaskDto } from "gematik-task-api";

interface DialogData {
  task: GematikTaskDto;
}

@Component({
  selector: "app-completed-task-details-dialog",
  templateUrl: "./completed-task-details-dialog.component.html",
  styleUrls: ["./completed-task-details-dialog.component.scss"],
})
export class CompletedTaskDetailsDialogComponent implements OnInit {
  title: string;
  text: string;

  constructor(
    public dialogRef: MatDialogRef<CompletedTaskDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService,
    public domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.title = this.translateService.instant(this.data.task.name);
    this.text = this.translateService.instant(this.data.task.description);
  }

  confirmAction(): void {
    this.dialogRef.close();
  }
}
