import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as templateActions from "../actions/template.actions";
import * as fromServices from "../../services";

@Injectable()
export class TemplateEffects {
  constructor(private actions$: Actions, private templateService: fromServices.TemplateService) {}

  loadTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.LOAD_TEMPLATES),
      switchMap((templates) => {
        return this.templateService.getAll().pipe(
          map((templates) => new templateActions.LoadTemplatesSuccess(templates)),
          catchError((error) => of(new templateActions.LoadTemplatesFail(error))),
        );
      }),
    );
  });

  createTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.CREATE_TEMPLATE),
      map((action: templateActions.CreateTemplate) => action.payload),
      switchMap((template) => {
        return this.templateService.create(template).pipe(
          map((template) => new templateActions.CreateTemplateSuccess(template)),
          catchError((error) => of(new templateActions.CreateTemplateFail(error))),
        );
      }),
    );
  });

  updateTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.UPDATE_TEMPLATE),
      map((action: templateActions.UpdateTemplate) => action.payload),
      switchMap((template) => {
        return this.templateService.update(template).pipe(
          map((template) => new templateActions.UpdateTemplateSuccess(template)),
          catchError((error) => of(new templateActions.UpdateTemplateFail(error))),
        );
      }),
    );
  });

  deleteTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.DELETE_TEMPLATE),
      map((action: templateActions.DeleteTemplate) => action.payload),
      switchMap((template) => {
        return this.templateService.delete(template).pipe(
          map(() => new templateActions.DeleteTemplateSuccess(template)),
          catchError((error) => of(new templateActions.DeleteTemplateFail(error))),
        );
      }),
    );
  });
}
