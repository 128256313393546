import { GematikProfile } from "../../models/gematik-profile.model";
import * as fromProfile from "../actions/profile.actions";

export interface ProfileState {
  profile: GematikProfile;
  loading: boolean;
  loaded: boolean;
}

export const initialState: ProfileState = {
  profile: undefined,
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromProfile.ProfileActions): ProfileState {
  switch (action.type) {
    case fromProfile.UPDATE_PROFILE:
    case fromProfile.LOAD_PROFILE: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromProfile.UPDATE_PROFILE_SUCCESS:
    case fromProfile.LOAD_PROFILE_SUCCESS: {
      const profile = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        profile,
      };
    }
    case fromProfile.UPDATE_PROFILE_FAIL:
    case fromProfile.LOAD_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getProfile = (state: ProfileState) => state.profile;
export const getProfileLoading = (state: ProfileState) => state.loading;
export const getProfileLoaded = (state: ProfileState) => state.loaded;
