import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormHelperService, TokenHelperService, ValueLabel } from "gematik-form-library";
import { GematikTaskDto } from "gematik-task-api";
import { MatDialog } from "@angular/material/dialog";
import { CompletedTaskDetailsDialogComponent } from "./completed-task-details-dialog/completed-task-details-dialog.component";
import { Observable, Subscription, of } from "rxjs";
import { NavigationEnd, NavigationStart, Router, Scroll } from "@angular/router";
import { trigger, state, transition, style, animate } from "@angular/animations";

import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { map } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DatePipe } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "completed-task-table",
  templateUrl: "./completed-task-table.component.html",
  styleUrls: ["./completed-task-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed, void", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
      transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class CompletedTaskTableComponent implements OnInit, OnChanges, OnDestroy {
  tsks: any[];
  filterText: string = "";
  dataSource: MatTableDataSource<any>;

  columnDefinitions: string[] = [
    "expand",
    "buid",
    "partner",
    "partnerType",
    "processName",
    "taskName",
    "createdTimeStamp",
    "actions",
  ];

  tabletColumnDefinitions: string[] = ["expand", "buid", "createdTimeStamp", "actions"];
  handsetColumnDefinitions: string[] = ["handset"];

  displayedColumns: string[] = [...this.columnDefinitions];

  tabletBreakpoint: string = "768px";
  tabletBreakpointMatched: boolean = false;
  handsetBreakpoint: string = "540px";
  handsetBreakpointMatched: boolean = false;

  @Output() taskSelected = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  @Input() searchStr: string;
  @Input() set completedTasks(completedTasks: any[]) {
    this.tsks = completedTasks;
    // if (completedTasks && completedTasks.length > 0) {
    //   for (const task of completedTasks) {
    //     if (task.processName) {
    //       task.processName = this.translateService.instant(task.processName);
    //     }
    //     if (task.outcome) {
    //       task.outcome = this.translateService.instant(task.outcome);
    //     }
    //   }
    // }

    this.dataSource = new MatTableDataSource(completedTasks);
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buidFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerTypeFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerRoleFilterOptions$: Observable<ValueLabel[]> = of([]);
  processNameFilterOptions$: Observable<ValueLabel[]> = of([]);
  taskNameFilterOptions$: Observable<ValueLabel[]> = of([]);

  selectedFilters: { [id: string]: ValueLabel[] } | any = {
    buid: [],
    partner: [],
    partnerType: [],
    role: [],
    processName: [],
    name: [],
    searchStr: [],
  };

  reset: { search: boolean };

  subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private formHelper: FormHelperService,
    private dialog: MatDialog,
    private tokenHelperService: TokenHelperService,
    private router: Router,
    private store: Store<fromStore.UwlState>,
    public domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private destroyRef: DestroyRef,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.reset = {
          ...this.reset,
          search: false,
        };
      } else if (event instanceof Scroll) {
        if (event.routerEvent instanceof NavigationEnd) {
          this.getFilters(event.routerEvent.url);
        }
      } else if (event instanceof NavigationEnd) {
        this.getFilters(event.url);
      }
    });
    this.subscriptions.push(sub);
    const breakpointSub = this.breakpointObserver
      .observe([`(max-width: ${this.tabletBreakpoint})`, `(max-width: ${this.handsetBreakpoint})`])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        this.tabletBreakpointMatched = state.breakpoints[`(max-width: ${this.tabletBreakpoint})`];
        this.handsetBreakpointMatched = state.breakpoints[`(max-width: ${this.handsetBreakpoint})`];
        if (!this.tabletBreakpointMatched && !this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.columnDefinitions];
        } else if (this.tabletBreakpointMatched && !this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.tabletColumnDefinitions];
        } else if (this.tabletBreakpointMatched && this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.handsetColumnDefinitions];
        } else {
          this.displayedColumns = [...this.columnDefinitions];
        }
      });
    this.subscriptions.push(breakpointSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter();
    if (changes.searchStr) {
      this.applyFilter(this.searchStr);
    }
  }

  private getFilters(url: string): void {
    this.selectedFilters = {
      buid: [],
      partner: [],
      partnerType: [],
      role: [],
      processName: [],
      name: [],
      searchStr: [],
    };
    this.reset = {
      ...this.reset,
      search: true,
    };
    if (url === "/tasks/my") {
      this.setFilterOptions(fromStore.getMyCompletedTasks);
    } else if (url === "/tasks/group") {
      this.setFilterOptions(fromStore.getGroupCompletedTasks);
    }
  }

  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.selectedFilters = {
      ...this.selectedFilters,
      searchStr: [{ value: this.filterText, label: this.filterText }],
    };
    this.dataSource.filter = this.selectedFilters;
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.lastPage();
        this.cdr.detectChanges();
      }
    }
  }

  taskRowClicked(completedTask: any) {
    const task: GematikTaskDto = completedTask;
    const dialogRef = this.dialog.open(CompletedTaskDetailsDialogComponent, {
      data: {
        task,
      },
    });
    this.taskSelected.emit(completedTask);
  }

  customFilterPredicate(data: any, filters: { [id: string]: ValueLabel[] }): boolean {
    let expression: string = "";
    Object.keys(filters).forEach((key, index, array) => {
      const vls: ValueLabel[] = filters[key];
      if (vls.length > 0) {
        if (key !== "searchStr") {
          let expr: string = "(";
          vls.forEach((vl, index, array) => {
            expr = expr + `data["${key}"].includes("${vl.value}")`;
            if (index !== array.length - 1) {
              expr = expr + " || ";
            } else {
              expr = expr + ")";
            }
          });
          expression = expression + expr + " && ";
        } else {
          if (vls[0].value) {
            const buidSearchExpr: string = `data["buid"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerSearchExpr: string = `data["partner"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerTypeSearchExpr: string = `data["partnerType"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerRoleSearchExpr: string = `data["role"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const assigneeSearchExpr: string = `data["assigneeName"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const processNameSearchExpr: string = this.translateService
              .instant(data["processName"])
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());
            const activityNameSearchExpr: string = this.translateService
              .instant(data["name"])
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());
            const createdSearchExpr: string = (
              this.datePipe.transform(data["created"], "dd.MM.YYYY HH:mm") as any
            )
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());

            let expr: string =
              buidSearchExpr +
              " || " +
              partnerSearchExpr +
              " || " +
              partnerTypeSearchExpr +
              " || " +
              partnerRoleSearchExpr +
              " || " +
              assigneeSearchExpr +
              " || " +
              processNameSearchExpr +
              " || " +
              activityNameSearchExpr +
              " || " +
              createdSearchExpr;
            if (expression.length === 0) {
              expression = expr + " && ";
            } else {
              expression = expression + expr + " && ";
            }
          }
        }
      }
    });
    expression = expression.substring(0, expression.lastIndexOf("&&"));

    if (!expression) {
      return true;
    }
    return eval(expression);
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.tsks);
    if (["completedtimeStamp"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByCreatedDate);
    } else if (["priority", "taskName", "partner", "processName"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortByProperty($event.active));
    } else if (["task"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByProperty($event.active));
    }

    if ($event.direction === "desc") {
      sortedTasks.reverse();
    }
    this.dataSource = new MatTableDataSource(sortedTasks);
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  isUserInternal(task: GematikTaskDto): boolean {
    return this.tokenHelperService.isUserInternal() && task.caseLogId !== null;
  }

  onViewCaseLogs(task: GematikTaskDto): void {
    const url: string = `${task.crmUrl}/index.php?module=Cases&action=DetailView&record=${task.caseLogId}`;
    window.open(url);
  }

  getSelectedFilters(key: string): ValueLabel[] {
    return this.selectedFilters[key];
  }

  onFilterChange(event: { checked: boolean; vl: ValueLabel }, key: string): void {
    const { checked, vl } = event;
    if (checked) {
      this.selectedFilters = {
        ...this.selectedFilters,
        [key]: [...this.selectedFilters[key], vl],
      };
    } else {
      this.onRemoveFilter(key, vl);
    }
    this.filter();
  }

  onRemoveFilter(key: string, filter: ValueLabel): void {
    this.selectedFilters = {
      ...this.selectedFilters,
      [key]: this.getSelectedFilters(key).filter((f) => f !== filter),
    };
    this.filter();
    if (key === "searchStr") {
      this.clearFilter.emit();
    }
  }

  private filter(): void {
    this.dataSource.filter = this.selectedFilters;
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.lastPage();
        this.cdr.detectChanges();
      }
    }
  }

  private setFilterOptions(selector): void {
    this.buidFilterOptions$ = this.store.select(selector).pipe(
      map((tasks) => {
        return tasks.map((task) => {
          return { value: task.buid, label: task.buid };
        });
      }),
    );
    this.partnerFilterOptions$ = this.store.select(selector).pipe(
      map((tasks) => {
        return tasks.map((task) => task.partner);
      }),
      map((values) => Array.from(new Set(values))),
      map((values) =>
        values.map((value: string) => {
          return { value, label: value };
        }),
      ),
    );
    this.partnerTypeFilterOptions$ = this.store.select(selector).pipe(
      map((tasks) => {
        return tasks.map((task) => task.partnerType);
      }),
      map((values) => Array.from(new Set(values))),
      map((values) =>
        values.map((value: string) => {
          return { value, label: value };
        }),
      ),
    );
    this.partnerRoleFilterOptions$ = this.store.select(selector).pipe(
      map((tasks) => {
        return tasks.map((task) => task.role);
      }),
      map((values) => Array.from(new Set(values))),
      map((values) =>
        values.map((value: string) => {
          return { value, label: value };
        }),
      ),
    );
    this.processNameFilterOptions$ = this.store.select(selector).pipe(
      map((tasks) => {
        return tasks.map((task) => task.processName);
      }),
      map((values) => Array.from(new Set(values))),
      map((values) =>
        values.map((value: string) => {
          return { value, label: value };
        }),
      ),
    );
    this.taskNameFilterOptions$ = this.store.select(selector).pipe(
      map((tasks) => {
        return tasks.map((task) => task.name);
      }),
      map((values) => Array.from(new Set(values))),
      map((values) =>
        values.map((value: string) => {
          return { value, label: value };
        }),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
