<div *ngIf="isHome">
  <router-outlet></router-outlet>
</div>
<mat-sidenav-container *ngIf="!isHome" autosize class="pip-container">
  <mat-sidenav *ngIf="!handsetBreakpointMatched" #sidenav mode="side" opened="isExpanded">
    <side-navigation
      [isExpanded]="isExpanded"
      (toggle)="isExpanded = !isExpanded"
    ></side-navigation>
  </mat-sidenav>
  <mat-sidenav-content>
    <div *ngIf="handsetBreakpointMatched">
      <nav class="navbar navbar-dark">
        <div class="container-fluid mt-0 pt-3 ps-3 pe-3 pb-3">
          <a class="navbar-brand" href="" routerLink="/">
            <img src="/gematik-shared/assets/icons/logo.svg" alt="" />
            <span class="gem-color-dark-grey ms-1">myServices</span>
          </a>
          <mat-icon
            class="d-block nav-icons"
            style="cursor: pointer"
            svgIcon="gem-toggler"
            (click)="openTop(content)"
          ></mat-icon>
        </div>
      </nav>
      <ng-template #content let-offcanvas>
        <div class="offcanvas-body">
          <side-navigation
            [isExpanded]="true"
            [isHandset]="true"
            (closeHandsetMenu)="offcanvas.close('Close click')"
          ></side-navigation>
        </div>
      </ng-template>
    </div>
    <div class="ms-3 mt-5">
      <gematik-alerts [alerts]="alerts$ | async"></gematik-alerts>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
