import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

import * as fromTasks from "./task.reducer";
import * as fromProcesses from "./process.reducer";
import * as fromEndpoints from "./endpoint.reducer";
import * as fromIncidents from "./incident.reducer";
import * as fromTemplates from "./template.reducer";
import * as fromHelp from "./help.reducer";
import * as fromGroups from "./groups.reducer";
import * as fromTaskTotals from "./task-totals.reducer";
import * as fromProcessTotals from "./process-totals.reducer";
import * as fromProfile from "./profile.reducer";
import * as fromEnterpriseApps from "./my-services.reducer";
import * as fromQuickActions from "./quick-action.reducer";
import * as fromOrganisationalProfile from "./organisation-profile.reducer";
import * as fromPersonalProfile from "./personal-profile.reducer";
import * as fromAlerts from "./alert.reducer";
import * as fromRights from "./my-rights.reducer";

export interface UwlState {
  openTasks: fromTasks.TaskState;
  activeProcesses: fromProcesses.ProcessState;
  completedTasks: fromTasks.TaskState;
  completedProcesses: fromProcesses.ProcessState;
  incidents: fromIncidents.IncidentState;
  endpoints: fromEndpoints.EndpointState;
  templates: fromTemplates.TemplateState;
  help: fromHelp.HelpState;
  groups: fromGroups.GroupsState;
  taskTotals: fromTaskTotals.TaskTotalsState;
  processTotals: fromProcessTotals.ProcessTotalsState;
  profile: fromProfile.ProfileState;
  myServices: fromEnterpriseApps.MyServicesState;
  quickActions: fromQuickActions.QuickActionState;
  organisationalProfile: fromOrganisationalProfile.OrganisationProfileState;
  personalProfile: fromPersonalProfile.PersonalProfileState;
  alerts: fromAlerts.AlertState;
  myRights: fromRights.MyRightState;
}

export const reducers: ActionReducerMap<UwlState> = {
  openTasks: fromTasks.taskOpenReducer,
  activeProcesses: fromProcesses.processActiveReducer,
  completedTasks: fromTasks.taskCompletedReducer,
  completedProcesses: fromProcesses.processCompletedReducer,
  incidents: fromIncidents.reducer,
  endpoints: fromEndpoints.reducer,
  templates: fromTemplates.reducer,
  help: fromHelp.reducer,
  groups: fromGroups.reducer,
  taskTotals: fromTaskTotals.reducer,
  processTotals: fromProcessTotals.reducer,
  profile: fromProfile.reducer,
  myServices: fromEnterpriseApps.reducer,
  quickActions: fromQuickActions.reducer,
  organisationalProfile: fromOrganisationalProfile.reducer,
  personalProfile: fromPersonalProfile.reducer,
  alerts: fromAlerts.reducer,
  myRights: fromRights.reducer,
};

export const getUwlStore = createFeatureSelector<UwlState>("uwl");
