import { Action } from "@ngrx/store";
import { Right } from "../../models/right.model";

// Load My Rights
export const LOAD_MY_RIGHTS = "[My Rights] Load My Rights";
export const LOAD_MY_RIGHTS_SUCCESS = "[My Rights] Load My Rights Success";
export const LOAD_MY_RIGHTS_FAIL = "[My Rights] Load My Rights Fail";

export class LoadMyRights implements Action {
  readonly type = LOAD_MY_RIGHTS;
}

export class LoadMyRightsSuccess implements Action {
  readonly type = LOAD_MY_RIGHTS_SUCCESS;
  constructor(
    public payload: {
      myApplications: Right[];
      myBookmarks: Right[];
    },
  ) {}
}

export class LoadMyRightsFail implements Action {
  readonly type = LOAD_MY_RIGHTS_FAIL;
  constructor(public payload: any) {}
}

// Update My Application
export const UPDATE_MY_RIGHT_APPLICATIONS = "[My Rights] Update My Right Applications";
export const UPDATE_MY_RIGHT_APPLICATIONS_SUCCESS =
  "[My Rights] Update My Right Applications Success";
export const UPDATE_MY_RIGHT_APPLICATIONS_FAIL = "[My Rights] Update My Right Applications Fail";

export class UpdateMyRightApplications implements Action {
  readonly type = UPDATE_MY_RIGHT_APPLICATIONS;
  constructor(public payload: { leftList: Right[]; rightList: Right[] }) {}
}

export class UpdateMyRightApplicationsSuccess implements Action {
  readonly type = UPDATE_MY_RIGHT_APPLICATIONS_SUCCESS;
  constructor(public payload: Right[]) {}
}

export class UpdateMyRightApplicationsFail implements Action {
  readonly type = UPDATE_MY_RIGHT_APPLICATIONS_FAIL;
  constructor(public payload: any) {}
}

// Update My Bookmark
export const UPDATE_MY_RIGHT_BOOKMARKS = "[My Rights] Update My Right Bookmarks";
export const UPDATE_MY_RIGHT_BOOKMARKS_SUCCESS = "[My Rights] Update My Right Bookmarks Success";
export const UPDATE_MY_RIGHT_BOOKMARKS_FAIL = "[My Rights] Update My Right Bookmarks Fail";

export class UpdateMyRightBookmarks implements Action {
  readonly type = UPDATE_MY_RIGHT_BOOKMARKS;
  constructor(public payload: { leftList: Right[]; rightList: Right[] }) {}
}

export class UpdateMyRightBookmarksSuccess implements Action {
  readonly type = UPDATE_MY_RIGHT_BOOKMARKS_SUCCESS;
  constructor(public payload: Right[]) {}
}

export class UpdateMyRightBookmarksFail implements Action {
  readonly type = UPDATE_MY_RIGHT_BOOKMARKS_FAIL;
  constructor(public payload: any) {}
}

export type MyRightsActions =
  | LoadMyRights
  | LoadMyRightsSuccess
  | LoadMyRightsFail
  | UpdateMyRightApplications
  | UpdateMyRightApplicationsSuccess
  | UpdateMyRightApplicationsFail
  | UpdateMyRightBookmarks
  | UpdateMyRightBookmarksSuccess
  | UpdateMyRightBookmarksFail;
