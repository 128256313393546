import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FormHelperService } from "gematik-form-library";
import { UwlHelpConfig, UwlService } from "../../../services/uwl/uwl.service";
import { GemHtmlEditDialogComponent } from "../gem-html-edit-dialog/gem-html-edit-dialog.component";

@Component({
  selector: "help-config-form",
  templateUrl: "./help-config-form.component.html",
  styleUrls: ["./help-config-form.component.scss"],
})
export class HelpConfigFormComponent implements OnInit {
  entityForm: UntypedFormGroup;

  @Output() entityCreatedEdited = new EventEmitter<UntypedFormGroup>();

  @Input() set entity(entity: UwlHelpConfig) {
    this.entityForm = this.createForm(entity);
  }

  constructor(
    private uwlService: UwlService,
    private formHelper: FormHelperService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  createForm(entity: UwlHelpConfig) {
    const fg = this.uwlService.mapUwlHelpConfigToForm(entity);
    fg.get("helpkey").setValidators(Validators.required);
    fg.get("lang").setValidators(Validators.required);
    fg.get("title").setValidators(Validators.required);
    fg.get("html").disable();
    return fg;
  }

  createEditEntity() {
    this.formHelper.isFormValidElseShowErrors(this.entityForm, "createEntityErrorTitle", () => {
      this.entityCreatedEdited.emit(this.entityForm);
    });
  }

  cancel() {
    this.entityCreatedEdited.emit(null);
  }

  openHtmlEditor() {
    this.dialog.open(GemHtmlEditDialogComponent, {
      data: {
        htmlContent: this.entityForm.get("html").value,
        confirmAction: (htmlContent) => this.updateHtmlContent(htmlContent),
      },
    });
  }

  updateHtmlContent(htmlContent: string) {
    this.entityForm.get("html").setValue(htmlContent);
  }

  langToLowerCase() {
    let lang: string = this.entityForm.get("lang").value;
    if (lang) {
      lang = lang.toLowerCase();
      this.entityForm.get("lang").setValue(lang);
    }
  }
}
