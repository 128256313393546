import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as actions from "../actions/my-services.actions";
import * as fromServices from "../../services";

@Injectable()
export class MyServicesEffects {
  constructor(private actions$: Actions, private service: fromServices.MyServicesService) {}

  loadMyEnterpriseApps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LOAD_MY_SERVICES),
      switchMap((payload: any) => {
        return this.service.getMyServices().pipe(
          map((response) => {
            return new actions.LoadMyServicesSuccess(response);
          }),
          catchError((error) => of(new actions.LoadMyServicesFail(error))),
        );
      }),
    );
  });

  updateMyEnterpriseAppServices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UPDATE_MY_SERVICES),
      map((action: actions.UpdateMyServices) => action.payload),
      switchMap((payload) => {
        return this.service.updateMyServices(payload).pipe(
          map((response) => new actions.UpdateMyServicesSuccess(response)),
          catchError((error) => of(new actions.UpdateMyServicesFail(error))),
        );
      }),
    );
  });
}
