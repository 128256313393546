import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as incidentActions from "../actions/incident.actions";
import * as fromServices from "../../services";

@Injectable()
export class IncidentEffects {
  constructor(private actions$: Actions, private incidentService: fromServices.IncidentService) {}

  loadAllIncidents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(incidentActions.LOAD_ALL_INCIDENTS),
      switchMap((payload: any) => {
        return this.incidentService.getAll().pipe(
          map((incidents) => new incidentActions.LoadAllIncidentsSuccess(incidents)),
          catchError((error) => of(new incidentActions.LoadAllIncidentsFail(error))),
        );
      }),
    );
  });

  loadIncidents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(incidentActions.LOAD_INCIDENTS),
      map((action: incidentActions.LoadIncidents) => action.payload),
      switchMap((endpoint) => {
        return this.incidentService.get(endpoint).pipe(
          map((incidents) => new incidentActions.LoadIncidentsSuccess(incidents)),
          catchError((error) => of(new incidentActions.LoadIncidentsFail(error))),
        );
      }),
    );
  });

  retryFailedIncident$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(incidentActions.RETRY_FAILED_INCIDENT),
      map((action: incidentActions.RetryFailedIncident) => action.payload),
      switchMap((incident) => {
        return this.incidentService.retry(incident).pipe(
          map((incidents) => new incidentActions.RetryFailedIncidentSuccess()),
          catchError((error) => of(new incidentActions.RetryFailedIncidentFail(error))),
        );
      }),
    );
  });
}
