import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FormHelperService, ValueLabel } from "gematik-form-library";
import { SuiteCrmPartnerAddress } from "gematik-shared";

export type ScenanioType = "Personal" | "Organisation";

@Component({
  selector: "communication-address-form",
  styleUrls: ["communication-address-form.component.scss"],
  template: `
    <p>scenarioType: {{ scenarioType }}</p>

    <mat-card>
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>
            <div class="d-flex">
              <mat-icon class="nav-icons me-1" svgIcon="gem-report"></mat-icon>
              <strong> Address </strong>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="mt-2"> </mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content class="mt-4">
        <div class="row">
          <form [formGroup]="form">
            <div class="col-sm-12">
              <gem-select
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.form.scenario.label'
                "
                [control]="scenario"
                [options]="scenarios"
              >
              </gem-select>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.form.address.label'
                "
                [control]="address"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.form.street.label'
                "
                [control]="street"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="'portal.profile.organisational.organisationAddress.form.city.label'"
                [control]="city"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="'portal.profile.organisational.organisationAddress.form.state.label'"
                [control]="state"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.form.postalCode.label'
                "
                [control]="postalCode"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.form.country.label'
                "
                [control]="country"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.form.description.label'
                "
                [control]="description"
              ></gem-input>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-end mt-2">
              <button data-testid="save" class="btn btn-primary" color="primary" (click)="onSave()">
                {{ "portal.profile.buttons.save" | translate }}
              </button>
              <button
                data-testid="cancel"
                class="btn btn-secondary"
                color="primary"
                (click)="onCancel()"
              >
                {{ "portal.profile.buttons.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class CommunicationAddressFormComponent implements OnInit, OnChanges {
  @Input() partnerAddress: SuiteCrmPartnerAddress;
  @Input() scenarioType: ScenanioType;

  @Output() save: EventEmitter<SuiteCrmPartnerAddress> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  scenarios: ValueLabel[] = [
    {
      value: "Standard",
      label: this.translateService.instant(
        "portal.profile.organisational.organisationAddress.form.scenario.options.Standard",
      ),
    },
    {
      value: "Betriebsstätte",
      label: this.translateService.instant(
        "portal.profile.organisational.organisationAddress.form.scenario.options.Betriebsstätte",
      ),
    },
    {
      value: "Vertrag",
      label: this.translateService.instant(
        "portal.profile.organisational.organisationAddress.form.scenario.options.Vertrag",
      ),
    },
    {
      value: "Rechnung",
      label: this.translateService.instant(
        "portal.profile.organisational.organisationAddress.form.scenario.options.Rechnung",
      ),
    },
    {
      value: "Lieferung",
      label: this.translateService.instant(
        "portal.profile.organisational.organisationAddress.form.scenario.options.Lieferung",
      ),
    },
    {
      value: "Notfall",
      label: this.translateService.instant(
        "portal.profile.organisational.organisationAddress.form.scenario.options.Notfall",
      ),
    },
  ];

  form: FormGroup = this.fb.group({
    id: [],
    scenario: [null, Validators.required],
    address: [null, Validators.required],
    street: [null, Validators.required],
    city: [null, Validators.required],
    state: [null, Validators.required],
    postalCode: [null, Validators.required],
    country: [null, Validators.required],
    description: [],
  });

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private formHelper: FormHelperService,
  ) {}

  ngOnInit(): void {
    if (this.scenarioType === "Personal") {
      this.scenarios = this.scenarios.filter(
        (scenario) => scenario.value === "Standard" || scenario.value === "Notfall",
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(this.partnerAddress);
  }

  onSave(): void {
    this.formHelper.isFormValidElseShowErrors(
      this.form,
      "Form Validation",
      () => {
        this.save.emit(this.form.getRawValue());
      },
      true,
      true,
      "portal.profile.organisational.organisationAddress.form.",
    );
  }

  onCancel(): void {
    this.cancel.emit();
  }

  get id(): FormControl {
    return this.form.get("id") as FormControl;
  }

  get scenario(): FormControl {
    return this.form.get("scenario") as FormControl;
  }

  get address(): FormControl {
    return this.form.get("address") as FormControl;
  }

  get street(): FormControl {
    return this.form.get("street") as FormControl;
  }

  get city(): FormControl {
    return this.form.get("city") as FormControl;
  }

  get state(): FormControl {
    return this.form.get("state") as FormControl;
  }

  get postalCode(): FormControl {
    return this.form.get("postalCode") as FormControl;
  }

  get country(): FormControl {
    return this.form.get("country") as FormControl;
  }

  get description(): FormControl {
    return this.form.get("description") as FormControl;
  }
}
