import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { UwlHelpConfig, UwlService } from "../../services/uwl/uwl.service";
import { Store } from "@ngrx/store";
import * as fromStore from "../../store";
import { Observable } from "rxjs";
import { Help } from "../../services";
import { filter, switchMap, take, tap } from "rxjs/operators";

@Component({
  selector: "app-help-config-page",
  templateUrl: "./help-config-page.component.html",
  styleUrls: ["./help-config-page.component.scss"],
})
export class HelpConfigPageComponent implements OnInit {
  help$: Observable<Help[]>;
  selectedEntity: Help;

  constructor(private uwlService: UwlService, private store: Store<fromStore.UwlState>) {}

  ngOnInit(): void {
    this.help$ = this.store.select(fromStore.getHelpLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(new fromStore.LoadHelp());
        }
      }),
      filter((loaded) => loaded),
      take(1),
      switchMap(() => {
        return this.store.select(fromStore.getHelp);
      }),
    );
  }

  createEditEntity(entityForm: UntypedFormGroup) {
    if (entityForm) {
      const entity = this.uwlService.mapFormToUwlHelpConfig(entityForm);
      if (entity.id) {
        this.store.dispatch(new fromStore.UpdateHelp(entity));
      } else {
        this.store.dispatch(new fromStore.CreateHelp(entity));
      }
    }
    this.selectedEntity = null;
  }

  deleteEntity(entity: Help) {
    this.store.dispatch(new fromStore.DeleteHelp(entity));
  }

  selectEntity(entity: Help) {
    this.selectedEntity = entity;
  }

  addEntityClicked() {
    this.selectedEntity = {
      id: undefined,
      helpkey: undefined,
      title: undefined,
      lang: undefined,
      html: undefined,
    };
  }
}
