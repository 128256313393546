import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as personalProfileActions from "../actions/personal-profile.actions";
import * as routerActions from "../actions/router.actions";
import * as fromServices from "../../services";
import * as fromStore from "..";
import { Store } from "@ngrx/store";

import { SnackBarService } from "gematik-shared";

@Injectable()
export class PersonalProfileEffects {
  constructor(
    private actions$: Actions,
    private service: fromServices.PersonalProfileService,
    private store: Store<fromStore.UwlState>,
    private snackBarService: SnackBarService,
  ) {}

  loadAssignmentGroupIdentities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.LOAD_ASSIGNMENT_GROUP_IDENTITIES),
      switchMap((payload: any) => {
        return this.service.getAssignmentGroupIdentities().pipe(
          map(
            (response) => new personalProfileActions.LoadAssignmentGroupIdentitiesSuccess(response),
          ),
          catchError((error) =>
            of(new personalProfileActions.LoadAssignmentGroupIdentitiesFail(error)),
          ),
        );
      }),
    );
  });

  loadAssignmentGroupIdentityAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES),
      switchMap((payload) => {
        return this.service.getAssignmentGroupIdentityAddresses(payload["agiId"]).pipe(
          map(
            (response) =>
              new personalProfileActions.LoadAssignmentGroupIdentityAddressesSuccess(
                response,
                payload["agiId"],
              ),
          ),
          catchError((error) =>
            of(new personalProfileActions.LoadAssignmentGroupIdentityAddressesFail(error)),
          ),
        );
      }),
    );
  });

  createAssignmentGroupIdentityAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS),
      map((action: personalProfileActions.CreateAssignmentGroupIdentityAddress) => action),
      switchMap((payload) => {
        return this.service
          .createAssignmentGroupIdentityAddress(payload.agiId, payload.payload)
          .pipe(
            map((response) => {
              this.store.dispatch(
                new routerActions.RouterNavigate(
                  `/profile/personal/communication-scenarios/${payload.agiId}`,
                ),
              );
              this.snackBarService.openSuccess("Address created successfully");
              return new personalProfileActions.CreateAssignmentGroupIdentityAddressSuccess(
                response,
                payload.agiId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.CreateAssignmentGroupIdentityAddressFail(error)),
            ),
          );
      }),
    );
  });

  updateAssignmentGroupIdentityAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS),
      map((action: personalProfileActions.UpdateAssignmentGroupIdentityAddress) => action),
      switchMap((payload) => {
        return this.service
          .updateAssignmentGroupIdentityAddress(payload.agiId, payload.payload)
          .pipe(
            map((response) => {
              this.store.dispatch(
                new routerActions.RouterNavigate(
                  `/profile/personal/communication-scenarios/${payload.agiId}`,
                ),
              );
              this.snackBarService.openSuccess("Address updated successfully");
              return new personalProfileActions.UpdateAssignmentGroupIdentityAddressSuccess(
                response,
                payload.agiId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.UpdateAssignmentGroupIdentityAddressFail(error)),
            ),
          );
      }),
    );
  });

  deleteAssignmentGroupIdentityAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS),
      map((action: personalProfileActions.DeleteAssignmentGroupIdentityAddress) => action),
      switchMap((payload) => {
        return this.service
          .deleteAssignmentGroupIdentityAddress(payload.agiId, payload.addressId)
          .pipe(
            map((response) => {
              this.store.dispatch(
                new routerActions.RouterNavigate(
                  `/profile/personal/communication-scenarios/${payload.agiId}`,
                ),
              );
              this.snackBarService.openSuccess("Address deleted successfully");
              return new personalProfileActions.DeleteAssignmentGroupIdentityAddressSuccess(
                payload.agiId,
                payload.addressId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.DeleteAssignmentGroupIdentityAddressFail(error)),
            ),
          );
      }),
    );
  });

  createAssignmentGroupIdentityAddressMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL),
      map((action: personalProfileActions.CreateAssignmentGroupIdentityAddressMail) => action),
      switchMap((payload) => {
        return this.service
          .createAssignmentGroupIdentityAddressMail(
            payload.agiId,
            payload.addressId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Mail created successfully");
              return new personalProfileActions.CreateAssignmentGroupIdentityAddressMailSuccess(
                response,
                payload.agiId,
                payload.addressId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.CreateAssignmentGroupIdentityAddressMailFail(error)),
            ),
          );
      }),
    );
  });

  updateAssignmentGroupIdentitynAddressMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL),
      map((action: personalProfileActions.UpdateAssignmentGroupIdentityAddressMail) => action),
      switchMap((payload) => {
        return this.service
          .updateAssignmentGroupIdentityAddressMail(
            payload.agiId,
            payload.addressId,
            payload.mailId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Mail updated successfully");
              return new personalProfileActions.UpdateAssignmentGroupIdentityAddressMailSuccess(
                response,
                payload.agiId,
                payload.addressId,
                payload.mailId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.UpdateAssignmentGroupIdentityAddressMailFail(error)),
            ),
          );
      }),
    );
  });

  deleteAssignmentGroupIdentityAddressMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL),
      map((action: personalProfileActions.DeleteAssignmentGroupIdentityAddressMail) => action),
      switchMap((payload) => {
        return this.service
          .deleteAssignmentGroupIdentityAddressMail(
            payload.agiId,
            payload.addressId,
            payload.mailId,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Mail deleted successfully");
              return new personalProfileActions.DeleteAssignmentGroupIdentityAddressMailSuccess(
                payload.agiId,
                payload.addressId,
                payload.mailId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.DeleteAssignmentGroupIdentityAddressMailFail(error)),
            ),
          );
      }),
    );
  });

  createAssignmentGroupIdentityAddressPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE),
      map((action: personalProfileActions.CreateAssignmentGroupIdentityAddressPhone) => action),
      switchMap((payload) => {
        return this.service
          .createAssignmentGroupIdentityAddressPhone(
            payload.agiId,
            payload.addressId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Phone created successfully");
              return new personalProfileActions.CreateAssignmentGroupIdentityAddressPhoneSuccess(
                response,
                payload.agiId,
                payload.addressId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.CreateAssignmentGroupIdentityAddressPhoneFail(error)),
            ),
          );
      }),
    );
  });

  updateAssignmentGroupIdentityAddressPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE),
      map((action: personalProfileActions.UpdateAssignmentGroupIdentityAddressPhone) => action),
      switchMap((payload) => {
        return this.service
          .updateAssignmentGroupIdentityAddressPhone(
            payload.agiId,
            payload.addressId,
            payload.phoneId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Phone updated successfully");
              return new personalProfileActions.UpdateAssignmentGroupIdentityAddressPhoneSuccess(
                response,
                payload.agiId,
                payload.addressId,
                payload.phoneId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.UpdateAssignmentGroupIdentityAddressPhoneFail(error)),
            ),
          );
      }),
    );
  });

  deleteAssignmentGroupIdentityAddressPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personalProfileActions.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE),
      map((action: personalProfileActions.DeleteAssignmentGroupIdentityAddressPhone) => action),
      switchMap((payload) => {
        return this.service
          .deleteAssignmentGroupIdentityAddressPhone(
            payload.agiId,
            payload.addressId,
            payload.phoneId,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Phone deleted successfully");
              return new personalProfileActions.DeleteAssignmentGroupIdentityAddressPhoneSuccess(
                payload.agiId,
                payload.addressId,
                payload.phoneId,
              );
            }),
            catchError((error) =>
              of(new personalProfileActions.DeleteAssignmentGroupIdentityAddressPhoneFail(error)),
            ),
          );
      }),
    );
  });
}
