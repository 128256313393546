import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SuiteCrmPartnerAddressPhone } from "gematik-shared";
import { Subscription } from "rxjs";

import { MatDialog } from "@angular/material/dialog";
import { CommunicationAddressPhoneComponent } from "../communication-address-phone/communication-address-phone.component";

@Component({
  selector: "communication-address-phones",
  styleUrls: ["communication-address-phones.component.scss"],
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>
            <div class="d-flex">
              <mat-icon class="nav-icons me-1" svgIcon="gem-report"></mat-icon>
              <strong> Phones </strong>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="mt-2"> </mat-card-subtitle>
          <button data-testid="add" mat-mini-fab class="fab-button" (click)="onAdd()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content class="mt-4">
        <div class="col-sm-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Phone</th>
                <th scope="col">Description</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              @for (phone of phones; track phone.phone; let i = $index) {
              <tr>
                <td>
                  {{ phone.phone }}
                </td>
                <td>{{ phone.description }}</td>
                <td>
                  <span
                    data-testid="edit"
                    class="material-icons"
                    style="cursor: pointer;"
                    (click)="onEdit(phone, i)"
                  >
                    edit
                  </span>
                </td>
                <td>
                  <span
                    data-testid="delete"
                    class="material-icons"
                    style="cursor: pointer;"
                    (click)="onDelete(phone)"
                  >
                    delete
                  </span>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class CommunicationAddressPhonesComponent implements OnInit, OnDestroy {
  @Input() phones: SuiteCrmPartnerAddressPhone[] = [];
  @Output() add: EventEmitter<SuiteCrmPartnerAddressPhone> = new EventEmitter();
  @Output() update: EventEmitter<{ obj: SuiteCrmPartnerAddressPhone; index: number }> =
    new EventEmitter();
  @Output() delete: EventEmitter<SuiteCrmPartnerAddressPhone> = new EventEmitter();

  addressId: string;
  orgId: string;

  subscriptions: Subscription[] = [];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  onAdd(): void {
    const dialogRef = this.dialog.open(CommunicationAddressPhoneComponent);
    const sub = dialogRef.afterClosed().subscribe((result) => {
      this.add.emit(result.phone);
    });
    this.subscriptions.push(sub);
  }

  onEdit(phone: SuiteCrmPartnerAddressPhone, index: number): void {
    const dialogRef = this.dialog.open(CommunicationAddressPhoneComponent, {
      data: {
        phone,
      },
    });
    const sub = dialogRef.afterClosed().subscribe((result) => {
      if (result && result.phone) {
        this.update.emit({ obj: result.phone, index });
      }
    });
    this.subscriptions.push(sub);
  }

  onDelete(phone: SuiteCrmPartnerAddressPhone): void {
    this.delete.emit(phone);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
