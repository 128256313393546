import { Action } from "@ngrx/store";
import { Endpoint } from "../../services/uwl/uwl.service";

// Load Endpoints
export const LOAD_ENDPOINTS = "[Endpoints] Load Endpoints";
export const LOAD_ENDPOINTS_SUCCESS = "[Endpoints] Load Endpoints Success";
export const LOAD_ENDPOINTS_FAIL = "[Endpoints] Load Endpoints Fail";

export class LoadEndpoints implements Action {
  readonly type = LOAD_ENDPOINTS;
}

export class LoadEndpointsSuccess implements Action {
  readonly type = LOAD_ENDPOINTS_SUCCESS;
  constructor(public payload: Endpoint[]) {}
}

export class LoadEndpointsFail implements Action {
  readonly type = LOAD_ENDPOINTS_FAIL;
  constructor(public payload: any) {}
}

// Create Endpoint
export const CREATE_ENDPOINT = "[Endpoints] Create Endpoint";
export const CREATE_ENDPOINT_SUCCESS = "[Endpoints] Create Endpoint Success";
export const CREATE_ENDPOINT_FAIL = "[Endpoints] Create Endpoint Fail";

export class CreateEndpoint implements Action {
  readonly type = CREATE_ENDPOINT;
  constructor(public payload: Endpoint) {}
}

export class CreateEndpointSuccess implements Action {
  readonly type = CREATE_ENDPOINT_SUCCESS;
  constructor(public payload: Endpoint) {}
}

export class CreateEndpointFail implements Action {
  readonly type = CREATE_ENDPOINT_FAIL;
  constructor(public payload: any) {}
}

// Update Endpoint
export const UPDATE_ENDPOINT = "[Endpoints] Update Endpoint";
export const UPDATE_ENDPOINT_SUCCESS = "[Endpoints] Update Endpoint Success";
export const UPDATE_ENDPOINT_FAIL = "[Endpoints] Update Endpoint Fail";

export class UpdateEndpoint implements Action {
  readonly type = UPDATE_ENDPOINT;
  constructor(public payload: Endpoint) {}
}

export class UpdateEndpointSuccess implements Action {
  readonly type = UPDATE_ENDPOINT_SUCCESS;
  constructor(public payload: Endpoint) {}
}

export class UpdateEndpointFail implements Action {
  readonly type = UPDATE_ENDPOINT_FAIL;
  constructor(public payload: any) {}
}

// Delete Endpoint
export const DELETE_ENDPOINT = "[Endpoints] Delete Endpoint";
export const DELETE_ENDPOINT_SUCCESS = "[Endpoints] Delete Endpoint Success";
export const DELETE_ENDPOINT_FAIL = "[Endpoints] Delete Endpoint Fail";

export class DeleteEndpoint implements Action {
  readonly type = DELETE_ENDPOINT;
  constructor(public payload: Endpoint) {}
}

export class DeleteEndpointSuccess implements Action {
  readonly type = DELETE_ENDPOINT_SUCCESS;
  constructor(public payload: Endpoint) {}
}

export class DeleteEndpointFail implements Action {
  readonly type = DELETE_ENDPOINT_FAIL;
  constructor(public payload: any) {}
}

export type EndpointActions =
  | LoadEndpoints
  | LoadEndpointsSuccess
  | LoadEndpointsFail
  | CreateEndpoint
  | CreateEndpointSuccess
  | CreateEndpointFail
  | UpdateEndpoint
  | UpdateEndpointSuccess
  | UpdateEndpointFail
  | DeleteEndpoint
  | DeleteEndpointSuccess
  | DeleteEndpointFail;
