<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ title | translate }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <incidents-table #incidentTable [incidentsList]="incidents$ | async"> </incidents-table>
  </div>
</div>
