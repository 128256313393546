import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as actions from "../actions/quick-action.actions";
import * as fromServices from "../../services";

@Injectable()
export class QuickActionsEffects {
  constructor(private actions$: Actions, private service: fromServices.QuickActionsService) {}

  loadQuickActions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LOAD_QUICK_ACTIONS),
      switchMap((payload: any) => {
        return this.service.getQuickActions().pipe(
          map((response) => {
            return new actions.LoadQuickActionsSuccess(response);
          }),
          catchError((error) => of(new actions.LoadQuickActionsFail(error))),
        );
      }),
    );
  });
}
