import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GematikProcessDiagram, GematikProcessDto } from "gematik-task-api";
import { GematikIncident } from "../../../models/gematik-incident.model";

export interface BpmnDialogData {
  dto: GematikIncident;
  diagram: GematikProcessDiagram;
}

@Component({
  selector: "app-gem-bpmn-incident-dialog",
  templateUrl: "./gem-bpmn-incident-dialog.component.html",
  styleUrls: ["./gem-bpmn-incident-dialog.component.scss"],
})
export class GemBpmnIncidentDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GemBpmnIncidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BpmnDialogData,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  confirmAction(): void {
    this.dialogRef.close();
  }

  openHelpDialog(id: string, width: string = "250px") {
    this.dialog.open(GemBpmnIncidentDialogComponent, {
      width: width,
      data: { id: id },
    });
  }
}
