import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as processActions from "../actions/process.actions";
import * as fromServices from "../../services";

@Injectable()
export class ProcessEffects {
  constructor(private actions$: Actions, private processService: fromServices.ProcessService) {}

  loadAllActiveProcesses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(processActions.LOAD_ALL_ACTIVE_PROCESSES),
      switchMap((payload: any) => {
        return this.processService.getAllOpen().pipe(
          map((response) => new processActions.LoadAllActiveProcessesSuccess(response)),
          catchError((error) => of(new processActions.LoadAllActiveProcessesFail(error))),
        );
      }),
    );
  });

  loadActiveProcesses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(processActions.LOAD_ACTIVE_PROCESSES),
      map((action: processActions.LoadActiveProcesses) => action.payload),
      switchMap((backendUrl) => {
        return this.processService.getOpen(backendUrl).pipe(
          map((response) => new processActions.LoadActiveProcessesSuccess(response, backendUrl)),
          catchError((error) => of(new processActions.LoadActiveProcessesFail(error))),
        );
      }),
    );
  });

  loadMyCompletedProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(processActions.LOAD_MY_COMPLETED_PROCESSES),
      switchMap((payload: any) => {
        return this.processService.getAllMyCompleted().pipe(
          map((response) => new processActions.LoadMyCompletedProcessesSuccess(response)),
          catchError((error) => of(new processActions.LoadMyCompletedProcessesFail(error))),
        );
      }),
    );
  });

  loadSubscriberCompletedTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(processActions.LOAD_SUBSCRIBER_COMPLETED_PROCESSES),
      switchMap((payload: any) => {
        return this.processService.getAllSubscriberCompleted().pipe(
          map((response) => new processActions.LoadSubscriberCompletedProcessesSuccess(response)),
          catchError((error) => of(new processActions.LoadSubscriberCompletedProcessesFail(error))),
        );
      }),
    );
  });

  loadProcessTotals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(processActions.LOAD_PROCESS_TOTALS),
      switchMap((payload: any) => {
        return this.processService.getProcessTotals().pipe(
          map((response) => new processActions.LoadProcessTotalsSuccess(response)),
          catchError((error) => of(new processActions.LoadProcessTotalsFail(error))),
        );
      }),
    );
  });
}
