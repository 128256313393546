<div class="d-flex flex-wrap">
  <gem-filter
    [title]="'buid' | translate"
    [searchLabel]="'buidSearch' | translate"
    [options]="buidFilterOptions$ | async"
    [value]="getSelectedFilters('buid')"
    [reset]="reset"
    (change)="onFilterChange($event, 'buid')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'partner' | translate"
    [searchLabel]="'partnerSearch' | translate"
    [options]="partnerFilterOptions$ | async"
    [value]="getSelectedFilters('partner')"
    [reset]="reset"
    (change)="onFilterChange($event, 'partner')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'partnerType' | translate"
    [searchLabel]="'partnerTypeSearch' | translate"
    [options]="partnerTypeFilterOptions$ | async"
    [value]="getSelectedFilters('partnerType')"
    [reset]="reset"
    (change)="onFilterChange($event, 'partnerType')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'partnerRole' | translate"
    [searchLabel]="'partnerRoleSearch' | translate"
    [options]="partnerRoleFilterOptions$ | async"
    [value]="getSelectedFilters('role')"
    [reset]="reset"
    (change)="onFilterChange($event, 'role')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'processName' | translate"
    [searchLabel]="'processNameSearch' | translate"
    [options]="processNameFilterOptions$ | async"
    [value]="getSelectedFilters('name')"
    [reset]="reset"
    (change)="onFilterChange($event, 'name')"
    class="mt-1 me-1"
  ></gem-filter>
</div>

<div class="md-chips d-flex flex-wrap">
  <div *ngFor="let selectedFilter of selectedFilters | keyvalue" class="d-flex flex-wrap">
    <div *ngFor="let sf of selectedFilter.value">
      <button
        *ngIf="sf.label"
        class="chip mt-1 me-1"
        (click)="onRemoveFilter(selectedFilter.key, sf)"
      >
        {{ sf.label | translate }}
      </button>
    </div>
  </div>
</div>

<div class="mat-table-responsive-container w-100">
  <table
    mat-table
    matSort
    multiTemplateDataRows
    [dataSource]="dataSource"
    (matSortChange)="onMatSortChange($event)"
    [ngStyle]="{
      'background-color': handsetBreakpointMatched ? 'inherit' : 'var(--mat-table-background-color)'
    }"
  >
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-icon
          mat-list-icon
          class="chevron-button"
          [ngClass]="{ rotated: expandedElement === row }"
          (click)="expandedElement = expandedElement === row ? null : row"
          >chevron_right
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="buid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "buid" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.buid | highlightSearch : filterText"
        (click)="expandedElement = expandedElement === row ? null : row"
      ></td>
    </ng-container>

    <ng-container matColumnDef="partner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partner" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.partner | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="partnerType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partnerType" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.partnerType | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "processOwner" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.owner | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="processName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "processName" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.name | translate | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="currentActvityName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "currentActvityName" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.currentActvityName | translate | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="assignee">
      <th mat-header-cell *matHeaderCellDef>{{ "assignee" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.assignee | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="createdTimeStamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "createdTimeStamp" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.created | date : 'dd.MM.YYYY HH:mm' | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="lastUpdate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ (completed ? "completedOn" : "lastUpdate") | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [innerHTML]="row.lastUpdate | date : 'dd.MM.YYYY HH:mm' | highlightSearch : filterText"
        (click)="processRowClicked(row)"
      ></td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="justify-content-center">
        {{ "actions" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [attr.data-label]="'actions' | translate"
        class="text-center"
      >
        <mat-icon
          *ngIf="isUserInternal(row)"
          svgIcon="gem-open-in-new"
          [title]="'caseLogs' | translate"
          (click)="onViewCaseLogs(row)"
        ></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
        [ngStyle]="{
          'border-bottom-width': handsetBreakpointMatched
            ? 'inherit'
            : 'var(--mat-table-row-item-outline-width, 1px)'
        }"
      >
        <div
          class="custom-info-details"
          [@detailExpand]="
            element == expandedElement && !handsetBreakpointMatched ? 'expanded' : 'collapsed'
          "
        >
          <div class="custom-info-details-data" style="width: 100%">
            <div *ngIf="!tabletBreakpointMatched">
              <div class="row">
                <div class="col-sm-12">{{ "portal.labels.information.label" | translate }}</div>
              </div>
              <div class="row">
                <div *ngIf="element.customProcessContentOne" class="col-sm-12">
                  <div>
                    <strong>{{ element.customProcessContentOneLabel | translate }}: </strong>
                    <span>{{ getCustomContentData(element.customProcessContentOne) }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div *ngIf="element.customProcessContentTwo" class="col-sm-12">
                  <div>
                    <strong>{{ element.customProcessContentTwoLabel | translate }}: </strong>
                    <span>
                      {{ getCustomContentData(element.customProcessContentTwo) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div *ngIf="element.customProcessContentThree" class="col-sm-12">
                  <div>
                    <strong>{{ element.customProcessContentThreeLabel | translate }}: </strong>
                    <span>
                      {{ getCustomContentData(element.customProcessContentThree) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="tabletBreakpointMatched">
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "partner" | translate }}:</strong>
                  </div>
                  <div>{{ element.partner }}</div>
                </div>
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "partnerType" | translate }}:</strong>
                  </div>
                  <div>{{ element.partnerType }}</div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "processOwner" | translate }}:</strong>
                  </div>
                  <div>{{ element.role }}</div>
                </div>
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "processName" | translate }}:</strong>
                  </div>
                  <div>{{ element.name | translate }}</div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "currentActvityName" | translate }}:</strong>
                  </div>
                  <div>{{ element.currentActvityName | translate }}</div>
                </div>
                <div class="col-sm-6">
                  <div>
                    <strong>{{ (completed ? "completedOn" : "lastUpdate") | translate }}:</strong>
                  </div>
                  <div>{{ element.lastUpdate | date : "dd.MM.YYYY HH:mm" }}</div>
                </div>
              </div>
              <div class="row mt-2">
                <div *ngIf="element.customProcessContentOne" class="col-sm-6">
                  <div>
                    <strong>{{ element.customProcessContentOneLabel | translate }}:</strong>
                  </div>
                  <div>{{ getCustomContentData(element.customProcessContentOne) }}</div>
                </div>
                <div *ngIf="element.customProcessContentTwo" class="col-sm-6">
                  <div>
                    <strong>{{ element.customProcessContentTwoLabel | translate }}:</strong>
                  </div>
                  <div>{{ getCustomContentData(element.customProcessContentTwo) }}</div>
                </div>
              </div>
              <div class="row mt-2">
                <div *ngIf="element.customProcessContentThree" class="col-sm-6">
                  <div>
                    <strong>{{ element.customProcessContentThreeLabel | translate }}:</strong>
                  </div>
                  <div>{{ getCustomContentData(element.customProcessContentThree) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="handset">
      <th mat-header-cell *matHeaderCellDef>Handset</th>
      <div mat-cell *matCellDef="let row">
        <div
          style="
            border-radius: 15px 15px 0px 0px;
            background: var(--gematik-blue);
            border-color: var(--gematik-blue);
            border-style: solid;
            border-width: 1px;
            padding: 20px;
            width: 100%;
            height: 100%;
          "
          (click)="processRowClicked(row)"
        >
          <div style="color: white">
            <div>
              <strong>{{ "buid" | translate }}: {{ row.buid }}</strong>
            </div>
            <div style="font-size: 0.8em">
              {{ "createdTimeStamp" | translate }}:
              {{ row.created | date : "dd.MM.YYYY HH:mm" }}
            </div>
          </div>
        </div>
        <div
          style="
            border-radius: 0px 0px 0px 0px;
            border-color: var(--gematik-blue);
            border-style: solid;
            border-width: 1px;
            background: white;
            padding: 20px;
            width: 100%;
            height: 100%;
          "
        >
          <div (click)="processRowClicked(row)">
            <div>
              <div class="text-muted" style="font-size: 0.8em">{{ "partner" | translate }}:</div>
              <div style="font-size: 0.8em">{{ row.partner }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ "partnerType" | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.partnerType }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ "processOwner" | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.role }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ "processName" | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.name | translate }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ "currentActvityName" | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.currentActvityName | translate }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ (completed ? "completedOn" : "lastUpdate") | translate }}
              </div>
              <div style="font-size: 0.8em">
                {{ row.lastUpdate | date : "dd.MM.YYYY HH:mm" }}
              </div>
            </div>
            <div *ngIf="row.customProcessContentOne" class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ row.customProcessContentOneLabel | translate }}:
              </div>
              <div style="font-size: 0.8em">
                {{ getCustomContentData(row.customProcessContentOne) }}
              </div>
            </div>
            <div *ngIf="row.customProcessContentTwo" class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ row.customProcessContentTwoLabel | translate }}:
              </div>
              <div style="font-size: 0.8em">
                {{ getCustomContentData(row.customProcessContentTwo) }}
              </div>
            </div>
            <div *ngIf="row.customProcessContentThree" class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ row.customProcessContentThreeLabel | translate }}:
              </div>
              <div style="font-size: 0.8em">
                {{ getCustomContentData(row.customProcessContentThree) }}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h4>{{ "actions" | translate }}</h4>
            <div *ngIf="isUserInternal(row)" class="d-flex" (click)="onViewCaseLogs(row)">
              <mat-icon svgIcon="gem-open-in-new" [title]="'caseLogs' | translate"></mat-icon>
              {{ "portal.buttons.caseLogs.label" | translate }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      [hidden]="handsetBreakpointMatched"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.custom-info-detail-row-selected]="expandedElement === row"
      style="cursor: pointer"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="custom-info-detail-row"
      [ngStyle]="{ height: handsetBreakpointMatched ? '20px' : '0px' }"
    ></tr>
  </table>
  <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
</div>
