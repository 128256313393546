import { Action } from "@ngrx/store";
import { ITemplate } from "../../services/uwl/template.service";

// Load Endpoints
export const LOAD_TEMPLATES = "[Templates] Load Template";
export const LOAD_TEMPLATES_SUCCESS = "[Templates] Load Template Success";
export const LOAD_TEMPLATES_FAIL = "[Templates] Load Template Fail";

export class LoadTemplates implements Action {
  readonly type = LOAD_TEMPLATES;
}

export class LoadTemplatesSuccess implements Action {
  readonly type = LOAD_TEMPLATES_SUCCESS;
  constructor(public payload: ITemplate[]) {}
}

export class LoadTemplatesFail implements Action {
  readonly type = LOAD_TEMPLATES_FAIL;
  constructor(public payload: any) {}
}

// Create Template
export const CREATE_TEMPLATE = "[Templates] Create Template";
export const CREATE_TEMPLATE_SUCCESS = "[Templates] Create Template Success";
export const CREATE_TEMPLATE_FAIL = "[Templates] Create Template Fail";

export class CreateTemplate implements Action {
  readonly type = CREATE_TEMPLATE;
  constructor(public payload: ITemplate) {}
}

export class CreateTemplateSuccess implements Action {
  readonly type = CREATE_TEMPLATE_SUCCESS;
  constructor(public payload: ITemplate) {}
}

export class CreateTemplateFail implements Action {
  readonly type = CREATE_TEMPLATE_FAIL;
  constructor(public payload: any) {}
}

// Update Template
export const UPDATE_TEMPLATE = "[Templates] Update Template";
export const UPDATE_TEMPLATE_SUCCESS = "[Templates] Update Template Success";
export const UPDATE_TEMPLATE_FAIL = "[Templates] Update Template Fail";

export class UpdateTemplate implements Action {
  readonly type = UPDATE_TEMPLATE;
  constructor(public payload: ITemplate) {}
}

export class UpdateTemplateSuccess implements Action {
  readonly type = UPDATE_TEMPLATE_SUCCESS;
  constructor(public payload: ITemplate) {}
}

export class UpdateTemplateFail implements Action {
  readonly type = UPDATE_TEMPLATE_FAIL;
  constructor(public payload: any) {}
}

// Delete Template
export const DELETE_TEMPLATE = "[Templates] Delete Template";
export const DELETE_TEMPLATE_SUCCESS = "[Templates] Delete Template Success";
export const DELETE_TEMPLATE_FAIL = "[Templates] Delete Template Fail";

export class DeleteTemplate implements Action {
  readonly type = DELETE_TEMPLATE;
  constructor(public payload: ITemplate) {}
}

export class DeleteTemplateSuccess implements Action {
  readonly type = DELETE_TEMPLATE_SUCCESS;
  constructor(public payload: ITemplate) {}
}

export class DeleteTemplateFail implements Action {
  readonly type = DELETE_TEMPLATE_FAIL;
  constructor(public payload: any) {}
}

export type TemplateActions =
  | LoadTemplates
  | LoadTemplatesSuccess
  | LoadTemplatesFail
  | CreateTemplate
  | CreateTemplateSuccess
  | CreateTemplateFail
  | UpdateTemplate
  | UpdateTemplateSuccess
  | UpdateTemplateFail
  | DeleteTemplate
  | DeleteTemplateSuccess
  | DeleteTemplateFail;
