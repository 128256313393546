import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as profileActions from "../actions/profile.actions";
import * as fromServices from "../../services";
import { SnackBarService } from "gematik-shared";

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: fromServices.PersonalProfileService,
    private snackBarService: SnackBarService,
  ) {}

  loadProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LOAD_PROFILE),
      switchMap((payload: any) => {
        return this.profileService.getProfile().pipe(
          map((response) => new profileActions.LoadProfileSuccess(response)),
          catchError((error) => of(new profileActions.LoadProfileFail(error))),
        );
      }),
    );
  });

  updateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UPDATE_PROFILE),
      map((action: profileActions.UpdateProfile) => action.payload),
      switchMap((profile) => {
        return this.profileService.updateProfile(profile).pipe(
          map((response) => {
            this.snackBarService.openSuccess("Successfully updated profile");
            return new profileActions.UpdateProfileSuccess(response);
          }),
          catchError((error) => of(new profileActions.UpdateProfileFail(error))),
        );
      }),
    );
  });
}
