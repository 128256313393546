<h1 mat-dialog-title>{{ title + " - " + ("buid" | translate) + ": " + data.task?.buid }}</h1>
<div class="row">
  <div class="col-sm-12 center">
    <p>
      {{ "partner" | translate }}: {{ data.task?.partner + "&nbsp;|&nbsp;" }}
      {{ "partnerType" | translate }}: {{ data.task?.partnerType + "&nbsp;|&nbsp;" }}
      {{ "partnerRole" | translate }}: {{ data.task?.role }} ({{ fullName }})
    </p>
    <p>
      {{
        data.task?.customTaskContentOneLabel
          ? data.task.customTaskContentOneLabel
          : ("customContentOne" | translate)
      }}: {{ data.task?.customTaskContentOne + "&nbsp;|&nbsp;" }}
      {{
        data.task?.customTaskContentTwoLabel
          ? data.task.customTaskContentTwoLabel
          : ("customContentTwo" | translate)
      }}: {{ data.task?.customTaskContentTwo + "&nbsp;|&nbsp;" }}
      {{
        data.task?.customTaskContentThreeLabel
          ? data.task.customTaskContentThreeLabel
          : ("customContentThree" | translate)
      }}: {{ data.task?.customTaskContentThree }}
    </p>
  </div>
</div>
<div mat-dialog-content>
  <p [innerHTML]="text"></p>
  <hr />
  <div #scroll class="scrollable-content">
    <p *ngIf="data.task?.htmlContent" [innerHTML]="data.task?.htmlContent"></p>
  </div>
  <div *ngIf="control">
    <br />
    <gem-textarea [placeholder]="inputFieldNameLabel" [control]="control"></gem-textarea>
    <br />
  </div>
  <br />
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary" (click)="confirmAction()" [disabled]="hasFieldData()">Ok</button>
</div>
