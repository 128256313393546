import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { GematikProcess } from "../../models/gematik-process.model";
import { GematikProcessTotals } from "../../models/gematik-process-totals.model";

export interface ProcessResponse {
  myProcesses: GematikProcess[];
  subscriberProcesses: GematikProcess[];
}

@Injectable({
  providedIn: "root",
})
export class ProcessService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getAllOpen(): Observable<ProcessResponse> {
    return this.http.get<ProcessResponse>(this.env.backendUrl + "/uwl/process/all/open");
  }

  getOpen(backendUrl: string): Observable<ProcessResponse> {
    const payload = {
      backendUrl,
    };
    return this.http.post<ProcessResponse>(`${this.env.backendUrl}/uwl/process/open`, payload);
  }

  getAllMyCompleted(): Observable<ProcessResponse> {
    return this.http.get<ProcessResponse>(this.env.backendUrl + "/uwl/process/all/my/completed");
  }

  getAllSubscriberCompleted(): Observable<ProcessResponse> {
    return this.http.get<ProcessResponse>(
      this.env.backendUrl + "/uwl/process/all/subscriber/completed",
    );
  }

  getProcessTotals(): Observable<GematikProcessTotals> {
    return this.http.get<GematikProcessTotals>(this.env.backendUrl + "/uwl/process/totals");
  }
}
