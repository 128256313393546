import { GematikTaskDto } from "gematik-task-api";
import * as DateFns from "date-fns";

export enum TaskAssignmentType {
  MY = "MY",
  GROUP = "GROUP",
  MY_AND_GROUP = "MY_AND_GROUP",
}

export enum TaskStatus {
  OPEN = "OPEN",
  COMPLETED = "COMPLETED",
}

export class GematikTask extends GematikTaskDto {
  assignmentType: TaskAssignmentType;
  status: TaskStatus;
}

export function sortTasksByCreatedDate(a: any, b: any) {
  const dateA = DateFns.parseISO(a.created).getTime();
  const dateB = DateFns.parseISO(b.created).getTime();
  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  return 0;
}
