import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromTemplates from "../reducers/template.reducer";

export const getTemplatesState = createFeatureSelector<fromTemplates.TemplateState>(
  fromTemplates.FEATURE_KEY,
);

export const getTemplateEntities = createSelector(
  getTemplatesState,
  fromTemplates.getTemplateEntities,
);

export const getTemplates = createSelector(getTemplateEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getTemplatesLoaded = createSelector(
  getTemplatesState,
  fromTemplates.getTemplatesLoaded,
);

export const getTemplatesLoading = createSelector(
  getTemplatesState,
  fromTemplates.getTemplatesLoading,
);
