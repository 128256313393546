import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sidenav-extension',
  templateUrl: './sidenav-extension.component.html',
  styleUrls: ['./sidenav-extension.component.scss'],
})
export class SidenavExtensionComponent implements OnInit {
  @Output() menuClicked = new EventEmitter();

  @Input() menuIcon: string;

  constructor() {}

  ngOnInit(): void {}
}
