import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { GematikIncident } from "../../models/gematik-incident.model";
import { GematikTaskApiService } from "gematik-task-api";

@Injectable({
  providedIn: "root",
})
export class IncidentService {
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private gematikApiTaskService: GematikTaskApiService,
  ) {}

  getAll(): Observable<GematikIncident[]> {
    return this.http.get<GematikIncident[]>(this.env.backendUrl + "/uwl/incident/all");
  }

  get(endpoint: string): Observable<GematikIncident[]> {
    const payload = {
      endpoint,
    };
    return this.http.post<GematikIncident[]>(this.env.backendUrl + "/uwl/incident", payload);
  }

  retry(incident: GematikIncident): Observable<any> {
    return this.gematikApiTaskService.retryFailedJob(incident.endpoint, incident.id, 1);
  }
}
