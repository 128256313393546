import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { GemFeedbackComponent } from "gematik-shared";
import { EnvService, TokenHelperService } from "gematik-form-library";
import { Observable, Subscription } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";

import * as fromStore from "../../store";
import { Store } from "@ngrx/store";

@Component({
  selector: "gem-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  count: string;
  lang: string;
  username: string;
  component: string;

  isAuthorized: Observable<boolean>;

  langSubscription: Subscription;
  routerSub: Subscription;

  constructor(
    private translate: TranslateService,
    public tokenHelperService: TokenHelperService,
    private env: EnvService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromStore.UwlState>,
  ) {}

  ngOnInit() {
    this.translate
      .get(`languages.${this.translate.currentLang === "en" ? "de" : "en"}`)
      .subscribe((res: string) => {
        this.lang = res;
      })
      .unsubscribe();

    this.langSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate
        .get(`languages.${event.lang === "en" ? "de" : "en"}`)
        .subscribe((res: string) => {
          this.lang = res;
        })
        .unsubscribe();
    });

    this.username = this.tokenHelperService.getFullname();

    this.isAuthorized = this.store.select(fromStore.isUserAuthorized);

    this.routerSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        this.component = event["component"];
      });
  }

  onLangClick() {
    if (this.translate.currentLang === "de") {
      this.translate.use("en");
      localStorage.setItem("gem-locale", "en");
    } else {
      this.translate.use("de");
      localStorage.setItem("gem-locale", "de");
    }
  }

  logout() {
    const url = window.location.origin;
    window.location.href = `https://login.windows.net/5b4217dc-2233-4650-9d4e-4f09010d9669/oauth2/logout?post_logout_redirect_uri=${url}`;
    window.sessionStorage.clear();
    localStorage.clear();
  }

  onFeedback(): void {
    // this.dialog.open(GemFeedbackDialogComponent, {
    //   data: {
    //     title: this.translate.instant("feedbackTitle"),
    //     text: this.translate.instant("feedbackText"),
    //     backendUrl: this.env.feedbackBackendUrl,
    //     application: "Aktivitätenübersicht",
    //     component: this.component,
    //   },
    //   width: "450px",
    // });
    this.dialog.open(GemFeedbackComponent, {
      data: {
        backendUrl: this.env.feedbackBackendUrl,
        application: "Aktivitätenübersicht",
        component: this.component,
      },
      width: "500px",
    });
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
