import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromTemplates from "../reducers/template.reducer";

export const getTemplatesState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.templates,
);

export const getTemplateEntities = createSelector(
  getTemplatesState,
  fromTemplates.getTemplateEntities,
);

export const getTemplates = createSelector(getTemplateEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getTemplatesLoaded = createSelector(
  getTemplatesState,
  fromTemplates.getTemplatesLoaded,
);

export const getTemplatesLoading = createSelector(
  getTemplatesState,
  fromTemplates.getTemplatesLoading,
);
