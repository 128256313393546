import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormHelperService, GemValidators } from "gematik-form-library";
import { SuiteCrmPartnerAddressPhone } from "gematik-shared";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "communication-address-phone-form",
  styleUrls: ["communication-address-phone-form.component.scss"],
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>
            <div class="d-flex">
              <mat-icon class="nav-icons me-1" svgIcon="gem-report"></mat-icon>
              <strong> Phone </strong>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="mt-2"> </mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content class="mt-4">
        <div class="row">
          <form [formGroup]="form">
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.phones.form.phone.label'
                "
                [control]="phone"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.phones.form.description.label'
                "
                [control]="description"
              ></gem-input>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-end mt-2">
              <button data-testid="save" class="btn btn-primary" color="primary" (click)="onSave()">
                {{ "portal.profile.buttons.save" | translate }}
              </button>
              <button
                data-testid="cancel"
                class="btn btn-secondary"
                color="primary"
                (click)="onCancel()"
              >
                {{ "portal.profile.buttons.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class CommunicationAddressPhoneFormComponent implements OnInit {
  @Input() addressPhone: SuiteCrmPartnerAddressPhone;
  @Output() save: EventEmitter<SuiteCrmPartnerAddressPhone> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  form: FormGroup = this.fb.group({
    id: [uuidv4()],
    phone: [null, [Validators.required, GemValidators.telephone]],
    description: [],
  });

  constructor(private fb: FormBuilder, private formHelper: FormHelperService) {}

  ngOnInit(): void {
    this.form.patchValue(this.addressPhone);
  }

  onSave(): void {
    this.formHelper.isFormValidElseShowErrors(
      this.form,
      "Form Validation",
      () => {
        this.save.emit(this.form.getRawValue());
      },
      true,
      true,
      "portal.profile.organisational.organisationAddress.phones.form.",
    );
  }

  onCancel(): void {
    this.cancel.emit();
  }

  get phone(): FormControl {
    return this.form.get("phone") as FormControl;
  }

  get description(): FormControl {
    return this.form.get("description") as FormControl;
  }
}
