import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, filter, first, tap } from "rxjs/operators";

@Injectable()
export class EndpointResolver  {
  constructor() {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(true);
    // return this.uwlEndpointService.loaded$.pipe(
    //   tap((loaded) => {
    //     if (!loaded) {
    //       this.uwlEndpointService.getAll();
    //     }
    //   }),
    //   filter((loaded) => !!loaded),
    //   first(),
    // );
  }
}
