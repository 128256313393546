import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { EntityActionOptions, MergeStrategy } from "@ngrx/data";
import { Endpoint, UwlService } from "../../services/uwl/uwl.service";
import { Store } from "@ngrx/store";
import * as fromStore from "../../store";

@Component({
  selector: "endpoint-config-page",
  templateUrl: "./endpoint-config-page.component.html",
  styleUrls: ["./endpoint-config-page.component.scss"],
})
export class EndpointConfigPageComponent implements OnInit {
  entityList: Endpoint[];
  entityToAdd: Endpoint = {
    id: -1,
    url: "",
    name: "",
    roles: "",
    uuid: "",
    labelEn: "",
    labelDe: "",
    icon: "",
    enabledInMyServices: false,
    link: "",
  };

  selectedEntity: Endpoint;

  constructor(private uwlService: UwlService, private store: Store<fromStore.UwlState>) {}

  ngOnInit(): void {
    this.getAllEntities();
  }

  createEditEntity(entityForm: UntypedFormGroup) {
    if (entityForm) {
      const entity: Endpoint = this.uwlService.mapFormToUwlEndpoint(entityForm);
      if (entity.id === -1) {
        this.store.dispatch(new fromStore.CreateEndpoint(entity));
      } else {
        this.store.dispatch(new fromStore.UpdateEndpoint(entity));
      }
    } else {
      this.selectedEntity = null;
    }
  }

  getAllEntities() {
    this.store.select(fromStore.getEndpoints).subscribe((endpoints) => {
      this.entityList = endpoints;
      this.selectedEntity = null;
    });
  }

  deleteEntity(entity: Endpoint) {
    // this.uwlEndpointService.delete(entity.id);
    this.store.dispatch(new fromStore.DeleteEndpoint(entity));
  }

  selectEntity(entity: Endpoint) {
    this.selectedEntity = entity;
  }

  addEntityClicked() {
    const object: Endpoint = {
      id: -1,
      url: "",
      name: "",
      roles: "",
      uuid: "",
      labelEn: "",
      labelDe: "",
      icon: "",
      enabledInMyServices: false,
      link: "",
    };
    this.selectedEntity = object;
  }

  restartWebSocket(entity: Endpoint) {
    // const endpoint: Endpoint = {
    //   ...entity,
    // };
    // this.uwlEndpointService.update(endpoint);
  }
}
