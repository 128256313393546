import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromTaskTotals from "../reducers/task-totals.reducer";

export const getTaskTotalsState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.taskTotals,
);

export const getTaskTotals = createSelector(getTaskTotalsState, fromTaskTotals.getTaskTotals);

export const getMyTaskTotals = createSelector(getTaskTotals, (totals) => {
  return {
    open: totals.open.my,
    due: totals.due.my,
    completed: totals.completed.my,
  };
});

export const getGroupTaskTotals = createSelector(getTaskTotals, (totals) => {
  return {
    open: totals.open.group,
    due: totals.due.group,
    completed: totals.completed.group,
  };
});
