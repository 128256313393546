import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";

import BpmnJS from "bpmn-js/dist/bpmn-navigated-viewer.production.min.js";
import { GematikProcessDiagram } from "gematik-task-api";

@Component({
  selector: "gem-bpmn-diagram",
  templateUrl: "./gem-bpmn-diagram.component.html",
  styleUrls: ["./gem-bpmn-diagram.component.scss"],
})
export class GemBpmnDiagramComponent implements OnInit {
  @ViewChild("ref", { static: true }) private el: ElementRef;
  @Input() diagram: GematikProcessDiagram;
  @Input() isIncident: Boolean = false;

  private bpmnJS: BpmnJS;

  constructor() {}

  async ngOnInit() {
    await this.initBpmnJs();
  }

  async initBpmnJs() {
    this.bpmnJS = new BpmnJS();

    try {
      await this.bpmnJS.importXML(this.diagram.bpmn);
    } catch (err) {
      console.log(err.message, err.warnings);
    }
    var overlays = this.bpmnJS.get("overlays");
    var elementRegistry = this.bpmnJS.get("elementRegistry");
    for (const activity of this.diagram.currentActivityList) {
      var shape = elementRegistry.get(activity.currentActivity);
      if (shape) {
        if (!activity.incident) {
          var $overlayHtml =
            `<div class="highlight-overlay" style="width: ` +
            shape.width +
            `px; height: ` +
            shape.height +
            `px">
            </div>`;
        } else {
          this.isIncident = true;
          var $overlayHtml =
            `<div class="highlight-incident-overlay" style="width: ` +
            shape.width +
            `px; height: ` +
            shape.height +
            `px">
          </div>`;
        }

        overlays.add(activity.currentActivity, {
          position: {
            top: 0,
            left: 0,
          },
          html: $overlayHtml,
        });
      }
    }
    this.bpmnJS.attachTo(this.el.nativeElement);
  }
}
