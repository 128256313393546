import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { GemConfirmationDialogComponent } from "gematik-form-library";
import { UwlHelpConfig } from "../../../services/uwl/uwl.service";

@Component({
  selector: "help-config-table",
  templateUrl: "./help-config-table.component.html",
  styleUrls: ["./help-config-table.component.scss"],
})
export class HelpConfigTableComponent implements OnInit {
  sort: any;
  _entityList: UwlHelpConfig[];

  @Input() set entityList(entitylist: UwlHelpConfig[]) {
    this._entityList = entitylist;
    this.dataSource = new MatTableDataSource(entitylist);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  @Output() entitySelected = new EventEmitter<UwlHelpConfig>();
  @Output() deleteEntityClicked = new EventEmitter<UwlHelpConfig>();

  displayedColumns: string[] = ["name", "lang", "title", "html", "buttons"];
  dataSource: MatTableDataSource<UwlHelpConfig>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {}
  selectEntity(entity: UwlHelpConfig) {
    this.entitySelected.emit(entity);
  }

  openDeleteEntityConfirmation(entity: UwlHelpConfig) {
    const name = entity.id;
    const title = this.translateService.instant("deleteEntityConfirmationTitle");
    const text = this.translateService.instant("deleteEntityConfirmationText", { name });
    this.openConfirmationDialog(title, text, () => this.deleteEntityClicked.emit(entity));
  }

  openConfirmationDialog(title: string, text: string, confirmAction: any): void {
    this.dialog.open(GemConfirmationDialogComponent, {
      data: {
        title,
        text,
        confirmAction,
      },
    });
  }
}
