import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as organisationalProfileActions from "../actions/organisational-profile.actions";
import * as routerActions from "../actions/router.actions";
import * as fromServices from "../../services";
import * as fromStore from "../../store";
import { Store } from "@ngrx/store";

import { SnackBarService } from "gematik-shared";

@Injectable()
export class OrganisationalProfileEffects {
  constructor(
    private actions$: Actions,
    private service: fromServices.OrganisationalProfileService,
    private store: Store<fromStore.UwlState>,
    private snackBarService: SnackBarService,
  ) {}

  loadOrganisations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.LOAD_ORGANISATIONS),
      switchMap((payload: any) => {
        return this.service.getPartners().pipe(
          map((response) => new organisationalProfileActions.LoadOrganisationsSuccess(response)),
          catchError((error) => of(new organisationalProfileActions.LoadOrganisationsFail(error))),
        );
      }),
    );
  });

  updateOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.UPDATE_ORGANISATION),
      map((action: organisationalProfileActions.UpdateOrganisation) => action.payload),
      switchMap((payload) => {
        return this.service.updatePartner(payload.id, payload).pipe(
          map((response) => {
            this.store.dispatch(
              new routerActions.RouterNavigate(`/profile/organisational/organisations`),
            );
            this.snackBarService.openSuccess("Organisation updated successfully");
            return new organisationalProfileActions.UpdateOrganisationSuccess(response);
          }),
          catchError((error) => of(new organisationalProfileActions.UpdateOrganisationFail(error))),
        );
      }),
    );
  });

  loadOrganisationRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.LOAD_ORGANISATION_ROLES),
      switchMap((payload) => {
        return this.service.getPartnerRoles(payload["orgId"]).pipe(
          map(
            (response) =>
              new organisationalProfileActions.LoadOrganisationRolesSuccess(
                response,
                payload["orgId"],
              ),
          ),
          catchError((error) =>
            of(new organisationalProfileActions.LoadOrganisationRolesFail(error)),
          ),
        );
      }),
    );
  });

  loadOrganisationRoleAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.LOAD_ORGANISATION_ROLE_ADDRESSES),
      switchMap((payload) => {
        return this.service.getPartnerRoleAddresses(payload["orgId"], payload["orgRoleId"]).pipe(
          map(
            (response) =>
              new organisationalProfileActions.LoadOrganisationRoleAddressesSuccess(
                response,
                payload["orgId"],
                payload["orgRoleId"],
              ),
          ),
          catchError((error) =>
            of(new organisationalProfileActions.LoadOrganisationRoleAddressesFail(error)),
          ),
        );
      }),
    );
  });

  createOrganisationRoleAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.CREATE_ORGANISATION_ROLE_ADDRESS),
      map((action: organisationalProfileActions.CreateOrganisationRoleAddress) => action),
      switchMap((payload) => {
        return this.service
          .createPartnerRoleAddress(payload.orgId, payload.orgRoleId, payload.payload)
          .pipe(
            map((response) => {
              this.store.dispatch(
                new routerActions.RouterNavigate(
                  `/profile/organisational/organisations/${payload.orgId}`,
                ),
              );
              this.snackBarService.openSuccess("Address created successfully");
              return new organisationalProfileActions.CreateOrganisationRoleAddressSuccess(
                response,
                payload.orgId,
                payload.orgRoleId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.CreateOrganisationRoleAddressFail(error)),
            ),
          );
      }),
    );
  });

  updateOrganisationRoleAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.UPDATE_ORGANISATION_ROLE_ADDRESS),
      map((action: organisationalProfileActions.UpdateOrganisationRoleAddress) => action),
      switchMap((payload) => {
        return this.service
          .updatePartnerRoleAddress(payload.orgId, payload.orgRoleId, payload.payload)
          .pipe(
            map((response) => {
              this.store.dispatch(
                new routerActions.RouterNavigate(
                  `/profile/organisational/organisations/${payload.orgId}`,
                ),
              );
              this.snackBarService.openSuccess("Address updated successfully");
              return new organisationalProfileActions.UpdateOrganisationRoleAddressSuccess(
                response,
                payload.orgId,
                payload.orgRoleId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.UpdateOrganisationRoleAddressFail(error)),
            ),
          );
      }),
    );
  });

  deleteOrganisationRoleAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.DELETE_ORGANISATION_ROLE_ADDRESS),
      map((action: organisationalProfileActions.DeleteOrganisationRoleAddress) => action),
      switchMap((payload) => {
        return this.service
          .deletePartnerRoleAddress(payload.orgId, payload.orgRoleId, payload.addressId)
          .pipe(
            map((response) => {
              this.store.dispatch(
                new routerActions.RouterNavigate(
                  `/profile/organisational/organisations/${payload.orgId}`,
                ),
              );
              this.snackBarService.openSuccess("Address deleted successfully");
              return new organisationalProfileActions.DeleteOrganisationRoleAddressSuccess(
                payload.orgId,
                payload.orgRoleId,
                payload.addressId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.DeleteOrganisationRoleAddressFail(error)),
            ),
          );
      }),
    );
  });

  createOrganisationRoleAddressMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.CREATE_ORGANISATION_ROLE_ADDRESS_MAIL),
      map((action: organisationalProfileActions.CreateOrganisationRoleAddressMail) => action),
      switchMap((payload) => {
        return this.service
          .createPartnerRoleAddressMail(
            payload.orgId,
            payload.orgRoleId,
            payload.addressId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Mail created successfully");
              return new organisationalProfileActions.CreateOrganisationRoleAddressMailSuccess(
                response,
                payload.orgId,
                payload.orgRoleId,
                payload.addressId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.CreateOrganisationRoleAddressMailFail(error)),
            ),
          );
      }),
    );
  });

  updateOrganisationRoleAddressMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL),
      map((action: organisationalProfileActions.UpdateOrganisationRoleAddressMail) => action),
      switchMap((payload) => {
        return this.service
          .updatePartnerRoleAddressMail(
            payload.orgId,
            payload.orgRoleId,
            payload.addressId,
            payload.mailId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Mail updated successfully");
              return new organisationalProfileActions.UpdateOrganisationRoleAddressMailSuccess(
                response,
                payload.orgId,
                payload.orgRoleId,
                payload.addressId,
                payload.mailId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.UpdateOrganisationRoleAddressMailFail(error)),
            ),
          );
      }),
    );
  });

  deleteOrganisationRoleAddressMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.DELETE_ORGANISATION_ROLE_ADDRESS_MAIL),
      map((action: organisationalProfileActions.DeleteOrganisationRoleAddressMail) => action),
      switchMap((payload) => {
        return this.service
          .deletePartnerRoleAddressMail(
            payload.orgId,
            payload.orgRoleId,
            payload.addressId,
            payload.mailId,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Mail deleted successfully");
              return new organisationalProfileActions.DeleteOrganisationRoleAddressMailSuccess(
                payload.orgId,
                payload.orgRoleId,
                payload.addressId,
                payload.mailId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.DeleteOrganisationRoleAddressMailFail(error)),
            ),
          );
      }),
    );
  });

  createOrganisationRoleAddressPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.CREATE_ORGANISATION_ROLE_ADDRESS_PHONE),
      map((action: organisationalProfileActions.CreateOrganisationRoleAddressPhone) => action),
      switchMap((payload) => {
        return this.service
          .createPartnerRoleAddressPhone(
            payload.orgId,
            payload.orgRoleId,
            payload.addressId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Phone created successfully");
              return new organisationalProfileActions.CreateOrganisationRoleAddressPhoneSuccess(
                response,
                payload.orgId,
                payload.orgRoleId,
                payload.addressId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.CreateOrganisationRoleAddressPhoneFail(error)),
            ),
          );
      }),
    );
  });

  updateOrganisationRoleAddressPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE),
      map((action: organisationalProfileActions.UpdateOrganisationRoleAddressPhone) => action),
      switchMap((payload) => {
        return this.service
          .updatePartnerRoleAddressPhone(
            payload.orgId,
            payload.orgRoleId,
            payload.addressId,
            payload.phoneId,
            payload.payload,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Phone updated successfully");
              return new organisationalProfileActions.UpdateOrganisationRoleAddressPhoneSuccess(
                response,
                payload.orgId,
                payload.orgRoleId,
                payload.addressId,
                payload.phoneId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.UpdateOrganisationRoleAddressPhoneFail(error)),
            ),
          );
      }),
    );
  });

  deleteOrganisationRoleAddressPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organisationalProfileActions.DELETE_ORGANISATION_ROLE_ADDRESS_PHONE),
      map((action: organisationalProfileActions.DeleteOrganisationRoleAddressPhone) => action),
      switchMap((payload) => {
        return this.service
          .deletePartnerRoleAddressPhone(
            payload.orgId,
            payload.orgRoleId,
            payload.addressId,
            payload.phoneId,
          )
          .pipe(
            map((response) => {
              this.snackBarService.openSuccess("Phone deleted successfully");
              return new organisationalProfileActions.DeleteOrganisationRoleAddressPhoneSuccess(
                payload.orgId,
                payload.orgRoleId,
                payload.addressId,
                payload.phoneId,
              );
            }),
            catchError((error) =>
              of(new organisationalProfileActions.DeleteOrganisationRoleAddressPhoneFail(error)),
            ),
          );
      }),
    );
  });
}
