<!-- <mat-card class="sticky"> -->
<mat-card appearance="outlined" style="margin-top: 15px">
  <mat-card-content>
    <form [formGroup]="entityForm">
      <div class="row">
        <div class="col-sm-12">
          <gem-input [placeholder]="'name'" [control]="entityForm.get('name')"></gem-input>
          <gem-input [placeholder]="'url'" [control]="entityForm.get('url')"></gem-input>
          <gem-input [placeholder]="'roles'" [control]="entityForm.get('roles')"></gem-input>
          <gem-input
            [placeholder]="'endpoint.labelEn'"
            [control]="entityForm.get('labelEn')"
          ></gem-input>
          <gem-input
            [placeholder]="'endpoint.labelDe'"
            [control]="entityForm.get('labelDe')"
          ></gem-input>
          <gem-input [placeholder]="'endpoint.icon'" [control]="entityForm.get('icon')"></gem-input>
          <gem-input [placeholder]="'endpoint.link'" [control]="entityForm.get('link')"></gem-input>
          <gem-checkbox
            [label]="'endpoint.enabledInMyServices'"
            [control]="entityForm.get('enabledInMyServices')"
          ></gem-checkbox>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="createEditEntity()">
      {{ (entityForm.get("id").value ? "save" : "ADD") | translate }}
    </button>
  </mat-card-footer>
</mat-card>
