import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromQuickActions from "../reducers/quick-action.reducer";

export const getQuickActionsState = createFeatureSelector<fromQuickActions.QuickActionState>(
  fromQuickActions.FEATURE_KEY,
);

export const getQuickActionsEntities = createSelector(
  getQuickActionsState,
  fromQuickActions.getQuickActionsEntities,
);

export const getQuickActions = createSelector(getQuickActionsEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
