import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularEditorConfig } from "@kolkov/angular-editor";

export interface GemHtmlEditDialog {
  htmlContent: string;
  confirmAction: any;
}

@Component({
  selector: "gem-html-edit-dialog",
  templateUrl: "./gem-html-edit-dialog.component.html",
  styleUrls: ["./gem-html-edit-dialog.component.scss"],
})
export class GemHtmlEditDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: "auto",
    minHeight: "800",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["bold", "italic", "justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent", "fontName", "insertVideo"],
      ["fontSize", "textColor", "backgroundColor", "customClasses"],
    ],
  };
  constructor(public dialogRef: MatDialogRef<GemHtmlEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GemHtmlEditDialog) {}

  ngOnInit(): void {}

  confirmAction(): void {
    this.data.confirmAction(this.data.htmlContent);
    this.dialogRef.close();
  }
  cancelAction(): void {
    this.dialogRef.close();
  }
}
