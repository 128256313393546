import * as fromMyRight from "../actions/my-rights.actions";
import { Right } from "../../models/right.model";

export interface MyRightState {
  myApplications: { [id: string]: Right };
  myBookmarks: { [id: string]: Right };
  loading: boolean;
  loaded: boolean;
}

export const initialState: MyRightState = {
  myApplications: {},
  myBookmarks: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromMyRight.MyRightsActions): MyRightState {
  switch (action.type) {
    case fromMyRight.UPDATE_MY_RIGHT_BOOKMARKS:
    case fromMyRight.UPDATE_MY_RIGHT_APPLICATIONS:
    case fromMyRight.LOAD_MY_RIGHTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromMyRight.LOAD_MY_RIGHTS_SUCCESS: {
      const myApplications = action.payload.myApplications;
      const myBookmarks = action.payload.myBookmarks;
      const myApplicationEntities = myApplications.reduce(
        (entities: { [id: string]: Right }, right: Right) => {
          return {
            ...entities,
            [right.id]: right,
          };
        },
        {
          ...state.myApplications,
        },
      );
      const myBookmarksEntities = myBookmarks.reduce(
        (entities: { [id: string]: Right }, right: Right) => {
          return {
            ...entities,
            [right.id]: right,
          };
        },
        {
          ...state.myBookmarks,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        myApplications: myApplicationEntities,
        myBookmarks: myBookmarksEntities,
      };
    }
    case fromMyRight.UPDATE_MY_RIGHT_APPLICATIONS_SUCCESS: {
      const myApplications = action.payload;
      const myApplicationsEntities = myApplications.reduce(
        (entities: { [id: string]: Right }, right: Right) => {
          return {
            ...entities,
            [right.id]: right,
          };
        },
        {
          ...state.myApplications,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        myApplications: myApplicationsEntities,
      };
    }
    case fromMyRight.UPDATE_MY_RIGHT_BOOKMARKS_SUCCESS: {
      const myBookmarks = action.payload;
      const myBookmarksEntities = myBookmarks.reduce(
        (entities: { [id: string]: Right }, right: Right) => {
          return {
            ...entities,
            [right.id]: right,
          };
        },
        {
          ...state.myBookmarks,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        myBookmarks: myBookmarksEntities,
      };
    }
    case fromMyRight.UPDATE_MY_RIGHT_BOOKMARKS_FAIL:
    case fromMyRight.UPDATE_MY_RIGHT_APPLICATIONS_FAIL:
    case fromMyRight.LOAD_MY_RIGHTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getMyApplicationsEntities = (state: MyRightState) => state.myApplications;
export const getMyBookmarksEntities = (state: MyRightState) => state.myBookmarks;
export const getMyRightsLoading = (state: MyRightState) => state.loading;
export const getMyRightsLoaded = (state: MyRightState) => state.loaded;
