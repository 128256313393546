import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccessDeniedComponent } from "./pages/access-denied/access-denied.component";
import { EndpointConfigPageComponent } from "./pages/endpoint-config-page/endpoint-config-page.component";
import { HelpConfigPageComponent } from "./pages/help-config-page/help-config-page.component";
import { IncidentsPageComponent } from "./pages/incidents-page/incidents-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { OauthRedirectComponent } from "./pages/oauth-redirect/oauth-redirect.component";
import { ProcessesPageComponent } from "./pages/processes-page/processes-page.component";
import { EndpointResolver } from "./pages/resolvers/endpoint.resolver";
import { TasksPageComponent } from "./pages/tasks-page/tasks-page.component";
import { TemplatesPageComponent } from "./pages/templates-page/templates-page.component";
import { LegalNoticeComponent } from "./pages/legal-notice/legal-notice.component";

// guards
import * as fromSharedGuards from "gematik-shared";

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "home",
    loadChildren: () => import("./features/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./features/dashboard/dashboard.module").then((m) => m.DashboardModule),
    data: { breadcrumb: "Dashboard" },
  },
  {
    path: "profile",
    loadChildren: () => import("./features/profile/profile.module").then((m) => m.ProfileModule),
    data: { breadcrumb: "Profile" },
  },

  { path: "oauth", component: OauthRedirectComponent },
  {
    path: "tasks",
    redirectTo: "tasks/my",
    pathMatch: "full",
  },
  {
    path: "tasks/:taskTarget",
    component: TasksPageComponent,
    data: { component: "Tasks" },
    canActivate: [fromSharedGuards.AuthGuard, fromSharedGuards.AuthzBasicGuard],
  },
  {
    path: "processes",
    redirectTo: "processes/my",
    pathMatch: "full",
  },
  {
    path: "processes/:processTarget",
    component: ProcessesPageComponent,
    data: { component: "Processes" },
    canActivate: [fromSharedGuards.AuthGuard, fromSharedGuards.AuthzBasicGuard],
  },
  {
    path: "incidents",
    component: IncidentsPageComponent,
    canActivate: [
      fromSharedGuards.AuthGuard,
      fromSharedGuards.AuthzBasicGuard,
      fromSharedGuards.AuthzAdminGuard,
    ],
  },
  {
    path: "endpoints-config",
    component: EndpointConfigPageComponent,
    data: { component: "Endpoints" },
    canActivate: [
      fromSharedGuards.AuthGuard,
      fromSharedGuards.AuthzBasicGuard,
      fromSharedGuards.AuthzAdminGuard,
    ],
  },
  {
    path: "templates",
    component: TemplatesPageComponent,
    data: { component: "Templates" },
    canActivate: [
      fromSharedGuards.AuthGuard,
      fromSharedGuards.AuthzBasicGuard,
      fromSharedGuards.AuthzAdminGuard,
    ],
  },
  {
    path: "alerts",
    loadChildren: () => import("./features/alerts/alert.module").then((m) => m.AlertModule),
  },
  {
    path: "help-config",
    component: HelpConfigPageComponent,
    data: { component: "Help Config" },
    canActivate: [
      fromSharedGuards.AuthGuard,
      fromSharedGuards.AuthzBasicGuard,
      fromSharedGuards.AuthzAdminGuard,
    ],
  },
  {
    path: "redis-cache",
    loadChildren: () =>
      import("./features/redis-cache/redis-cache.module").then((m) => m.RedisCacheModule),
  },
  {
    path: "legal-notice",
    component: LegalNoticeComponent,
  },
  { path: "noaccess", component: AccessDeniedComponent },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [EndpointResolver],
})
export class AppRoutingModule {}
