import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SuiteCrmPartnerAddressMail } from "gematik-shared";
import { Subscription } from "rxjs";

import { MatDialog } from "@angular/material/dialog";
import { CommunicationAddressEmailComponent } from "../communication-address-email/communication-address-email.component";

@Component({
  selector: "communication-address-emails",
  styleUrls: ["communication-address-emails.component.scss"],
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>
            <div class="d-flex">
              <mat-icon class="nav-icons me-1" svgIcon="gem-report"></mat-icon>
              <strong> Emails </strong>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="mt-2"> </mat-card-subtitle>
          <button data-testid="add" mat-mini-fab class="fab-button" (click)="onAdd()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content class="mt-4">
        <div class="col-sm-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Description</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              @for (email of emails; track email.email; let i = $index) {
              <tr>
                <td>
                  {{ email.email }}
                </td>
                <td>{{ email.description }}</td>
                <td>
                  <span
                    data-testid="edit"
                    class="material-icons"
                    style="cursor: pointer;"
                    (click)="onEdit(email, i)"
                  >
                    edit
                  </span>
                </td>
                <td>
                  <span
                    data-testid="delete"
                    class="material-icons"
                    style="cursor: pointer;"
                    (click)="onDelete(email)"
                  >
                    delete
                  </span>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class CommunicationAddressEmailsComponent implements OnInit, OnDestroy {
  @Input() emails: SuiteCrmPartnerAddressMail[] = [];
  @Output() add: EventEmitter<SuiteCrmPartnerAddressMail> = new EventEmitter();
  @Output() update: EventEmitter<{ obj: SuiteCrmPartnerAddressMail; index: number }> =
    new EventEmitter();
  @Output() delete: EventEmitter<SuiteCrmPartnerAddressMail> = new EventEmitter();

  addressId: string;
  orgId: string;

  subscriptions: Subscription[] = [];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  onAdd(): void {
    const dialogRef = this.dialog.open(CommunicationAddressEmailComponent);
    const sub = dialogRef.afterClosed().subscribe((result) => {
      this.add.emit(result.email);
    });
    this.subscriptions.push(sub);
  }

  onEdit(email: SuiteCrmPartnerAddressMail, index: number): void {
    const dialogRef = this.dialog.open(CommunicationAddressEmailComponent, {
      data: {
        email,
      },
    });
    const sub = dialogRef.afterClosed().subscribe((result) => {
      if (result && result.email) {
        this.update.emit({ obj: result.email, index });
      }
    });
    this.subscriptions.push(sub);
  }

  onDelete(email: SuiteCrmPartnerAddressMail): void {
    this.delete.emit(email);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
