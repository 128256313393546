<div class="mat-table-responsive-container w-100">
  <table
    mat-table
    matSort
    multiTemplateDataRows
    [dataSource]="dataSource"
    [ngStyle]="{
      'background-color': handsetBreakpointMatched ? 'inherit' : 'var(--mat-table-background-color)'
    }"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "name" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "url" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.url }}</td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "roles" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.roles }}</td>
    </ng-container>

    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "endpoint.link" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.link }}</td>
    </ng-container>

    <ng-container matColumnDef="labelEn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "endpoint.labelEn" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.labelEn }}</td>
    </ng-container>

    <ng-container matColumnDef="labelDe">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "endpoint.labelDe" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.labelDe }}</td>
    </ng-container>

    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "endpoint.icon" | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span class="material-icons">
          {{ row.icon }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="enabledInMyServices">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "endpoint.enabledInMyServices" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.enabledInMyServices }}</td>
    </ng-container>

    <!-- <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "status" | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <i
          *ngIf="row.webSocketIsActive"
          class="material-icons"
          style="color: green"
          >check</i
        >
        <i
          *ngIf="!row.webSocketIsActive"
          class="material-icons"
          style="color: red; cursor: pointer;"
          [ngbTooltip]="'RESTART' | translate"
          (click)="restartWebSocket(row)"
          >close</i
        >
      </td>
    </ng-container> -->

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
        <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectEntity(row)"
          >edit</i
        >
        <i
          class="material-icons"
          [ngbTooltip]="'DELETE' | translate"
          (click)="openDeleteEntityConfirmation(row)"
          >delete</i
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
</div>
