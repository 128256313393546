import { Action } from "@ngrx/store";
import { MyService } from "../../models/my-service.model";

// Load My Services
export const LOAD_MY_SERVICES = "[My Services] Load My Services";
export const LOAD_MY_SERVICES_SUCCESS = "[My Services] Load My Services Success";
export const LOAD_MY_SERVICES_FAIL = "[My Services] Load My Services Fail";

export class LoadMyServices implements Action {
  readonly type = LOAD_MY_SERVICES;
}

export class LoadMyServicesSuccess implements Action {
  readonly type = LOAD_MY_SERVICES_SUCCESS;
  constructor(public payload: MyService[]) {}
}

export class LoadMyServicesFail implements Action {
  readonly type = LOAD_MY_SERVICES_FAIL;
  constructor(public payload: any) {}
}

// Update My Services
export const UPDATE_MY_SERVICES = "[My Services] Update My Services";
export const UPDATE_MY_SERVICES_SUCCESS = "[My Services] Update My Services Success";
export const UPDATE_MY_SERVICES_FAIL = "[My Services] Update My Services Fail";

export class UpdateMyServices implements Action {
  readonly type = UPDATE_MY_SERVICES;
  constructor(public payload: { leftList: MyService[]; rightList: MyService[] }) {}
}

export class UpdateMyServicesSuccess implements Action {
  readonly type = UPDATE_MY_SERVICES_SUCCESS;
  constructor(public payload: MyService[]) {}
}

export class UpdateMyServicesFail implements Action {
  readonly type = UPDATE_MY_SERVICES_FAIL;
  constructor(public payload: any) {}
}

export type MyServicesActions =
  | LoadMyServices
  | LoadMyServicesSuccess
  | LoadMyServicesFail
  | UpdateMyServices
  | UpdateMyServicesSuccess
  | UpdateMyServicesFail;
