<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ "endpointConfig" | translate }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="{{ selectedEntity ? 'col-md-12 col-lg-8' : 'col-md-12 col-lg-12' }}">
    <div class="row">
      <div class="col-sm-12">
        <a class="btn btn-primary add-entry-btn" (click)="addEntityClicked()">+</a>
      </div>
      <div class="col-sm-12">
        <endpoint-table [entityList]="entityList" (entitySelected)="selectEntity($event)" (deleteEntityClicked)="deleteEntity($event)" (restartWebSocketEvent)="restartWebSocket($event)"> </endpoint-table>
      </div>
    </div>
  </div>
  <div *ngIf="selectedEntity" class="col-md-12 col-lg-4">
    <div class="selected-entity-wrapper-not">
      <div class="sticky-top" style="top: 50px">
        <endpoint-form [entity]="selectedEntity" (entityCreatedEdited)="createEditEntity($event)"> </endpoint-form>
      </div>
    </div>
  </div>
</div>
