import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";

// external libraries
import { TranslateModule } from "@ngx-translate/core";

// material
import { MaterialModule } from "gematik-form-library";
import { GemComponentsModule } from "gematik-form-library";

// components
import * as fromComponents from "./components";

// containers
import * as fromContainers from "./containers";

export const ROUTES: Routes = [];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    ReactiveFormsModule,
    MaterialModule,
    GemComponentsModule,
    TranslateModule,
  ],
  declarations: [...fromComponents.components, ...fromContainers.containers],
  providers: [],
  exports: [...fromComponents.components, ...fromContainers.containers],
})
export class SharedModule {}
