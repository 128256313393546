import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromStore from "../store";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";

@Injectable()
export class PartnerManagerGuard {
  constructor(private store: Store<fromStore.UwlState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkStore().pipe(
      switchMap(() => {
        return this.isPartnerManager().pipe(
          tap((access) => {
            if (!access) {
              this.router.navigate(["noaccess"]);
            }
          }),
        );
      }),
    );
  }

  isPartnerManager(): Observable<boolean> {
    return this.store.select(fromStore.getProfile).pipe(
      map((profile) => profile.isPartnerManager),
      take(1),
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getProfileLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(new fromStore.LoadProfile());
        }
      }),
      filter((loaded) => loaded),
      take(1),
    );
  }
}
