import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SuiteCrmPartnerAddressMail } from "gematik-shared";
import { Subscription } from "rxjs";

export interface CommunicationAddressEmailData {
  email: SuiteCrmPartnerAddressMail;
}

@Component({
  selector: "communication-address-email",
  styleUrls: ["communication-address-email.component.scss"],
  template: `
    <communication-address-email-form
      data-testid="email-form"
      [addressEmail]="email"
      (save)="onSave($event)"
      (cancel)="onCancel()"
    ></communication-address-email-form>
  `,
})
export class CommunicationAddressEmailComponent implements OnInit, OnDestroy {
  orgId: string;
  addressId: string;

  email: SuiteCrmPartnerAddressMail;

  subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<CommunicationAddressEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommunicationAddressEmailData,
  ) {}

  ngOnInit(): void {
    this.email = this.data?.email;
  }

  onSave(email: SuiteCrmPartnerAddressMail): void {
    this.dialogRef.close({ email: email });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
