import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GemHtmlDisplayDialogComponent } from "../../pages/components/gem-html-display-dialog/gem-html-display-dialog.component";
import { UwlService } from "../../services";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "sidenav-tree",
  templateUrl: "./sidenav-tree.component.html",
  styleUrls: ["./sidenav-tree.component.scss"],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(0)" })),
      state("rotated", style({ transform: "rotate(90deg)" })),
      transition("rotated => default", animate("300ms ease-out")),
      transition("default => rotated", animate("300ms ease-in")),
    ]),
    trigger("openClose", [
      state("true", style({ height: "*", opacity: 1 })),
      state("false", style({ height: "0px", opacity: 0 })),
      transition("false <=> true", animate(300)),
    ]),
  ],
})
export class SidenavTreeComponent implements OnInit {
  @Input() treeContent: {
    rootLabel: string;
    icon: string;
    links: [{ label: string; route: string; message: string }];
    treeElementOpened: boolean;
  };

  treeElementOpened: boolean;
  state = "rotated";

  constructor(
    private dialog: MatDialog,
    private uwlService: UwlService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.treeElementOpened = this.treeContent.treeElementOpened;
  }

  toggleTreeElement() {
    this.treeElementOpened = !this.treeElementOpened;
    if (this.treeElementOpened) {
      this.state = "rotated";
    } else {
      this.state = "default";
    }
  }

  openHelpDialog(id: string) {
    this.uwlService.findHelpConfig(id, this.translate.currentLang).subscribe(
      (res) => {
        const html = res.body.html;
        const title = res.body.title;
        this.dialog.open(GemHtmlDisplayDialogComponent, {
          data: { id, html, title },
          autoFocus: false,
        });
      },
      (err) => {
        const title = this.translate.instant("help_error_loading_title");
        const html = this.translate.instant("help_error_loading_content", {
          helpkey: id,
          lang: this.translate.currentLang,
        });
        this.dialog.open(GemHtmlDisplayDialogComponent, {
          data: { id, html, title },
        });
      },
    );
  }
}
