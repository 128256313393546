<ul>
  <li>
    <!-- <a (click)="toggleTreeElement()">
      <mat-icon class="material-icons nav-icons" svgIcon="{{ treeContent.icon }}"></mat-icon>
      <span class="nav-main-item-label">{{ treeContent.rootLabel | translate }}</span>
    </a> -->

    <div class="d-flex align-items-center">
      <mat-icon class="nav-icons" svgIcon="{{ treeContent.icon }}"></mat-icon>
      <a (click)="toggleTreeElement()">
        <span class="nav-main-item-label">{{ treeContent.rootLabel | translate }}</span>
      </a>
    </div>

    <ul [@openClose]="treeElementOpened ? true : false" class="open-close-container">
      <li *ngFor="let link of treeContent.links" class="nav-line-item">
        <a *ngIf="link.route" routerLink="{{ link.route }}">
          <div
            [@openClose]="treeElementOpened ? true : false"
            class="message-marker"
            *ngIf="link.message && link.message != 0"
          >
            {{ link.message }}
          </div>
          <span
            [matBadge]="link.badge | async"
            matBadgePosition="before"
            matBadgeOverlap="false"
            style="display: inline-block"
            class="nav-sub-item-label"
            [class.has-tasks]="(link.badge | async) > 0"
            >{{ link.label | translate }}</span
          >
        </a>
        <a *ngIf="link.external" (click)="openHelpDialog(link.help)">
          {{ link.message }}
          <span class="nav-sub-item-label">{{ link.label | translate }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
