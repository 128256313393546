import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromGroup from "../reducers/groups.reducer";
import { SECURITY_GROUP_ADMIN, SECURITY_GROUP_BASIC } from "../../services";

export const getGroupsState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.groups,
);

export const getGroupsEntities = createSelector(getGroupsState, fromGroup.getGroupsEntities);

export const getGroups = createSelector(getGroupsEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getGroup = (name: string) =>
  createSelector(getGroupsEntities, (entities) => {
    return entities[name];
  });

export const isUserAuthorized = createSelector(getGroupsEntities, (entities) => {
  return !!entities[SECURITY_GROUP_BASIC];
});

export const isUserAdmin = createSelector(getGroupsEntities, (entities) => {
  return !!entities[SECURITY_GROUP_ADMIN];
});

export const getGroupsLoaded = createSelector(getGroupsState, fromGroup.getGroupsLoaded);

export const getGroupsLoading = createSelector(getGroupsState, fromGroup.getGroupsLoading);
