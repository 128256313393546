import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as alertActions from "../actions/alert.actions";
import * as fromServices from "../../services";

@Injectable()
export class AlertEffects {
  constructor(private actions$: Actions, private alertService: fromServices.AlertService) {}

  loadAlerts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(alertActions.LOAD_ALERTS),
      switchMap((alerts) => {
        return this.alertService.getAll().pipe(
          map((alerts) => new alertActions.LoadAlertsSuccess(alerts)),
          catchError((error) => of(new alertActions.LoadAlertsFail(error))),
        );
      }),
    );
  });

  createAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(alertActions.CREATE_ALERT),
      map((action: alertActions.CreateAlert) => action.payload),
      switchMap((alert) => {
        return this.alertService.create(alert).pipe(
          map((alert) => new alertActions.CreateAlertSuccess(alert)),
          catchError((error) => of(new alertActions.CreateAlertFail(error))),
        );
      }),
    );
  });

  updateAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(alertActions.UPDATE_ALERT),
      map((action: alertActions.UpdateAlert) => action.payload),
      switchMap((alert) => {
        return this.alertService.update(alert).pipe(
          map((alert) => new alertActions.UpdateAlertSuccess(alert)),
          catchError((error) => of(new alertActions.UpdateAlertFail(error))),
        );
      }),
    );
  });

  deleteAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(alertActions.DELETE_ALERT),
      map((action: alertActions.DeleteAlert) => action.payload),
      switchMap((alert) => {
        return this.alertService.delete(alert).pipe(
          map(() => new alertActions.DeleteAlertSuccess(alert)),
          catchError((error) => of(new alertActions.DeleteAlertFail(error))),
        );
      }),
    );
  });
}
