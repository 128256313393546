import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromEndpoints from "../reducers/endpoint.reducer";

export const getEndpointsState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.endpoints,
);

export const getEndpointEntities = createSelector(
  getEndpointsState,
  fromEndpoints.getEndpointEntities,
);

export const getEndpointCount = createSelector(
  getEndpointEntities,
  (entities) => Object.keys(entities).length,
);

export const getEndpoints = createSelector(getEndpointEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
