import { AuthorizationGuard } from "./authorization-guard";
import { AdminGuard } from "./admin-guard";
import { PartnerManagerGuard } from "./partner-manager-guard";
import { AuthGuard } from "./auth-guard";

export const guards: any[] = [AuthorizationGuard, AdminGuard, PartnerManagerGuard, AuthGuard];

export * from "./authorization-guard";
export * from "./admin-guard";
export * from "./partner-manager-guard";
export * from "./auth-guard";
