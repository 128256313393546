import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GemDialogComponent } from "gematik-form-library";
import { GematikProcessDiagram, GematikTaskApiService } from "gematik-task-api";
import { GematikIncident } from "../../../models/gematik-incident.model";
import { GemBpmnIncidentDialogComponent } from "../gem-bpmn-incident-dialog/gem-bpmn-incident-dialog.component";

import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { GematikProcessDiagramActivityDto } from "gematik-task-api";

@Component({
  selector: "incidents-table",
  templateUrl: "./incidents-table.component.html",
  styleUrls: ["./incidents-table.component.scss"],
})
export class IncidentsTableComponent implements OnInit {
  incidents: GematikIncident[];
  displayedColumns: string[];
  filterText: string = "";
  dataSource: MatTableDataSource<GematikIncident>;

  @Input() set incidentsList(incidents: GematikIncident[]) {
    this.incidents = incidents;
    this.dataSource = new MatTableDataSource(incidents);
    // this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private taskService: GematikTaskApiService,
    private store: Store<fromStore.UwlState>,
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [
      "incidentMessage",
      "processInstanceId",
      "incidentTimestamp",
      "activityId",
      "failedActivityId",
      "incidentType",
      "annotation",
      "diagram",
      "stacktrace",
      "retry",
    ];
  }

  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      // if (this.dataSource.paginator.hasPreviousPage()) {
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  onMatSortChange($event: any) {
    const sortedIncidents = Object.create(this.incidents);
    if ($event.direction === "desc") {
      sortedIncidents.reverse();
    }
    this.dataSource = new MatTableDataSource(sortedIncidents);
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  customFilterPredicate(row: any, filter: any): boolean {
    let buidFilter = row.buid.toLowerCase().includes(filter);
    let infoOneFilter = row.customProcessContentOne.toLowerCase().includes(filter);
    let infoTwoFilter = row.customProcessContentTwo.toLowerCase().includes(filter);
    let infoThreeFilter = row.customProcessContentThree.toLowerCase().includes(filter);
    return buidFilter || infoOneFilter || infoTwoFilter || infoThreeFilter;
  }

  getDiagram(incident: GematikIncident) {
    this.taskService
      .getProcessDiagram(incident.endpoint, incident.processInstanceId)
      .subscribe((res) => {
        let diagram: GematikProcessDiagram = res.body;
        diagram.currentActivityList.splice(0);

        let activity = new GematikProcessDiagramActivityDto();
        activity.currentActivity = incident.activityId;
        activity.incident = true;

        diagram.currentActivityList.push(activity);
        this.openConfirmationDialog(incident, diagram);
      });
  }

  getStacktrace(incident: GematikIncident): void {
    this.taskService.getIncidentStacktrace(incident.endpoint, incident.id).subscribe((res) => {
      this.openStacktraceDialog(res);
    });
  }

  retryIncident(incident: GematikIncident): void {
    this.store.dispatch(new fromStore.RetryFailedIncident(incident));
  }

  openConfirmationDialog(dto: GematikIncident, diagram: GematikProcessDiagram): void {
    this.dialog.open(GemBpmnIncidentDialogComponent, {
      data: {
        dto,
        diagram,
      },
      width: "90%",
      panelClass: "bpmn-dialog-style",
    });
  }

  openStacktraceDialog(stacktrace: string): void {
    this.dialog.open(GemDialogComponent, {
      data: {
        title: "Stacktrace",
        text: stacktrace,
      },
    });
  }
}
