import * as fromProcesses from "../actions/process.actions";
import { GematikProcessTotals } from "../../models/gematik-process-totals.model";

export interface ProcessTotalsState {
  totals: GematikProcessTotals;
  loading: boolean;
  loaded: boolean;
}

export const initialState: ProcessTotalsState = {
  totals: {
    open: {
      my: 0,
      group: 0,
    },
    due: {
      my: 0,
      group: 0,
    },
    completed: {
      my: 0,
      group: 0,
    },
  },
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromProcesses.ProcessActions,
): ProcessTotalsState {
  switch (action.type) {
    case fromProcesses.LOAD_PROCESS_TOTALS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromProcesses.LOAD_PROCESS_TOTALS_SUCCESS: {
      const totals = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        totals,
      };
    }
    case fromProcesses.LOAD_PROCESS_TOTALS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getProcessTotals = (state: ProcessTotalsState) => state.totals;
export const getProcessTotalsLoading = (state: ProcessTotalsState) => state.loading;
export const getProcessTotalsLoaded = (state: ProcessTotalsState) => state.loaded;
