import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromTaskTotals from "../reducers/task-totals.reducer";

export const getTaskTotalsState = createFeatureSelector<fromTaskTotals.TaskTotalsState>(
  fromTaskTotals.FEATURE_KEY,
);

export const getTaskTotals = createSelector(getTaskTotalsState, fromTaskTotals.getTaskTotals);

export const getMyTaskTotals = createSelector(getTaskTotals, (totals) => {
  return {
    open: totals.open.my,
    due: totals.due.my,
    completed: totals.completed.my,
  };
});

export const getGroupTaskTotals = createSelector(getTaskTotals, (totals) => {
  return {
    open: totals.open.group,
    due: totals.due.group,
    completed: totals.completed.group,
  };
});
