import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { TokenHelperService } from "gematik-form-library";
import { GematikTaskDto } from "projects/gematik-task-api/src/public-api";
export interface DialogData {
  task: GematikTaskDto;
  control: UntypedFormControl;
  approveAction: any;
  rejectAction: any;
  cancelAction: any;
}
@Component({
  selector: "gem-default-task-dialog",
  templateUrl: "./gem-default-task-dialog.component.html",
  styleUrls: ["./gem-default-task-dialog.component.scss"],
})
export class GemDefaultTaskDialogComponent implements OnInit, AfterViewInit {
  title: string;
  text: string;
  fullName: string;
  @ViewChild("scroll", { static: true, read: ElementRef }) myScrollContainer: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<GemDefaultTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService,
    private tokenHelper: TokenHelperService,
  ) {
    this.control = data.control;
  }
  control: UntypedFormControl;
  ngOnInit(): void {
    this.title = this.translateService.instant(this.data.task.name);
    this.text = this.translateService.instant(this.data.task.description);
    this.fullName = this.tokenHelper.getFullname();

    if (this.control !== null) {
      this.control.setValidators([Validators.required]);
      if (this.translateService.instant(`${this.data.task.taskDefinitionKey}_input_label`)) {
        this.data.task = {
          ...this.data.task,
          inputFieldName: `${this.data.task.taskDefinitionKey}_input_label`,
        };
      } else {
        this.data.task = {
          ...this.data.task,
          inputFieldName: "SimpleTaskInput",
        };
      }
    }
  }

  cancelAction(): void {
    this.data.cancelAction();
    this.dialogRef.close();
  }

  approveAction(): void {
    if (this.control) {
      this.data.approveAction(this.control);
    } else {
      this.data.approveAction();
    }
    this.dialogRef.close();
  }

  rejectAction(): void {
    if (this.control) {
      this.data.rejectAction(this.control);
    } else {
      this.data.rejectAction();
    }
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  hasFieldData() {
    if (this.control) {
      if (this.control.value) {
        return false;
      }
      return true;
    }
    return false;
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
