import * as fromIncidents from "../actions/incident.actions";
import { GematikIncident } from "../../models/gematik-incident.model";

export const FEATURE_KEY = "incidents";

export interface IncidentState {
  entities: { [id: string]: GematikIncident };
  loading: boolean;
  loaded: boolean;
}

export const initialState: IncidentState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromIncidents.IncidentActions,
): IncidentState {
  switch (action.type) {
    case fromIncidents.LOAD_INCIDENTS:
    case fromIncidents.LOAD_ALL_INCIDENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromIncidents.LOAD_INCIDENTS_SUCCESS:
    case fromIncidents.LOAD_ALL_INCIDENTS_SUCCESS: {
      const incidents = action.payload;
      const entities = incidents.reduce(
        (entities: { [id: number]: GematikIncident }, incident: GematikIncident) => {
          return {
            ...entities,
            [incident.id]: incident,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
    case fromIncidents.RETRY_FAILED_INCIDENT: {
      const incident = action.payload;
      const { [incident.id]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
      };
    }
    case fromIncidents.RETRY_FAILED_INCIDENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case fromIncidents.RETRY_FAILED_INCIDENT_FAIL:
    case fromIncidents.LOAD_INCIDENTS_FAIL:
    case fromIncidents.LOAD_ALL_INCIDENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getIncidentEntities = (state: IncidentState) => state.entities;
