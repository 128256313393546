import { Action } from "@ngrx/store";
import { Alert } from "gematik-shared";

// Load Alerts
export const LOAD_ALERTS = "[Alerts] Load Alerts";
export const LOAD_ALERTS_SUCCESS = "[Alerts] Load Alerts Success";
export const LOAD_ALERTS_FAIL = "[Alerts] Load Alerts Fail";

export class LoadAlerts implements Action {
  readonly type = LOAD_ALERTS;
}

export class LoadAlertsSuccess implements Action {
  readonly type = LOAD_ALERTS_SUCCESS;
  constructor(public payload: Alert[]) {}
}

export class LoadAlertsFail implements Action {
  readonly type = LOAD_ALERTS_FAIL;
  constructor(public payload: any) {}
}

// Create Alert
export const CREATE_ALERT = "[Alerts] Create Alert";
export const CREATE_ALERT_SUCCESS = "[Alerts] Create Alert Success";
export const CREATE_ALERT_FAIL = "[Alerts] Create Alert Fail";

export class CreateAlert implements Action {
  readonly type = CREATE_ALERT;
  constructor(public payload: Alert) {}
}

export class CreateAlertSuccess implements Action {
  readonly type = CREATE_ALERT_SUCCESS;
  constructor(public payload: Alert) {}
}

export class CreateAlertFail implements Action {
  readonly type = CREATE_ALERT_FAIL;
  constructor(public payload: any) {}
}

// Update Alert
export const UPDATE_ALERT = "[Alerts] Update Alert";
export const UPDATE_ALERT_SUCCESS = "[Alerts] Update Alert Success";
export const UPDATE_ALERT_FAIL = "[Alerts] Update Alert Fail";

export class UpdateAlert implements Action {
  readonly type = UPDATE_ALERT;
  constructor(public payload: Alert) {}
}

export class UpdateAlertSuccess implements Action {
  readonly type = UPDATE_ALERT_SUCCESS;
  constructor(public payload: Alert) {}
}

export class UpdateAlertFail implements Action {
  readonly type = UPDATE_ALERT_FAIL;
  constructor(public payload: any) {}
}

// Delete Alert
export const DELETE_ALERT = "[Alerts] Delete Alert";
export const DELETE_ALERT_SUCCESS = "[Alerts] Delete Alert Success";
export const DELETE_ALERT_FAIL = "[Alerts] Delete Alert Fail";

export class DeleteAlert implements Action {
  readonly type = DELETE_ALERT;
  constructor(public payload: Alert) {}
}

export class DeleteAlertSuccess implements Action {
  readonly type = DELETE_ALERT_SUCCESS;
  constructor(public payload: Alert) {}
}

export class DeleteAlertFail implements Action {
  readonly type = DELETE_ALERT_FAIL;
  constructor(public payload: any) {}
}

export type AlertActions =
  | LoadAlerts
  | LoadAlertsSuccess
  | LoadAlertsFail
  | CreateAlert
  | CreateAlertSuccess
  | CreateAlertFail
  | UpdateAlert
  | UpdateAlertSuccess
  | UpdateAlertFail
  | DeleteAlert
  | DeleteAlertSuccess
  | DeleteAlertFail;
