import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromIncidents from "../reducers/incident.reducer";

export const getIncidentsState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.incidents,
);

export const getIncidentEntities = createSelector(
  getIncidentsState,
  fromIncidents.getIncidentEntities,
);

export const getIncidentCount = createSelector(
  getIncidentEntities,
  (entities) => Object.keys(entities).length,
);

export const getIncidents = createSelector(getIncidentEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
