import * as fromSecurity from "../actions/security.actions";
import { Group } from "../../services/uwl/security.service";

export interface GroupsState {
  entities: { [id: string]: Group };
  loading: boolean;
  loaded: boolean;
}

export const initialState: GroupsState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromSecurity.SecurityActions): GroupsState {
  switch (action.type) {
    case fromSecurity.LOAD_GROUPS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromSecurity.LOAD_GROUPS_SUCCESS: {
      const groups = action.payload;
      const entities = groups.reduce(
        (entities: { [id: string]: Group }, group: Group) => {
          return {
            ...entities,
            [group.name]: group,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
    case fromSecurity.LOAD_GROUPS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getGroupsEntities = (state: GroupsState) => state.entities;
export const getGroupsLoading = (state: GroupsState) => state.loading;
export const getGroupsLoaded = (state: GroupsState) => state.loaded;
