import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as securityActions from "../actions/security.actions";
import * as fromServices from "../../services";

@Injectable()
export class SecurityEffects {
  constructor(private actions$: Actions, private securityService: fromServices.SecurityService) {}

  loadGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.LOAD_GROUPS),
      switchMap(() => {
        return this.securityService.getGroupNamesByUserId().pipe(
          map((response) => new securityActions.LoadGroupsSuccess(response)),
          catchError((error) => of(new securityActions.LoadGroupsFail(error))),
        );
      }),
    );
  });
}
