import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromMyService from "../reducers/my-services.reducer";

export const getMyServicesState = createFeatureSelector<fromMyService.MyServicesState>(
  fromMyService.FEATURE_KEY,
);

// My Services
export const getMyServicesEntities = createSelector(
  getMyServicesState,
  fromMyService.getMyServicesEntities,
);

export const getMyEnabledServices = createSelector(getMyServicesEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .filter((myService) => !myService.disabled)
    .sort((a, b) => a.index - b.index);
});

export const getMyServices = createSelector(getMyServicesEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .sort((a, b) => a.index - b.index);
});
