import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { Right } from "../../models/right.model";

@Injectable({
  providedIn: "root",
})
export class RightsService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getMyRights(): Observable<{
    myApplications: Right[];
    myBookmarks: Right[];
  }> {
    return this.http.get<{
      myApplications: Right[];
      myBookmarks: Right[];
    }>(this.env.backendUrl + "/rights/my");
  }

  updateMyRights(payload, type: string): Observable<Right[]> {
    return this.http.post<Right[]>(`${this.env.backendUrl}/rights/my/${type}`, payload);
  }
}
