import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromTasks from "../reducers/task.reducer";

// Open Tasks
export const getOpenTasksState = createFeatureSelector<fromTasks.TaskState>(
  fromTasks.OPEN_FEATURE_KEY,
);

export const getOpenGroupTaskEntities = createSelector(
  getOpenTasksState,
  fromTasks.getGroupTaskEntities,
);

export const getOpenMyTaskEntities = createSelector(getOpenTasksState, fromTasks.getMyTaskEntities);

export const getOpenGroupTaskCount = createSelector(
  getOpenGroupTaskEntities,
  (entities) => Object.keys(entities).length,
);

export const getOpenMyTaskCount = createSelector(
  getOpenMyTaskEntities,
  (entities) => Object.keys(entities).length,
);

export const getOpenGroupTasks = createSelector(getOpenGroupTaskEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getOpenMyTasks = createSelector(getOpenMyTaskEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

// Completed Tasks
export const getCompletedTasksState = createFeatureSelector<fromTasks.TaskState>(
  fromTasks.COMPLETED_FEATURE_KEY,
);

export const getMyCompletedTaskEntities = createSelector(
  getCompletedTasksState,
  fromTasks.getMyTaskEntities,
);

export const getMyCompletedTasks = createSelector(getMyCompletedTaskEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getGroupCompletedTaskEntities = createSelector(
  getCompletedTasksState,
  fromTasks.getGroupTaskEntities,
);

export const getGroupCompletedTasks = createSelector(getGroupCompletedTaskEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
