import { EndpointEffects } from "./endpoint.effects";
import { TaskEffects } from "./task.effects";
import { ProcessEffects } from "./process.effects";
import { IncidentEffects } from "./incident.effects";
import { TemplateEffects } from "./template.effects";
import { HelpEffects } from "./help.effects";
import { SecurityEffects } from "./security.effects";
import { ProfileEffects } from "./profile.effects";
import { MyServicesEffects } from "./my-services.effects";
import { QuickActionsEffects } from "./quick-action.effects";
import { OrganisationalProfileEffects } from "./organisational-profile.effects";
import { RouterEffects } from "./router.effects";
import { PersonalProfileEffects } from "./personal-profile.effects";
import { AlertEffects } from "./alert.effects";
import { MyRightsEffects } from "./my-rights.effects";

export const effects: any[] = [
  EndpointEffects,
  TaskEffects,
  ProcessEffects,
  IncidentEffects,
  TemplateEffects,
  HelpEffects,
  SecurityEffects,
  ProfileEffects,
  MyServicesEffects,
  QuickActionsEffects,
  OrganisationalProfileEffects,
  RouterEffects,
  PersonalProfileEffects,
  AlertEffects,
  MyRightsEffects,
];

export * from "./endpoint.effects";
export * from "./task.effects";
export * from "./process.effects";
export * from "./incident.effects";
export * from "./template.effects";
export * from "./help.effects";
export * from "./security.effects";
export * from "./process.effects";
export * from "./my-services.effects";
export * from "./quick-action.effects";
export * from "./organisational-profile.effects";
export * from "./router.effects";
export * from "./personal-profile.effects";
export * from "./alert.effects";
export * from "./my-rights.effects";
