import { NgModule } from "@angular/core";

import { SidenavComponent } from "./sidenav/sidenav.component";
import { SidenavExtensionComponent } from "./sidenav-extension/sidenav-extension.component";
import { SidenavTreeComponent } from "./sidenav-tree/sidenav-tree.component";
import { RouterModule } from "@angular/router";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { UtilsModule } from "gematik-form-library";
import { CommonModule } from "@angular/common";
import { GemComponentsModule } from "projects/gematik-form-library/src/public-api";

@NgModule({
  declarations: [
    SidenavComponent,
    SidenavExtensionComponent,
    SidenavTreeComponent,
    ToolbarComponent,
  ],
  imports: [RouterModule, TranslateModule, UtilsModule, CommonModule, GemComponentsModule],
  exports: [SidenavComponent, SidenavExtensionComponent, SidenavTreeComponent, ToolbarComponent],
})
export class NavigationModule {}
