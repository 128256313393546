import * as fromPersonalProfile from "../actions/personal-profile.actions";
import { SuiteCrmAssignmentGroupIdentity, SuiteCrmPartnerAddress } from "gematik-shared";

export const FEATURE_KEY = "personalProfile";

export interface PersonalProfileState {
  assignmentGroupIdentities: {
    entities: { [id: string]: SuiteCrmAssignmentGroupIdentity };
    loading: boolean;
    loaded: boolean;
  };
  addresses: {
    agi: {
      [id: string]: {
        entities: { [id: string]: SuiteCrmPartnerAddress };
      };
    };
    loading: boolean;
    loaded: boolean;
  };
}

export const initialState: PersonalProfileState = {
  assignmentGroupIdentities: {
    entities: {},
    loading: false,
    loaded: false,
  },
  addresses: {
    agi: {},
    loading: false,
    loaded: false,
  },
};

export function reducer(
  state = initialState,
  action: fromPersonalProfile.PersonalProfileActions,
): PersonalProfileState {
  switch (action.type) {
    case fromPersonalProfile.LOAD_ASSIGNMENT_GROUP_IDENTITIES: {
      return {
        ...state,
        assignmentGroupIdentities: {
          ...state.assignmentGroupIdentities,
          loading: true,
        },
      };
    }
    case fromPersonalProfile.LOAD_ASSIGNMENT_GROUP_IDENTITIES_SUCCESS: {
      const assignmentGroupIdentities = action.payload;
      const entities = assignmentGroupIdentities.reduce(
        (
          entities: { [id: string]: SuiteCrmAssignmentGroupIdentity },
          assignmentGroupIdentity: SuiteCrmAssignmentGroupIdentity,
        ) => {
          return {
            ...entities,
            [assignmentGroupIdentity.id]: assignmentGroupIdentity,
          };
        },
        {
          ...state.assignmentGroupIdentities.entities,
        },
      );
      return {
        ...state,
        assignmentGroupIdentities: {
          loading: false,
          loaded: true,
          entities,
        },
      };
    }
    case fromPersonalProfile.LOAD_ASSIGNMENT_GROUP_IDENTITIES_FAIL: {
      return {
        ...state,
        assignmentGroupIdentities: {
          ...state.assignmentGroupIdentities,
          loading: false,
          loaded: false,
        },
      };
    }
    // Assignment Group Identity Addresses
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS:
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS:
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS:
    case fromPersonalProfile.LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
        },
      };
    }
    case fromPersonalProfile.LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES_SUCCESS: {
      const addresses = action.payload;
      const agiId = action.agiId;
      const entities = addresses.reduce(
        (entities: { [id: string]: SuiteCrmPartnerAddress }, address: SuiteCrmPartnerAddress) => {
          return {
            ...entities,
            [address.id]: address,
          };
        },
        {
          ...state.addresses.agi[agiId]?.entities,
        },
      );
      return {
        ...state,
        addresses: {
          loading: false,
          loaded: true,
          agi: {
            ...state.addresses.agi,
            [agiId]: {
              entities,
            },
          },
        },
      };
    }

    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS:
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS: {
      const address = action.payload;
      const agiId = action.agiId;
      const entities = {
        ...state.addresses.agi[agiId].entities,
        [address.id]: address,
      };
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            [agiId]: {
              entities,
            },
          },
        },
      };
    }
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_SUCCESS: {
      const payload = action;
      const { [payload.addressId]: removed, ...entities } =
        state.addresses.agi[payload.agiId].entities;
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            ...state.addresses.agi,
            [payload.agiId]: {
              entities,
            },
          },
        },
      };
    }
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL:
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL:
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_FAIL:
    case fromPersonalProfile.LOAD_ASSIGNMENT_GROUP_IDENTITY_ADDRESSES_FAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          loaded: false,
        },
      };
    }

    // Assignment Group Identity Address Mails
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL:
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL:
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
        },
      };
    }
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS: {
      const mail = action.payload;
      const agiId = action.agiId;
      const addressId = action.addressId;
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            [agiId]: {
              entities: {
                ...state.addresses.agi[agiId].entities,
                [addressId]: {
                  ...state.addresses.agi[agiId].entities[addressId],
                  emails: [...state.addresses.agi[agiId].entities[addressId].emails, mail],
                },
              },
            },
          },
        },
      };
    }
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS: {
      const mail = action.payload;
      const agiId = action.agiId;
      const addressId = action.addressId;
      const mailId = action.mailId;
      const index = state.addresses.agi[agiId].entities[addressId].emails.findIndex(
        (e) => e.id === mail.id,
      );
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            [agiId]: {
              entities: {
                ...state.addresses.agi[agiId].entities,
                [addressId]: {
                  ...state.addresses.agi[agiId].entities[addressId],
                  emails: state.addresses.agi[agiId].entities[addressId].emails.map((obj, i) =>
                    i === index ? mail : obj,
                  ),
                },
              },
            },
          },
        },
      };
    }
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_SUCCESS: {
      const agiId = action.agiId;
      const addressId = action.addressId;
      const mailId = action.mailId;
      const index = state.addresses.agi[agiId].entities[addressId].emails.findIndex(
        (e) => e.id === mailId,
      );
      const emails = [...state.addresses.agi[agiId].entities[addressId].emails];
      emails.splice(index, 1);
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            [agiId]: {
              entities: {
                ...state.addresses.agi[agiId].entities,
                [addressId]: {
                  ...state.addresses.agi[agiId].entities[addressId],
                  emails: [...emails],
                },
              },
            },
          },
        },
      };
    }
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL:
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL:
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_MAIL_FAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          loaded: false,
        },
      };
    }
    // Assignment Group Identity Address Phones
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE:
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE:
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
        },
      };
    }
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS: {
      const phone = action.payload;
      const agiId = action.agiId;
      const addressId = action.addressId;
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            [agiId]: {
              entities: {
                ...state.addresses.agi[agiId].entities,
                [addressId]: {
                  ...state.addresses.agi[agiId].entities[addressId],
                  phones: [...state.addresses.agi[agiId].entities[addressId].phones, phone],
                },
              },
            },
          },
        },
      };
    }
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS: {
      const phone = action.payload;
      const agiId = action.agiId;
      const addressId = action.addressId;
      const phoneId = action.phoneId;
      const index = state.addresses.agi[agiId].entities[addressId].phones.findIndex(
        (e) => e.id === phone.id,
      );
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            [agiId]: {
              entities: {
                ...state.addresses.agi[agiId].entities,
                [addressId]: {
                  ...state.addresses.agi[agiId].entities[addressId],
                  phones: state.addresses.agi[agiId].entities[addressId].phones.map((obj, i) =>
                    i === index ? phone : obj,
                  ),
                },
              },
            },
          },
        },
      };
    }
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_SUCCESS: {
      const agiId = action.agiId;
      const addressId = action.addressId;
      const phoneId = action.phoneId;
      const index = state.addresses.agi[agiId].entities[addressId].phones.findIndex(
        (e) => e.id === phoneId,
      );
      const phones = [...state.addresses.agi[agiId].entities[addressId].phones];
      phones.splice(index, 1);
      return {
        ...state,
        addresses: {
          ...state.addresses,
          agi: {
            [agiId]: {
              entities: {
                ...state.addresses.agi[agiId].entities,
                [addressId]: {
                  ...state.addresses.agi[agiId].entities[addressId],
                  phones: [...phones],
                },
              },
            },
          },
        },
      };
    }
    case fromPersonalProfile.DELETE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL:
    case fromPersonalProfile.UPDATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL:
    case fromPersonalProfile.CREATE_ASSIGNMENT_GROUP_IDENTITY_ADDRESS_PHONE_FAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          loaded: false,
        },
      };
    }
  }
  return state;
}

export const getAssignmentGroupIdentitiesEntities = (state: PersonalProfileState) =>
  state.assignmentGroupIdentities.entities;
export const getAssignmentGroupIdentitiesLoading = (state: PersonalProfileState) =>
  state.assignmentGroupIdentities.loading;
export const getAssignmentGroupIdentitiesLoaded = (state: PersonalProfileState) =>
  state.assignmentGroupIdentities.loaded;
