import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface HtmlDisplayDialogData {
  id: string;
  html: string;
  title: string;
}
@Component({
  selector: "gem-html-display-dialog",
  templateUrl: "./gem-html-display-dialog.component.html",
  styleUrls: ["./gem-html-display-dialog.component.scss"],
})
export class GemHtmlDisplayDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GemHtmlDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HtmlDisplayDialogData,
  ) {}

  ngOnInit(): void {}
  confirmAction(): void {
    this.dialogRef.close();
  }
}
