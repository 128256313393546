import * as fromOrganisationalProfile from "../actions/organisational-profile.actions";
import { SuiteCrmPartner, SuiteCrmPartnerAddress, SuiteCrmPartnerRole } from "gematik-shared";

export interface OrganisationProfileState {
  organisations: {
    entities: { [id: string]: SuiteCrmPartner };
    loading: boolean;
    loaded: boolean;
  };
  roles: {
    organisation: {
      [id: string]: {
        entities: { [id: string]: SuiteCrmPartnerRole };
      };
    };
    loading: boolean;
    loaded: boolean;
  };
  addresses: {
    role: {
      [id: string]: {
        entities: { [id: string]: SuiteCrmPartnerAddress };
      };
    };
    loading: boolean;
    loaded: boolean;
  };
}

export const initialState: OrganisationProfileState = {
  organisations: {
    entities: {},
    loading: false,
    loaded: false,
  },
  roles: {
    organisation: {},
    loading: false,
    loaded: false,
  },
  addresses: {
    role: {},
    loading: false,
    loaded: false,
  },
};

export function reducer(
  state = initialState,
  action: fromOrganisationalProfile.OrganisationProfileActions,
): OrganisationProfileState {
  switch (action.type) {
    // Organisations
    case fromOrganisationalProfile.UPDATE_ORGANISATION:
    case fromOrganisationalProfile.LOAD_ORGANISATIONS: {
      return {
        ...state,
        organisations: {
          ...state.organisations,
          loading: true,
        },
      };
    }
    case fromOrganisationalProfile.LOAD_ORGANISATIONS_SUCCESS: {
      const organisations = action.payload;
      const entities = organisations.reduce(
        (entities: { [id: string]: SuiteCrmPartner }, organisation: SuiteCrmPartner) => {
          return {
            ...entities,
            [organisation.id]: organisation,
          };
        },
        {
          ...state.organisations.entities,
        },
      );
      return {
        ...state,
        organisations: {
          loading: false,
          loaded: true,
          entities,
        },
      };
    }
    case fromOrganisationalProfile.UPDATE_ORGANISATION_SUCCESS: {
      const organisation = action.payload;
      const entities = {
        ...state.organisations.entities,
        [organisation.id]: organisation,
      };
      return {
        ...state,
        organisations: {
          entities,
          loading: false,
          loaded: true,
        },
      };
    }
    case fromOrganisationalProfile.UPDATE_ORGANISATION_FAIL:
    case fromOrganisationalProfile.LOAD_ORGANISATIONS_FAIL: {
      return {
        ...state,
        organisations: {
          ...state.organisations,
          loading: false,
          loaded: false,
        },
      };
    }

    // Organisation Roles
    case fromOrganisationalProfile.LOAD_ORGANISATION_ROLES: {
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };
    }
    case fromOrganisationalProfile.LOAD_ORGANISATION_ROLES_SUCCESS: {
      const roles = action.payload;
      const orgId = action.orgId;
      const entities = roles.reduce(
        (entities: { [id: string]: SuiteCrmPartnerRole }, address: SuiteCrmPartnerRole) => {
          return {
            ...entities,
            [address.id]: address,
          };
        },
        {
          ...state.roles.organisation[orgId]?.entities,
        },
      );
      return {
        ...state,
        roles: {
          loading: false,
          loaded: true,
          organisation: {
            ...state.roles.organisation,
            [orgId]: {
              entities,
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.LOAD_ORGANISATION_ROLES_FAIL: {
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          loaded: false,
        },
      };
    }

    // Organisation Role Addresses
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS:
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS:
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS:
    case fromOrganisationalProfile.LOAD_ORGANISATION_ROLE_ADDRESSES: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
        },
      };
    }
    case fromOrganisationalProfile.LOAD_ORGANISATION_ROLE_ADDRESSES_SUCCESS: {
      const addresses = action.payload;
      const orgId = action.orgId;
      const orgRoleId = action.orgRoleId;
      const id = orgId + ":" + orgRoleId;
      const entities = addresses.reduce(
        (entities: { [id: string]: SuiteCrmPartnerAddress }, address: SuiteCrmPartnerAddress) => {
          return {
            ...entities,
            [address.id]: address,
          };
        },
        {
          ...state.addresses.role[id]?.entities,
        },
      );
      return {
        ...state,
        addresses: {
          loading: false,
          loaded: true,
          role: {
            ...state.addresses.role,
            [id]: {
              entities,
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_SUCCESS:
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_SUCCESS: {
      const address = action.payload;
      const orgId = action.orgId;
      const orgRoleId = action.orgRoleId;
      const id = orgId + ":" + orgRoleId;
      const entities = {
        ...state.addresses.role[id].entities,
        [address.id]: address,
      };
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            [id]: {
              entities,
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_SUCCESS: {
      const payload = action;
      const id = payload.orgId + ":" + payload.orgRoleId;
      const { [payload.addressId]: removed, ...entities } = state.addresses.role[id].entities;
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            ...state.addresses.role,
            [id]: {
              entities,
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_FAIL:
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_FAIL:
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_FAIL:
    case fromOrganisationalProfile.LOAD_ORGANISATION_ROLE_ADDRESSES_FAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          loaded: false,
        },
      };
    }
    // Organisation Role Address Mails
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_MAIL:
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL:
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_MAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
        },
      };
    }
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS: {
      const mail = action.payload;
      const orgId = action.orgId;
      const orgRoleId = action.orgRoleId;
      const id = orgId + ":" + orgRoleId;
      const addressId = action.addressId;
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            [id]: {
              entities: {
                ...state.addresses.role[id].entities,
                [addressId]: {
                  ...state.addresses.role[id].entities[addressId],
                  emails: [...state.addresses.role[id].entities[addressId].emails, mail],
                },
              },
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS: {
      const mail = action.payload;
      const orgId = action.orgId;
      const orgRoleId = action.orgRoleId;
      const id = orgId + ":" + orgRoleId;
      const addressId = action.addressId;
      const mailId = action.mailId;
      const index = state.addresses.role[id].entities[addressId].emails.findIndex(
        (e) => e.id === mail.id,
      );
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            [id]: {
              entities: {
                ...state.addresses.role[id].entities,
                [addressId]: {
                  ...state.addresses.role[id].entities[addressId],
                  emails: state.addresses.role[id].entities[addressId].emails.map((obj, i) =>
                    i === index ? mail : obj,
                  ),
                },
              },
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS: {
      const orgId = action.orgId;
      const orgRoleId = action.orgRoleId;
      const id = orgId + ":" + orgRoleId;
      const addressId = action.addressId;
      const mailId = action.mailId;
      const index = state.addresses.role[id].entities[addressId].emails.findIndex(
        (e) => e.id === mailId,
      );
      const emails = [...state.addresses.role[id].entities[addressId].emails];
      emails.splice(index, 1);
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            [id]: {
              entities: {
                ...state.addresses.role[id].entities,
                [addressId]: {
                  ...state.addresses.role[id].entities[addressId],
                  emails: [...emails],
                },
              },
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL:
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL:
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          loaded: false,
        },
      };
    }
    // Organisation Role Address Phones
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_PHONE:
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE:
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_PHONE: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
        },
      };
    }
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS: {
      const phone = action.payload;
      const orgId = action.orgId;
      const id = orgId + ":" + action.orgRoleId;
      const addressId = action.addressId;
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            [id]: {
              entities: {
                ...state.addresses.role[id].entities,
                [addressId]: {
                  ...state.addresses.role[id].entities[addressId],
                  phones: [...state.addresses.role[id].entities[addressId].phones, phone],
                },
              },
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS: {
      const phone = action.payload;
      const orgId = action.orgId;
      const id = orgId + ":" + action.orgRoleId;
      const addressId = action.addressId;
      const phoneId = action.phoneId;
      const index = state.addresses.role[id].entities[addressId].phones.findIndex(
        (e) => e.id === phone.id,
      );
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            [id]: {
              entities: {
                ...state.addresses.role[id].entities,
                [addressId]: {
                  ...state.addresses.role[id].entities[addressId],
                  phones: state.addresses.role[id].entities[addressId].phones.map((obj, i) =>
                    i === index ? phone : obj,
                  ),
                },
              },
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS: {
      const orgId = action.orgId;
      const id = orgId + ":" + action.orgRoleId;
      const addressId = action.addressId;
      const phoneId = action.phoneId;
      const index = state.addresses.role[id].entities[addressId].phones.findIndex(
        (e) => e.id === phoneId,
      );
      const phones = [...state.addresses.role[id].entities[addressId].phones];
      phones.splice(index, 1);
      return {
        ...state,
        addresses: {
          ...state.addresses,
          role: {
            [id]: {
              entities: {
                ...state.addresses.role[id].entities,
                [addressId]: {
                  ...state.addresses.role[id].entities[addressId],
                  phones: [...phones],
                },
              },
            },
          },
        },
      };
    }
    case fromOrganisationalProfile.DELETE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL:
    case fromOrganisationalProfile.UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL:
    case fromOrganisationalProfile.CREATE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          loaded: false,
        },
      };
    }
  }
  return state;
}

export const getOrganisationsEntities = (state: OrganisationProfileState) =>
  state.organisations.entities;

export const getOrganisationRolesOrganisations = (state: OrganisationProfileState) =>
  state.roles.organisation;

export const getOrganisationAddressesOrganisations = (state: OrganisationProfileState) =>
  state.addresses.role;
