import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromAlerts from "../reducers/alert.reducer";

export const getAlertsState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.alerts,
);

export const getAlertEntities = createSelector(getAlertsState, fromAlerts.getAlertEntities);

export const getAlerts = createSelector(getAlertEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getAlertsLoaded = createSelector(getAlertsState, fromAlerts.getAlertsLoaded);

export const getAlertsLoading = createSelector(getAlertsState, fromAlerts.getAlertsLoading);
