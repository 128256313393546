import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SuiteCrmPartnerAddressPhone } from "gematik-shared";
import { Subscription } from "rxjs";

export interface OrganisationalAddressPhoneData {
  phone: SuiteCrmPartnerAddressPhone;
}

@Component({
  selector: "communication-address-phone",
  styleUrls: ["communication-address-phone.component.scss"],
  template: `
    <communication-address-phone-form
      data-testid="email-form"
      [addressPhone]="phone"
      (save)="onSave($event)"
      (cancel)="onCancel()"
    ></communication-address-phone-form>
  `,
})
export class CommunicationAddressPhoneComponent implements OnInit, OnDestroy {
  orgId: string;
  addressId: string;

  phone: SuiteCrmPartnerAddressPhone;

  subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<CommunicationAddressPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrganisationalAddressPhoneData,
  ) {}

  ngOnInit(): void {
    this.phone = this.data?.phone;
  }

  onSave(phone: SuiteCrmPartnerAddressPhone): void {
    this.dialogRef.close({ phone: phone });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
