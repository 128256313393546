import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import {
  SuiteCrmPartner,
  SuiteCrmPartnerAddress,
  SuiteCrmPartnerAddressMail,
  SuiteCrmPartnerAddressPhone,
  SuiteCrmPartnerRole,
} from "gematik-shared";

@Injectable({
  providedIn: "root",
})
export class OrganisationalProfileService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getPartners(): Observable<SuiteCrmPartner[]> {
    return this.http.get<SuiteCrmPartner[]>(
      `${this.env.backendUrl}/portal/profile/organisational/partners`,
    );
  }

  updatePartner(id: string, payload: SuiteCrmPartner): Observable<SuiteCrmPartner> {
    return this.http.post<SuiteCrmPartner>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${id}`,
      payload,
    );
  }

  getPartnerRoles(orgId: string): Observable<SuiteCrmPartnerRole[]> {
    return this.http.get<SuiteCrmPartnerRole[]>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles`,
    );
  }

  getPartnerRoleAddresses(orgId: string, orgRoleId: string): Observable<SuiteCrmPartnerAddress[]> {
    return this.http.get<SuiteCrmPartnerAddress[]>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses`,
    );
  }

  createPartnerRoleAddress(
    orgId: string,
    orgRoleId: string,
    payload: SuiteCrmPartnerAddress,
  ): Observable<SuiteCrmPartnerAddress> {
    return this.http.post<SuiteCrmPartnerAddress>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses`,
      payload,
    );
  }

  updatePartnerRoleAddress(
    orgId: string,
    orgRoleId: string,
    payload: SuiteCrmPartnerAddress,
  ): Observable<SuiteCrmPartnerAddress> {
    return this.http.put<SuiteCrmPartnerAddress>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${payload.id}`,
      payload,
    );
  }

  deletePartnerRoleAddress(orgId: string, orgRoleId: string, addressId: string): Observable<any> {
    return this.http.delete(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${addressId}`,
    );
  }

  createPartnerRoleAddressMail(
    orgId: string,
    orgRoleId: string,
    addressId: string,
    payload: SuiteCrmPartnerAddressMail,
  ): Observable<SuiteCrmPartnerAddressMail> {
    return this.http.post<SuiteCrmPartnerAddressMail>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${addressId}/mails`,
      payload,
    );
  }

  updatePartnerRoleAddressMail(
    orgId: string,
    orgRoleId: string,
    addressId: string,
    mailId: string,
    payload: SuiteCrmPartnerAddressMail,
  ): Observable<SuiteCrmPartnerAddressMail> {
    return this.http.put<SuiteCrmPartnerAddressMail>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${addressId}/mails/${mailId}`,
      payload,
    );
  }

  deletePartnerRoleAddressMail(
    orgId: string,
    orgRoleId: string,
    addressId: string,
    mailId: string,
  ): Observable<any> {
    return this.http.delete(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${addressId}/mails/${mailId}`,
    );
  }

  createPartnerRoleAddressPhone(
    orgId: string,
    orgRoleId: string,
    addressId: string,
    payload: SuiteCrmPartnerAddressPhone,
  ): Observable<SuiteCrmPartnerAddressPhone> {
    return this.http.post<SuiteCrmPartnerAddressPhone>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${addressId}/phones`,
      payload,
    );
  }

  updatePartnerRoleAddressPhone(
    orgId: string,
    orgRoleId: string,
    addressId: string,
    phoneId: string,
    payload: SuiteCrmPartnerAddressPhone,
  ): Observable<SuiteCrmPartnerAddressPhone> {
    return this.http.put<SuiteCrmPartnerAddressPhone>(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${addressId}/phones/${phoneId}`,
      payload,
    );
  }

  deletePartnerRoleAddressPhone(
    orgId: string,
    orgRoleId: string,
    addressId: string,
    phoneId: string,
  ): Observable<any> {
    return this.http.delete(
      `${this.env.backendUrl}/portal/profile/organisational/partners/${orgId}/roles/${orgRoleId}/addresses/${addressId}/phones/${phoneId}`,
    );
  }
}
