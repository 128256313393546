import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromProfile from "../reducers/profile.reducer";

export const getProfileState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.profile,
);

export const isPartnerManager = createSelector(
  getProfileState,
  (state) => state.profile?.isPartnerManager,
);

export const getProfile = createSelector(getProfileState, fromProfile.getProfile);
export const getProfileLoaded = createSelector(getProfileState, fromProfile.getProfileLoaded);
