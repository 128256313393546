import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GematikProcessDiagram, GematikProcessDto } from "gematik-task-api";
import { GemHtmlDisplayDialogComponent } from "../gem-html-display-dialog/gem-html-display-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { UwlService } from "../../../services";
import { TranslateService } from "@ngx-translate/core";

export interface BpmnDialogData {
  dto: GematikProcessDto;
  diagram: GematikProcessDiagram;
}

@Component({
  selector: "gem-bpmn-dialog",
  templateUrl: "./gem-bpmn-dialog.component.html",
  styleUrls: ["./gem-bpmn-dialog.component.scss"],
})
export class GemBpmnDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GemBpmnDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BpmnDialogData,
    private dialog: MatDialog,
    public domSanitizer: DomSanitizer,
    private uwlService: UwlService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  confirmAction(): void {
    this.dialogRef.close();
  }

  openHelpDialog(id: string) {
    this.uwlService.findHelpConfig(id, this.translate.currentLang).subscribe(
      (res) => {
        const html = res.body.html;
        const title = res.body.title;
        this.dialog.open(GemHtmlDisplayDialogComponent, {
          data: { id, html, title },
          autoFocus: false,
        });
      },
      (err) => {
        const title = this.translate.instant("help_error_loading_title");
        const html = this.translate.instant("help_error_loading_content", {
          helpkey: id,
          lang: this.translate.currentLang,
        });
        this.dialog.open(GemHtmlDisplayDialogComponent, {
          data: { id, html, title },
        });
      },
    );
  }
}
