import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromPersonalProfile from "../reducers/personal-profile.reducer";

export const getPersonalProfileState =
  createFeatureSelector<fromPersonalProfile.PersonalProfileState>(fromPersonalProfile.FEATURE_KEY);

export const getPersonalProfileAssignmentGroupIdentitiesEntities = createSelector(
  getPersonalProfileState,
  fromPersonalProfile.getAssignmentGroupIdentitiesEntities,
);

export const getPersonalProfileAssignmentGroupIdentities = createSelector(
  getPersonalProfileAssignmentGroupIdentitiesEntities,
  (entities) => {
    return Object.keys(entities).map((id) => entities[id]);
  },
);

export const getPersonalProfileAssignmentGroupIdentity = (agi: string) =>
  createSelector(getPersonalProfileAssignmentGroupIdentitiesEntities, (entities) => {
    return entities[agi];
  });

export const getPersonalProfileAssignmentGroupIdentitiesLoaded = createSelector(
  getPersonalProfileState,
  fromPersonalProfile.getAssignmentGroupIdentitiesLoaded,
);

export const getPersonalProfileAssignmentGroupIdentityAddressesEntities = (agiId: string) =>
  createSelector(getPersonalProfileState, (state) => state.addresses.agi[agiId]?.entities);

export const getPersonalProfileAssignmentGroupIdentityAddresses = (agiId: string) =>
  createSelector(getPersonalProfileAssignmentGroupIdentityAddressesEntities(agiId), (entities) => {
    if (entities) {
      return Object.keys(entities).map((id) => entities[id]);
    }
  });

export const getPersonalProfileAssignmentGroupIdentityAddress = (
  agiId: string,
  addressId: string,
) =>
  createSelector(getPersonalProfileAssignmentGroupIdentityAddressesEntities(agiId), (entities) => {
    if (entities) {
      return entities[addressId];
    }
  });
