import * as fromMyServices from "../actions/my-services.actions";
import { MyService } from "../../models/my-service.model";

export interface MyServicesState {
  entities: { [id: number]: MyService };
  loading: boolean;
  loaded: boolean;
}

export const initialState: MyServicesState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromMyServices.MyServicesActions,
): MyServicesState {
  switch (action.type) {
    case fromMyServices.UPDATE_MY_SERVICES:
    case fromMyServices.LOAD_MY_SERVICES: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromMyServices.LOAD_MY_SERVICES_SUCCESS: {
      const myServices = action.payload;
      const myServicesEntities = myServices.reduce(
        (entities: { [id: number]: MyService }, myService: MyService) => {
          return {
            ...entities,
            [myService.id]: myService,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities: myServicesEntities,
      };
    }
    case fromMyServices.UPDATE_MY_SERVICES_SUCCESS: {
      const myServices = action.payload;
      const myServicesEntities = myServices.reduce(
        (entities: { [id: number]: MyService }, myService: MyService) => {
          return {
            ...entities,
            [myService.id]: myService,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities: myServicesEntities,
      };
    }
    case fromMyServices.UPDATE_MY_SERVICES_FAIL:
    case fromMyServices.LOAD_MY_SERVICES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getMyServicesEntities = (state: MyServicesState) => state.entities;
export const getMyServicesLoading = (state: MyServicesState) => state.loading;
export const getMyServicesLoaded = (state: MyServicesState) => state.loaded;
