import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { TokenHelperService } from "gematik-form-library";
import { GematikTaskDto } from "projects/gematik-task-api/src/public-api";
export interface DialogData {
  task: GematikTaskDto;
  control: UntypedFormControl;
  confirmAction: any;
  cancelAction: any;
}
@Component({
  selector: "gem-simple-task-dialog",
  templateUrl: "./gem-simple-task-dialog.component.html",
  styleUrls: ["./gem-simple-task-dialog.component.scss"],
})
export class GemSimpleTaskDialogComponent implements OnInit, AfterViewInit {
  title: string;
  text: string;
  fullName: string;
  inputFieldNameLabel: string;
  @ViewChild("scroll", { static: true, read: ElementRef }) myScrollContainer: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<GemSimpleTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService,
    private tokenHelper: TokenHelperService,
  ) {
    this.control = data.control;
  }
  control: UntypedFormControl;
  ngOnInit(): void {
    if (this.control && this.control !== null) {
      this.control.setValidators([Validators.required]);
      this.title = this.translateService.instant(this.data.task.name);
      this.text = this.translateService.instant(this.data.task.description);
      this.fullName = this.tokenHelper.getFullname();
      if (this.translateService.instant(`${this.data.task.taskDefinitionKey}_input_label`)) {
        this.inputFieldNameLabel = `${this.data.task.taskDefinitionKey}_input_label`;
      } else {
        this.inputFieldNameLabel = "SimpleTaskInput";
      }
    }
  }

  cancelAction(): void {
    this.data.cancelAction();
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.control) {
      this.data.confirmAction(this.control);
    } else {
      this.data.confirmAction();
    }
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  hasFieldData() {
    if (this.control) {
      if (this.control.value) {
        return false;
      }
      return true;
    }
    return false;
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
