<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "help_key" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.helpkey }}</td>
    </ng-container>

    <ng-container matColumnDef="lang">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "help_lang" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.lang }}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "help_title" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.title }}</td>
    </ng-container>

    <ng-container matColumnDef="html">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "help_html" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.html && row.html.length > 25 ? (row.html | slice: 0:45) + "..." : row.html }}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()"></td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
        <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectEntity(row)">edit</i>
        <i class="material-icons" [ngbTooltip]="'DELETE' | translate" (click)="openDeleteEntityConfirmation(row)">delete</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
</div>
