import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-gem-locking-dialog",
  templateUrl: "./gem-locking-dialog.component.html",
  styleUrls: ["./gem-locking-dialog.component.scss"],
})
export class GemLockingDialogComponent {
  constructor(public dialogRef: MatDialogRef<GemLockingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onOverride() {
    this.dialogRef.close();
  }
}
