<mat-toolbar>
  <mat-toolbar-row>
    <div class="logo-wrapper" routerLink="/">
      <img src="/gematik-shared/assets/images/logo/gematik.png" class="custom-logo" alt="gematik" />
      {{ "uwl-title" | translate }}
    </div>
    <span class="toolbar-spacer"></span>

    <div class="d-flex align-items-center gem-nav-icons">
      <mat-icon class="nav-icons" svgIcon="gem-users"></mat-icon>
      <span class="username" [matMenuTriggerFor]="profileMenu" style="cursor: pointer"
        >&nbsp;&nbsp;{{ this.tokenHelperService.getFullname() }}
        <span class="header-pipe">|</span></span
      >
    </div>

    <div class="d-flex align-items-center">
      <mat-icon
        id="btnFeedback"
        [title]="'feedbackTitle' | translate"
        role="img"
        class="nav-icons me-2 ms-2"
        svgIcon="gem-feedback"
        style="cursor: pointer; width: 30px; height: 30px"
        (click)="onFeedback()"
      ></mat-icon>
      <span class="header-pipe">|</span>
    </div>

    <div class="d-flex align-items-center">
      <a class="language-toggle" (click)="onLangClick()">{{ lang }}</a>
    </div>

    <!-- <div class="profile-div">
      <mat-icon class="nav-icons" svgIcon="gem-users"></mat-icon>
      <span class="username" [matMenuTriggerFor]="profileMenu" style="cursor: pointer"
        >&nbsp;&nbsp;{{ this.tokenHelperService.getFullname() }}</span
      >
    </div> -->

    <!-- <div class="profile-div">
      <mat-icon
        id="btnFeedback"
        [title]="'feedbackTitle' | translate"
        role="img"
        class="nav-icons ml-2 mr-2"
        svgIcon="gem-approval-blue"
        style="cursor: pointer; width: 32px; height: 32px"
        (click)="onFeedback()"
      ></mat-icon>
      <span class="header-pipe">|</span>
    </div>
    <div>
      <a class="language-toggle" (click)="onLangClick()">{{ lang }}</a>
    </div> -->

    <mat-menu #profileMenu="matMenu">
      <button id="logout-button" mat-menu-item (click)="logout()">
        {{ "logout" | translate }}
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
