import * as fromEndpoints from "../actions/endpoint.actions";
import { Endpoint } from "../../services/uwl/uwl.service";

export const FEATURE_KEY = "endpoints";

export interface EndpointState {
  entities: { [id: number]: Endpoint };
  loading: boolean;
  loaded: boolean;
}

export const initialState: EndpointState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromEndpoints.EndpointActions,
): EndpointState {
  switch (action.type) {
    case fromEndpoints.DELETE_ENDPOINT:
    case fromEndpoints.UPDATE_ENDPOINT:
    case fromEndpoints.CREATE_ENDPOINT:
    case fromEndpoints.LOAD_ENDPOINTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromEndpoints.LOAD_ENDPOINTS_SUCCESS: {
      const endpoints = action.payload;
      const entities = endpoints.reduce(
        (entities: { [id: number]: Endpoint }, endpoint: Endpoint) => {
          return {
            ...entities,
            [endpoint.id]: endpoint,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromEndpoints.UPDATE_ENDPOINT_SUCCESS:
    case fromEndpoints.CREATE_ENDPOINT_SUCCESS: {
      const endpoint = action.payload;
      const entities = {
        ...state.entities,
        [endpoint.id]: endpoint,
      };
      return {
        ...state,
        entities,
        loading: false,
        loaded: true,
      };
    }

    case fromEndpoints.DELETE_ENDPOINT_SUCCESS: {
      const endpoint = action.payload;
      const { [endpoint.id]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
      };
    }

    case fromEndpoints.DELETE_ENDPOINT_FAIL:
    case fromEndpoints.UPDATE_ENDPOINT_FAIL:
    case fromEndpoints.CREATE_ENDPOINT_FAIL:
    case fromEndpoints.LOAD_ENDPOINTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getEndpointEntities = (state: EndpointState) => state.entities;
