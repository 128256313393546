import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";

import { FormHelperService } from "gematik-form-library";
import { Endpoint, UwlService } from "../../../services/uwl/uwl.service";

@Component({
  selector: "endpoint-form",
  templateUrl: "./endpoint-form.component.html",
  styleUrls: ["./endpoint-form.component.scss"],
})
export class EndpointFormComponent implements OnInit {
  entityForm: UntypedFormGroup;

  @Output() entityCreatedEdited = new EventEmitter<UntypedFormGroup>();

  @Input() set entity(entity: Endpoint) {
    this.entityForm = this.createForm(entity);
  }

  constructor(private uwlService: UwlService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  createForm(entity: Endpoint) {
    const fg = this.uwlService.mapUwlEndpointToForm(entity);
    fg.get("name").setValidators(Validators.required);
    fg.get("url").setValidators(Validators.required);
    fg.get("roles").setValidators(Validators.required);
    fg.get("labelEn").setValidators(Validators.required);
    fg.get("labelDe").setValidators(Validators.required);
    fg.get("icon").setValidators(Validators.required);
    fg.get("link").setValidators(Validators.required);
    fg.get("enabledInMyServices").setValidators(Validators.required);
    return fg;
  }

  createEditEntity() {
    this.formHelper.isFormValidElseShowErrors(this.entityForm, "createEntityErrorTitle", () => {
      this.entityCreatedEdited.emit(this.entityForm);
    });
  }

  cancel() {
    this.entityCreatedEdited.emit(null);
  }
}
