<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ "access-denied" | translate }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p>{{ "access-denied-text" | translate }}</p>
      </div>
    </div>
  </div>
</div>
