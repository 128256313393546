import { Alert } from "gematik-shared";
import * as fromAlerts from "../actions/alert.actions";

export interface AlertState {
  entities: { [id: string]: Alert };
  loading: boolean;
  loaded: boolean;
}

export const initialState: AlertState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromAlerts.AlertActions): AlertState {
  switch (action.type) {
    case fromAlerts.DELETE_ALERT:
    case fromAlerts.UPDATE_ALERT:
    case fromAlerts.CREATE_ALERT:
    case fromAlerts.LOAD_ALERTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromAlerts.LOAD_ALERTS_SUCCESS: {
      const alerts = action.payload;
      const entities = alerts.reduce(
        (entities: { [id: string]: Alert }, alert: Alert) => {
          return {
            ...entities,
            [alert.id]: alert,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromAlerts.UPDATE_ALERT_SUCCESS:
    case fromAlerts.CREATE_ALERT_SUCCESS: {
      const alert = action.payload;
      const entities = {
        ...state.entities,
        [alert.id]: alert,
      };
      return {
        ...state,
        entities,
        loading: false,
        loaded: true,
      };
    }

    case fromAlerts.DELETE_ALERT_SUCCESS: {
      const alert = action.payload;
      const { [alert.id]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
      };
    }

    case fromAlerts.DELETE_ALERT_FAIL:
    case fromAlerts.UPDATE_ALERT_FAIL:
    case fromAlerts.CREATE_ALERT_FAIL:
    case fromAlerts.LOAD_ALERTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getAlertEntities = (state: AlertState) => state.entities;
export const getAlertsLoading = (state: AlertState) => state.loading;
export const getAlertsLoaded = (state: AlertState) => state.loaded;
