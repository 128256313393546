<h1 mat-dialog-title>{{ title + " - " + ("buid" | translate) + ": " + data.task.buid }}</h1>

<div class="container">
  <div class="row">
    <div class="col">{{ "partner" | translate }}</div>
    <div class="col">{{ data.task.partner }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "partnerType" | translate }}</div>
    <div class="col">{{ data.task.partnerType }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "partnerRole" | translate }}</div>
    <div class="col">{{ data.task.role }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "processName" | translate }}</div>
    <div class="col">{{ data.task.processName | translate }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "taskName" | translate }}</div>
    <div class="col">{{ data.task?.name | translate }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "createdTimeStamp" | translate }}</div>
    <div class="col">{{ data.task?.created | date : "dd.MM.YYYY HH:mm" }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "completedtimeStamp" | translate }}</div>
    <div class="col">{{ data.task?.completed | date : "dd.MM.YYYY HH:mm" }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "taskApprover" | translate }}</div>
    <div class="col">{{ data.task?.completedBy }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "taskOutcome" | translate }}</div>
    <div class="col">{{ data.task?.outcome | translate }}</div>
  </div>

  <div class="row">
    <div class="col">{{ "customContentOne" | translate }}</div>
    <div
      class="col"
      [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.task?.customTaskContentOne)"
    ></div>
  </div>

  <div class="row">
    <div class="col">{{ "customContentTwo" | translate }}</div>
    <div
      class="col"
      [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.task.customTaskContentTwo)"
    ></div>
  </div>

  <div class="row">
    <div class="col">{{ "customContentThree" | translate }}</div>
    <div
      class="col"
      [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.task.customTaskContentThree)"
    ></div>
  </div>
</div>

<div mat-dialog-actions class="mt-3">
  <button class="btn btn-primary" (click)="confirmAction()">
    {{ "OK" | translate }}
  </button>
</div>
