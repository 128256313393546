import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as helpActions from "../actions/help.actions";
import * as fromServices from "../../services";

@Injectable()
export class HelpEffects {
  constructor(private actions$: Actions, private helpService: fromServices.HelpService) {}

  loadHelp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(helpActions.LOAD_HELP),
      switchMap((payload: any) => {
        return this.helpService.getAll().pipe(
          map((help) => new helpActions.LoadHelpSuccess(help)),
          catchError((error) => of(new helpActions.LoadHelpFail(error))),
        );
      }),
    );
  });

  createHelp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(helpActions.CREATE_HELP),
      map((action: helpActions.CreateHelp) => action.payload),
      switchMap((help) => {
        return this.helpService.create(help).pipe(
          map((response) => new helpActions.CreateHelpSuccess(response)),
          catchError((error) => of(new helpActions.CreateHelpFail(error))),
        );
      }),
    );
  });

  updateHelp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(helpActions.UPDATE_HELP),
      map((action: helpActions.UpdateHelp) => action.payload),
      switchMap((help) => {
        return this.helpService.update(help).pipe(
          map((response) => new helpActions.UpdateHelpSuccess(response)),
          catchError((error) => of(new helpActions.UpdateHelpFail(error))),
        );
      }),
    );
  });

  deleteHelp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(helpActions.DELETE_HELP),
      map((action: helpActions.DeleteHelp) => action.payload),
      switchMap((help) => {
        return this.helpService.delete(help).pipe(
          map((response) => new helpActions.DeleteHelpSuccess(help)),
          catchError((error) => of(new helpActions.DeleteHelpFail(error))),
        );
      }),
    );
  });
}
