<div class="row">
  <div class="col-sm-12">
    <h1>{{ title | translate }}</h1>
  </div>
  <div class="col-sm-12">
    <span *ngIf="processTarget === 'subscriber'">{{
      "portal.processes.group.subTitle" | translate
    }}</span>
    <span *ngIf="processTarget === 'my'">{{ "portal.processes.my.subTitle" | translate }}</span>
  </div>
</div>
<div class="mt-4"></div>
<div class="row">
  <div class="col-sm-10">
    <form [formGroup]="form">
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="searchInput"
          placeholder="Suche"
          formControlName="searchStr"
        />
        <label for="searchInput">{{ "portal.fields.search.label" | translate }}</label>
      </div>
    </form>
  </div>
  <div class="col-sm-2"></div>
</div>
<div class="mt-3"></div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        (click)="onToggleView()"
        [checked]="!activeTab"
        id="viewSwitch"
      />
      <label class="form-check-label" for="viewSwitch">{{
        "portal.processes.fields.closed.label" | translate
      }}</label>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <processes-table
      [ngStyle]="{ display: activeTab ? 'block' : 'none' }"
      #processTable
      [processList]="activeProcesses$ | async"
      [type]="processTarget"
      [searchStr]="searchStr.value"
      (clearFilter)="onClearFilter($event)"
    >
    </processes-table>
    <processes-table
      [ngStyle]="{ display: activeTab ? 'none' : 'block' }"
      #processTable
      [processList]="completedProcesses"
      [completed]="true"
      [type]="processTarget"
      [searchStr]="searchStr.value"
      (clearFilter)="onClearFilter($event)"
    >
    </processes-table>
  </div>
</div>

<!-- <div class="row">
  <div class="col-sm-12">
    <mat-tab-group (selectedTabChange)="clicked($event)">
      <mat-tab label="{{ 'activeProcesses' | translate }}">
        <processes-table
          #processTable
          [processList]="activeProcesses$ | async"
          [type]="processTarget"
        >
        </processes-table>
      </mat-tab>
      <mat-tab label="{{ 'completedProcesses' | translate }}">
        <processes-table
          #processTable
          [processList]="completedProcesses"
          [completed]="true"
          [type]="processTarget"
        >
        </processes-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div> -->
