import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";

export interface ITemplate {
  name: string;
  content: string;
}

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getAll(): Observable<ITemplate[]> {
    return this.http.get<ITemplate[]>(this.env.notificationsBackendUrl + "/templates");
  }

  create(template: ITemplate): Observable<ITemplate> {
    return this.http.post<ITemplate>(this.env.notificationsBackendUrl + "/templates", template);
  }

  update(template: ITemplate): Observable<ITemplate> {
    return this.http.put<ITemplate>(
      `${this.env.notificationsBackendUrl}/templates/${template.name}`,
      template,
    );
  }

  delete(template: ITemplate): Observable<ITemplate> {
    return this.http.delete<ITemplate>(
      `${this.env.notificationsBackendUrl}/templates/${template.name}`,
    );
  }
}
