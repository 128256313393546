import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { GemConfirmationDialogComponent } from "gematik-form-library";
import { ITemplate } from "../../../services";

@Component({
  selector: "templates-table",
  templateUrl: "./templates-table.component.html",
  styleUrls: ["./templates-table.component.scss"],
})
export class TemplatesTableComponent implements OnInit {
  sort: any;
  _entityList: ITemplate[];

  @Input() set entityList(entitylist: ITemplate[]) {
    this._entityList = entitylist;
    this.dataSource = new MatTableDataSource(entitylist);
    this.dataSource.paginator = this.paginator;
    if (this._entityList) {
      this.length = this._entityList.length;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  @Output() entitySelected = new EventEmitter<ITemplate>();
  @Output() deleteEntityClicked = new EventEmitter<ITemplate>();

  displayedColumns: string[] = ["name", "buttons"];
  dataSource: MatTableDataSource<ITemplate>;
  length = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
  }

  constructor(private translateService: TranslateService, private dialog: MatDialog) {}

  ngOnInit(): void {}

  selectEntity(entity: ITemplate) {
    this.entitySelected.emit(entity);
  }

  openDeleteEntityConfirmation(entity: ITemplate) {
    const name = entity.name;
    const title = this.translateService.instant("deleteEntityConfirmationTitle");
    const text = this.translateService.instant("deleteEntityConfirmationText", { name });
    this.openConfirmationDialog(title, text, () => this.deleteEntityClicked.emit(entity));
  }

  openConfirmationDialog(title: string, text: string, confirmAction: any): void {
    this.dialog.open(GemConfirmationDialogComponent, {
      data: {
        title,
        text,
        confirmAction,
      },
    });
  }
}
