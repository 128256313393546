<h1 mat-dialog-title>{{ "lockingTitle" | translate : { username: data.lock.username } }}</h1>
<div mat-dialog-content>{{ "lockingMessage" | translate : { username: data.lock.username } }}</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary resetFilter" mat-dialog-close cdkFocusInitial>
    {{ "lockingButtonCancel" | translate }}
  </button>
  <button class="btn btn-primary" color="warn" [mat-dialog-close]="data">
    {{ "lockingButtonOverride" | translate }}
  </button>
</div>
