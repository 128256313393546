import { Action } from "@ngrx/store";
import { GematikProfile } from "../../models/gematik-profile.model";

// Load Profile
export const LOAD_PROFILE = "[Profile] Load Profile";
export const LOAD_PROFILE_SUCCESS = "[Profile] Load Profile Success";
export const LOAD_PROFILE_FAIL = "[Profile] Load Profile Fail";

export class LoadProfile implements Action {
  readonly type = LOAD_PROFILE;
}

export class LoadProfileSuccess implements Action {
  readonly type = LOAD_PROFILE_SUCCESS;
  constructor(public payload: GematikProfile) {}
}

export class LoadProfileFail implements Action {
  readonly type = LOAD_PROFILE_FAIL;
  constructor(public payload: any) {}
}

// Update Profile
export const UPDATE_PROFILE = "[Profile] Update Profile";
export const UPDATE_PROFILE_SUCCESS = "[Profile] Update Profile Success";
export const UPDATE_PROFILE_FAIL = "[Profile] Update Profile Fail";

export class UpdateProfile implements Action {
  readonly type = UPDATE_PROFILE;
  constructor(public payload: GematikProfile) {}
}

export class UpdateProfileSuccess implements Action {
  readonly type = UPDATE_PROFILE_SUCCESS;
  constructor(public payload: GematikProfile) {}
}

export class UpdateProfileFail implements Action {
  readonly type = UPDATE_PROFILE_FAIL;
  constructor(public payload: any) {}
}

export type ProfileActions =
  | LoadProfile
  | LoadProfileSuccess
  | LoadProfileFail
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFail;
