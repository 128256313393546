import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonModule, LOCATION_INITIALIZED, registerLocaleData } from "@angular/common";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import {
  EnvService,
  EnvServiceProvider,
  GemComponentsModule,
  UtilsModule,
} from "gematik-form-library";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import localeDe from "@angular/common/locales/de";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { OAuthModule } from "angular-oauth2-oidc";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { NavigationModule } from "./navigation/navigation.module";
import { PagesModule } from "./pages/pages.module";
import { SidenavService } from "./services/sidenav.service";
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { environment } from "../environments/environment";
import { EntityDataModule } from "@ngrx/data";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { RxStompService } from "./services/stomp/rx-stomp.service";
import { rxStompServiceFactory } from "./services/stomp/rx-stomp-service-factory";

// shared libraries
import { GematikSharedModule } from "gematik-shared";
import { reducers, effects } from "./store";

// modules
import { SharedModule } from "./features/shared/shared.module";

// guards
import * as fromGuards from "./guards";
import moment from "moment";

registerLocaleData(localeDe);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: "./assets/i18n/", suffix: ".json?build_id=build_id_replacement_placeholder" },
    {
      prefix: "./gematik-shared/assets/i18n/",
      suffix: ".json?build_id=build_id_replacement_placeholder",
    },
  ]);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = "de";
        translate.setDefaultLang("de");
        translate.use("en").subscribe(
          () => {
            console.info(`Successfully initialized '${"en"}' language.'`);
            translate.use(langToSet).subscribe(
              () => {
                console.info(`Successfully initialized '${langToSet}' language.'`);
              },
              (err) => {
                console.error(`Problem with '${langToSet}' language initialization.'`);
              },
              () => {
                resolve(null);
              },
            );
          },
          (err) => {
            console.error(`Problem with '${"en"}' language initialization.'`);
          },
          () => {
            resolve(null);
          },
        );
      });
    });
}

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgbModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatToolbarModule,
    UtilsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ["http"],
        sendAccessToken: true,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PagesModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature("uwl", reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EntityDataModule.forRoot({}),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
      routerState: RouterState.Minimal,
    }),
    GemComponentsModule,
    NavigationModule,
    GematikSharedModule.forRoot(),
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD.MM.YYYY",
        },
        display: {
          dateInput: "DD.MM.YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [EnvService],
    },
    EnvServiceProvider,
    SidenavService,
    ...fromGuards.guards,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    this.overrideDate();
  }

  overrideDate() {
    Date.prototype.toJSON = function () {
      return moment(this).format("YYYY-MM-DDTHH:mm:ss");
    };
  }
}
