import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { EnvService, TokenHelperService } from "gematik-form-library";

export const SECURITY_GROUP_BASIC: string = "G.CLOUD.PIP.Basis";
export const SECURITY_GROUP_ADMIN: string = "G.CLOUD.PIP.UWL_Admin";

export interface Group {
  id: string;
  name: string;
}

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private tokenHelperService: TokenHelperService,
  ) {}

  // getGroupNames(groupIds: string[]): Observable<Group[]> {
  //   return this.http.post<Group[]>(this.env.backendUrl + "/uwl/aad/groupByIds", groupIds);
  // }

  getGroupNamesByUserId(): Observable<Group[]> {
    return this.http
      .get<string[]>(
        `${this.env.backendUrl}/uwl/aad/groupsByUserId/${this.tokenHelperService.getUsername()}`,
      )
      .pipe(
        map((groups) =>
          groups.map((group) => {
            return {
              id: group,
              name: group,
            };
          }),
        ),
      );
  }
}
