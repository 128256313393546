import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Endpoint } from "../../../services/uwl/uwl.service";
import { GemConfirmationDialogComponent } from "gematik-form-library";

@Component({
  selector: "endpoint-table",
  templateUrl: "./endpoint-table.component.html",
  styleUrls: ["./endpoint-table.component.scss"],
})
export class EndpointTableComponent implements OnInit {
  sort: any;
  _entityList: Endpoint[];

  @Input() set entityList(entitylist: Endpoint[]) {
    this._entityList = entitylist;
    this.dataSource = new MatTableDataSource(entitylist);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  @Output() entitySelected = new EventEmitter<Endpoint>();
  @Output() deleteEntityClicked = new EventEmitter<Endpoint>();
  @Output() restartWebSocketEvent = new EventEmitter<Endpoint>();

  displayedColumns: string[] = [
    "name",
    "url",
    "roles",
    "labelEn",
    "labelDe",
    "icon",
    "link",
    "enabledInMyServices",
    "buttons",
  ];
  dataSource: MatTableDataSource<Endpoint>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
  }

  handsetBreakpointMatched: boolean = false;

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {}

  selectEntity(entity: Endpoint) {
    this.entitySelected.emit(entity);
  }

  openDeleteEntityConfirmation(entity: Endpoint) {
    const name = entity.name;
    const title = this.translateService.instant("deleteEntityConfirmationTitle");
    const text = this.translateService.instant("deleteEntityConfirmationText", { name });
    this.openConfirmationDialog(title, text, () => this.deleteEntityClicked.emit(entity));
  }

  openConfirmationDialog(title: string, text: string, confirmAction: any): void {
    this.dialog.open(GemConfirmationDialogComponent, {
      data: {
        title,
        text,
        confirmAction,
      },
    });
  }

  restartWebSocket(entity: Endpoint) {
    this.restartWebSocketEvent.emit(entity);
  }
}
