import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromOrganisationalProfile from "../reducers/organisation-profile.reducer";

export const getOrganisationalProfileState =
  createFeatureSelector<fromOrganisationalProfile.OrganisationProfileState>(
    fromOrganisationalProfile.FEATURE_KEY,
  );

export const getOrganisationalProfileOrganisationsEntities = createSelector(
  getOrganisationalProfileState,
  fromOrganisationalProfile.getOrganisationsEntities,
);

export const getOrganisationalProfileOrganisations = createSelector(
  getOrganisationalProfileOrganisationsEntities,
  (entities) => {
    return Object.keys(entities).map((id) => entities[id]);
  },
);

export const getOrganisationalProfileOrganisation = (id: string) =>
  createSelector(getOrganisationalProfileOrganisationsEntities, (entities) => {
    return entities[id];
  });

export const getOrganisationalProfileOrganisationRolesEntities = (orgId: string) =>
  createSelector(
    getOrganisationalProfileState,
    (state) => state.roles.organisation[orgId]?.entities,
  );

export const getOrganisationalProfileOrganisationRoles = (orgId: string) =>
  createSelector(getOrganisationalProfileOrganisationRolesEntities(orgId), (entities) => {
    if (entities) {
      return Object.keys(entities).map((id) => entities[id]);
    }
  });

export const getOrganisationalProfileOrganisationRoleAddressesEntities = (id: string) =>
  createSelector(getOrganisationalProfileState, (state) => {
    return state.addresses.role[id]?.entities;
  });

export const getOrganisationalProfileOrganisationRoleAddresses = (
  orgId: string,
  orgRoleId: string,
) =>
  createSelector(
    getOrganisationalProfileOrganisationRoleAddressesEntities(orgId + ":" + orgRoleId),
    (entities) => {
      if (entities) {
        return Object.keys(entities).map((id) => entities[id]);
      }
    },
  );

export const getOrganisationalProfileOrganisationRoleAddress = (
  orgId: string,
  orgRoleId: string,
  addressId: string,
) =>
  createSelector(
    getOrganisationalProfileOrganisationRoleAddressesEntities(orgId + ":" + orgRoleId),
    (entities) => {
      if (entities) {
        return entities[addressId];
      }
    },
  );
