<!-- <mat-card class="sticky"> -->
<mat-card appearance="outlined" style="margin-top: 15px">
  <mat-card-content>
    <form [formGroup]="entityForm">
      <div class="row">
        <div class="col-sm-12">
          <gem-input [placeholder]="'help_key'" [control]="entityForm.get('helpkey')"></gem-input>
          <gem-input [placeholder]="'help_lang'" [control]="entityForm.get('lang')" (focusout)="langToLowerCase()"></gem-input>
          <gem-input [placeholder]="'help_title'" [control]="entityForm.get('title')"></gem-input>
        </div>
        <div class="col-sm-11">
          <gem-input [placeholder]="'help_html'" [control]="entityForm.get('html')"></gem-input>
        </div>
        <div class="col-sm-1">
          <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="openHtmlEditor()">edit</i>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{ "CANCEL" | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="createEditEntity()">{{ (entityForm.get("id").value ? "save" : "ADD") | translate }}</button>
  </mat-card-footer>
</mat-card>
