<div class="d-flex flex-wrap">
  <gem-filter
    [title]="'buid' | translate"
    [searchLabel]="'buidSearch' | translate"
    [options]="buidFilterOptions$ | async"
    [value]="getSelectedFilters('buid')"
    [reset]="reset"
    (change)="onFilterChange($event, 'buid')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'partner' | translate"
    [searchLabel]="'partnerSearch' | translate"
    [options]="partnerFilterOptions$ | async"
    [value]="getSelectedFilters('partner')"
    [reset]="reset"
    (change)="onFilterChange($event, 'partner')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'partnerType' | translate"
    [searchLabel]="'partnerTypeSearch' | translate"
    [options]="partnerTypeFilterOptions$ | async"
    [value]="getSelectedFilters('partnerType')"
    [reset]="reset"
    (change)="onFilterChange($event, 'partnerType')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'partnerRole' | translate"
    [searchLabel]="'partnerRoleSearch' | translate"
    [options]="partnerRoleFilterOptions$ | async"
    [value]="getSelectedFilters('role')"
    [reset]="reset"
    (change)="onFilterChange($event, 'role')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'processName' | translate"
    [searchLabel]="'processNameSearch' | translate"
    [options]="processNameFilterOptions$ | async"
    [value]="getSelectedFilters('processName')"
    [reset]="reset"
    (change)="onFilterChange($event, 'processName')"
    class="mt-1 me-1"
  ></gem-filter>
  <gem-filter
    [title]="'taskName' | translate"
    [searchLabel]="'taskNameSearch' | translate"
    [options]="taskNameFilterOptions$ | async"
    [value]="getSelectedFilters('name')"
    [reset]="reset"
    (change)="onFilterChange($event, 'name')"
    class="mt-1 me-1"
  ></gem-filter>
</div>

<div class="md-chips d-flex flex-wrap">
  <div *ngFor="let selectedFilter of selectedFilters | keyvalue" class="d-flex flex-wrap">
    <div *ngFor="let sf of selectedFilter.value">
      <button
        *ngIf="sf.label"
        class="chip mt-1 me-1"
        (click)="onRemoveFilter(selectedFilter.key, sf)"
      >
        {{ sf.label | translate }}
      </button>
    </div>
  </div>
</div>

<div class="mat-table-responsive-container w-100">
  <table
    mat-table
    matSort
    multiTemplateDataRows
    [dataSource]="dataSource"
    (matSortChange)="onMatSortChange($event)"
    [ngStyle]="{
      'background-color': handsetBreakpointMatched ? 'inherit' : 'var(--mat-table-background-color)'
    }"
  >
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-icon
          mat-list-icon
          class="chevron-button"
          [ngClass]="{ rotated: expandedElement === row }"
          >chevron_right
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="buid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "buid" | translate }}</th>
      <td
        mat-cell
        mat-cell
        *matCellDef="let row"
        [attr.data-label]="'buid' | translate"
        [innerHTML]="row.buid | highlightSearch : filterText"
      ></td>
    </ng-container>

    <ng-container matColumnDef="partner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partner" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [attr.data-label]="'partner' | translate"
        [innerHTML]="row.partner | highlightSearch : filterText"
      ></td>
    </ng-container>

    <ng-container matColumnDef="partnerType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partnerType" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [attr.data-label]="'partnerType' | translate"
        [innerHTML]="row.partnerType | highlightSearch : filterText"
      ></td>
    </ng-container>

    <ng-container matColumnDef="taskRole">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "processOwner" | translate }}
      </th>
      <td mat-cell *matCellDef="let row" [attr.data-label]="'processOwner' | translate">
        {{ row.owner }}
      </td>
    </ng-container>

    <ng-container matColumnDef="processName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "processName" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [attr.data-label]="'processName' | translate"
        [innerHTML]="row.processName | translate | highlightSearch : filterText"
      ></td>
    </ng-container>

    <ng-container matColumnDef="taskName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "taskName" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [attr.data-label]="'taskName' | translate"
        [innerHTML]="row.name | translate | highlightSearch : filterText"
      ></td>
    </ng-container>

    <ng-container matColumnDef="createdTimeStamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "createdTimeStamp" | translate }}
      </th>
      <td mat-cell *matCellDef="let row" [attr.data-label]="'createdTimeStamp' | translate">
        {{ row.created | date : "dd.MM.YYYY HH:mm" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="justify-content-center">
        {{ "actions" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [attr.data-label]="'actions' | translate"
        class="text-center"
      >
        <mat-icon
          *ngIf="isUserInternal(row)"
          svgIcon="gem-open-in-new"
          [title]="'caseLogs' | translate"
          (click)="onViewCaseLogs(row)"
        ></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
        [ngStyle]="{
          'border-bottom-width': handsetBreakpointMatched
            ? 'inherit'
            : 'var(--mat-table-row-item-outline-width, 1px)'
        }"
      >
        <div
          class="custom-info-details"
          [@detailExpand]="
            element == expandedElement && !handsetBreakpointMatched ? 'expanded' : 'collapsed'
          "
        >
          <div class="custom-info-details-data" style="width: 100%">
            <div *ngIf="!tabletBreakpointMatched">
              <div class="row">
                <div class="col-sm-3 custom-info-details-data__label">Informationen:</div>
                <div class="col-sm-9">
                  <div *ngIf="element.customTaskContentOne">
                    <strong>{{ element.customTaskContentOneLabel | translate }}: </strong>
                    <span
                      [innerHTML]="
                        domSanitizer.bypassSecurityTrustHtml(element.customTaskContentOne)
                      "
                    ></span>
                  </div>
                  <div *ngIf="element.customTaskContentTwo">
                    <strong>{{ element.customTaskContentTwoLabel | translate }}: </strong>
                    <span
                      [innerHTML]="
                        domSanitizer.bypassSecurityTrustHtml(element.customTaskContentTwo)
                      "
                    ></span>
                  </div>
                  <div *ngIf="element.customTaskContentThree">
                    <strong>{{ element.customTaskContentThreeLabel | translate }}: </strong>
                    <span
                      [innerHTML]="
                        domSanitizer.bypassSecurityTrustHtml(element.customTaskContentThree)
                      "
                    ></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3 custom-info-details-data__label">
                  {{ "completedtimeStamp" | translate }}:
                </div>
                <div class="col-sm-9">
                  {{ element.completed | date : "dd.MM.YYYY HH:mm" }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3 custom-info-details-data__label">
                  {{ "taskOutcome" | translate }}:
                </div>
                <div class="col-sm-9">
                  {{ element.outcome }}
                </div>
              </div>
            </div>
            <div *ngIf="tabletBreakpointMatched">
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "partner" | translate }}:</strong>
                  </div>
                  <div>{{ element.partner }}</div>
                </div>
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "partnerType" | translate }}:</strong>
                  </div>
                  <div>{{ element.partnerType }}</div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "partnerRole" | translate }}:</strong>
                  </div>
                  <div>{{ element.role }}</div>
                </div>
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "processName" | translate }}:</strong>
                  </div>
                  <div>{{ element.processName | translate }}</div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-6">
                  <div>
                    <strong>{{ "taskName" | translate }}:</strong>
                  </div>
                  <div>{{ element.name | translate }}</div>
                </div>
                <div *ngIf="element.customTaskContentOne" class="col-sm-6">
                  <div>
                    <strong>{{ element.customTaskContentOneLabel | translate }}:</strong>
                  </div>
                  <div>{{ element.customTaskContentOne }}</div>
                </div>
              </div>
              <div class="row mt-2">
                <div *ngIf="element.customTaskContentTwo" class="col-sm-6">
                  <div>
                    <strong>{{ element.customTaskContentTwoLabel | translate }}:</strong>
                  </div>
                  <div>{{ element.customTaskContentTwo }}</div>
                </div>
                <div *ngIf="element.customTaskContentThree" class="col-sm-6">
                  <div>
                    <strong>{{ element.customTaskContentThreeLabel | translate }}:</strong>
                  </div>
                  <div>{{ element.customTaskContentThree }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="handset">
      <th mat-header-cell *matHeaderCellDef>Handset</th>
      <div mat-cell *matCellDef="let row">
        <div
          style="
            border-radius: 15px 15px 0px 0px;
            background: var(--gematik-blue);
            border-color: var(--gematik-blue);
            border-style: solid;
            border-width: 1px;
            padding: 20px;
            width: 100%;
            height: 100%;
          "
        >
          <div style="color: white">
            <div>
              <strong>{{ "buid" | translate }}: {{ row.buid }}</strong>
            </div>
            <div style="font-size: 0.8em">
              {{ "createdTimeStamp" | translate }}:
              {{ row.created | date : "dd.MM.YYYY HH:mm" }}
            </div>
          </div>
        </div>
        <div
          style="
            border-radius: 0px 0px 0px 0px;
            border-color: var(--gematik-blue);
            border-style: solid;
            border-width: 1px;
            background: white;
            padding: 20px;
            width: 100%;
            height: 100%;
          "
        >
          <div>
            <div>
              <div class="text-muted" style="font-size: 0.8em">{{ "partner" | translate }}:</div>
              <div style="font-size: 0.8em">{{ row.partner }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ "partnerType" | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.partnerType }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ "partnerRole" | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ partnerRole }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ "processName" | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.processName | translate }}</div>
            </div>
            <div class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">{{ "taskName" | translate }}:</div>
              <div style="font-size: 0.8em">{{ row.name | translate }}</div>
            </div>
            <div *ngIf="row.customTaskContentOne" class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ row.customTaskContentOneLabel | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.customTaskContentOne }}</div>
            </div>
            <div *ngIf="row.customTaskContentTwo" class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ row.customTaskContentTwoLabel | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.customTaskContentTwo }}</div>
            </div>
            <div *ngIf="row.customTaskContentThree" class="mt-2">
              <div class="text-muted" style="font-size: 0.8em">
                {{ row.customTaskContentThreeLabel | translate }}:
              </div>
              <div style="font-size: 0.8em">{{ row.customTaskContentThree }}</div>
            </div>
          </div>
          <div class="mt-3">
            <h4>{{ "actions" | translate }}</h4>
            <div *ngIf="type === 'my'" class="d-flex" (click)="onUnclaim(row)">
              <mat-icon svgIcon="unclaim"></mat-icon>
              {{ "portal.tasks.buttons.unclaim.label" | translate }}
            </div>
            <!-- <div class="d-flex" (click)="onTimeline(row)">
              <mat-icon svgIcon="gem-timeline" class="ms-1"></mat-icon>
              {{ "portal.tasks.buttons.timeline.label" | translate }}
            </div> -->
            <div *ngIf="isUserInternal(row)" class="d-flex" (click)="onViewCaseLogs(row)">
              <mat-icon
                class="ms-1"
                svgIcon="gem-open-in-new"
                [title]="'caseLogs' | translate"
              ></mat-icon>
              {{ "portal.tasks.buttons.caseLogs.label" | translate }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      [hidden]="handsetBreakpointMatched"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.custom-info-detail-row-selected]="expandedElement === row"
      style="cursor: pointer"
      (click)="expandedElement = expandedElement === row ? null : row"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="custom-info-detail-row"
      [ngStyle]="{ height: handsetBreakpointMatched ? '20px' : '0px' }"
    ></tr>
  </table>
  <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
</div>
