import { Action } from "@ngrx/store";
import {
  SuiteCrmPartner,
  SuiteCrmPartnerAddress,
  SuiteCrmPartnerAddressMail,
  SuiteCrmPartnerAddressPhone,
  SuiteCrmPartnerRole,
} from "gematik-shared";

// Load Organisations
export const LOAD_ORGANISATIONS = "[Organisational Profile] Load Organisations";
export const LOAD_ORGANISATIONS_SUCCESS = "[Organisational Profile] Load Organisations Success";
export const LOAD_ORGANISATIONS_FAIL = "[Organisational Profile] Load Organisations Fail";

export class LoadOrganisations implements Action {
  readonly type = LOAD_ORGANISATIONS;
}

export class LoadOrganisationsSuccess implements Action {
  readonly type = LOAD_ORGANISATIONS_SUCCESS;
  constructor(public payload: SuiteCrmPartner[]) {}
}

export class LoadOrganisationsFail implements Action {
  readonly type = LOAD_ORGANISATIONS_FAIL;
  constructor(public payload: any) {}
}

// Update Organisation
export const UPDATE_ORGANISATION = "[Organisational Profile] Update Organisation";
export const UPDATE_ORGANISATION_SUCCESS = "[Organisational Profile] Update Organisation Success";
export const UPDATE_ORGANISATION_FAIL = "[Organisational Profile] Update Organisation Fail";

export class UpdateOrganisation implements Action {
  readonly type = UPDATE_ORGANISATION;
  constructor(public payload: SuiteCrmPartner) {}
}

export class UpdateOrganisationSuccess implements Action {
  readonly type = UPDATE_ORGANISATION_SUCCESS;
  constructor(public payload: SuiteCrmPartner) {}
}

export class UpdateOrganisationFail implements Action {
  readonly type = UPDATE_ORGANISATION_FAIL;
  constructor(public payload: any) {}
}

// Load Organisation Roles
export const LOAD_ORGANISATION_ROLES = "[Organisational Profile] Load Organisation Roles";
export const LOAD_ORGANISATION_ROLES_SUCCESS =
  "[Organisational Profile] Load Organisation Roles Success";
export const LOAD_ORGANISATION_ROLES_FAIL = "[Organisational Profile] Load Organisation Roles Fail";

export class LoadOrganisationRoles implements Action {
  readonly type = LOAD_ORGANISATION_ROLES;
  constructor(public orgId: string) {}
}

export class LoadOrganisationRolesSuccess implements Action {
  readonly type = LOAD_ORGANISATION_ROLES_SUCCESS;
  constructor(public payload: SuiteCrmPartnerRole[], public orgId: string) {}
}

export class LoadOrganisationRolesFail implements Action {
  readonly type = LOAD_ORGANISATION_ROLES_FAIL;
  constructor(public payload: any) {}
}

// Load Organisation Role Addresses
export const LOAD_ORGANISATION_ROLE_ADDRESSES =
  "[Organisational Profile] Load Organisation Role Addresses";
export const LOAD_ORGANISATION_ROLE_ADDRESSES_SUCCESS =
  "[Organisational Profile] Load Organisation Role Addresses Success";
export const LOAD_ORGANISATION_ROLE_ADDRESSES_FAIL =
  "[Organisational Profile] Load Organisation Role Addresses Fail";

export class LoadOrganisationRoleAddresses implements Action {
  readonly type = LOAD_ORGANISATION_ROLE_ADDRESSES;
  constructor(public orgId: string, public orgRoleId: string) {}
}

export class LoadOrganisationRoleAddressesSuccess implements Action {
  readonly type = LOAD_ORGANISATION_ROLE_ADDRESSES_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddress[],
    public orgId: string,
    public orgRoleId: string,
  ) {}
}

export class LoadOrganisationRoleAddressesFail implements Action {
  readonly type = LOAD_ORGANISATION_ROLE_ADDRESSES_FAIL;
  constructor(public payload: any) {}
}

// Create Organisation Role Address
export const CREATE_ORGANISATION_ROLE_ADDRESS =
  "[Organisational Profile] Create Organisation Role Address";
export const CREATE_ORGANISATION_ROLE_ADDRESS_SUCCESS =
  "[Organisational Profile] Create Organisation Role Address Success";
export const CREATE_ORGANISATION_ROLE_ADDRESS_FAIL =
  "[Organisational Profile] Create Organisation Role Address Fail";

export class CreateOrganisationRoleAddress implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS;
  constructor(
    public payload: SuiteCrmPartnerAddress,
    public orgId: string,
    public orgRoleId: string,
  ) {}
}

export class CreateOrganisationRoleAddressSuccess implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddress,
    public orgId: string,
    public orgRoleId: string,
  ) {}
}

export class CreateOrganisationRoleAddressFail implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_FAIL;
  constructor(public payload: any) {}
}

// Update Organisation Role Address
export const UPDATE_ORGANISATION_ROLE_ADDRESS =
  "[Organisational Profile] Update Organisation Role Address";
export const UPDATE_ORGANISATION_ROLE_ADDRESS_SUCCESS =
  "[Organisational Profile] Update Organisation Role Address Success";
export const UPDATE_ORGANISATION_ROLE_ADDRESS_FAIL =
  "[Organisational Profile] Update Organisation Role Address Fail";

export class UpdateOrganisationRoleAddress implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS;
  constructor(
    public payload: SuiteCrmPartnerAddress,
    public orgId: string,
    public orgRoleId: string,
  ) {}
}

export class UpdateOrganisationRoleAddressSuccess implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddress,
    public orgId: string,
    public orgRoleId: string,
  ) {}
}

export class UpdateOrganisationRoleAddressFail implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_FAIL;
  constructor(public payload: any) {}
}

// Delete Organisation Role Address
export const DELETE_ORGANISATION_ROLE_ADDRESS =
  "[Organisational Profile] Delete Organisation Role Address";
export const DELETE_ORGANISATION_ROLE_ADDRESS_SUCCESS =
  "[Organisational Profile] Delete Organisation Role Address Success";
export const DELETE_ORGANISATION_ROLE_ADDRESS_FAIL =
  "[Organisational Profile] Delete Organisation Role Address Fail";

export class DeleteOrganisationRoleAddress implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS;
  constructor(public orgId: string, public orgRoleId: string, public addressId: string) {}
}

export class DeleteOrganisationRoleAddressSuccess implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_SUCCESS;
  constructor(public orgId: string, public orgRoleId: string, public addressId: string) {}
}

export class DeleteOrganisationRoleAddressFail implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_FAIL;
  constructor(public payload: any) {}
}

// Create Organisation Role Address Mail
export const CREATE_ORGANISATION_ROLE_ADDRESS_MAIL =
  "[Organisational Profile] Create Organisation Role Address Mail";
export const CREATE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS =
  "[Organisational Profile] Create Organisation Role Address Mail Success";
export const CREATE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL =
  "[Organisational Profile] Create Organisation Role Address Mail Fail";

export class CreateOrganisationRoleAddressMail implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_MAIL;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
  ) {}
}

export class CreateOrganisationRoleAddressMailSuccess implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
  ) {}
}

export class CreateOrganisationRoleAddressMailFail implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL;
  constructor(public payload: any) {}
}

// Update Organisation Role Address Mail
export const UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL =
  "[Organisational Profile] Update Organisation Role Address Mail";
export const UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS =
  "[Organisational Profile] Update Organisation Role Address Mail Success";
export const UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL =
  "[Organisational Profile] Update Organisation Role Address Mail Fail";

export class UpdateOrganisationRoleAddressMail implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public mailId: string,
  ) {}
}

export class UpdateOrganisationRoleAddressMailSuccess implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressMail,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public mailId: string,
  ) {}
}

export class UpdateOrganisationRoleAddressMailFail implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL;
  constructor(public payload: any) {}
}

// Delete Organisation Role Address Mail
export const DELETE_ORGANISATION_ROLE_ADDRESS_MAIL =
  "[Organisational Profile] Delete Organisation Role Address Mail";
export const DELETE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS =
  "[Organisational Profile] Delete Organisation Role Address Mail Success";
export const DELETE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL =
  "[Organisational Profile] Delete Organisation Role Address Mail Fail";

export class DeleteOrganisationRoleAddressMail implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_MAIL;
  constructor(
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public mailId: string,
  ) {}
}

export class DeleteOrganisationRoleAddressMailSuccess implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_MAIL_SUCCESS;
  constructor(
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public mailId: string,
  ) {}
}

export class DeleteOrganisationRoleAddressMailFail implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_MAIL_FAIL;
  constructor(public payload: any) {}
}

// Create Organisation Role Address Phone
export const CREATE_ORGANISATION_ROLE_ADDRESS_PHONE =
  "[Organisational Profile] Create Organisation Role Address Phone";
export const CREATE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS =
  "[Organisational Profile] Create Organisation Role Address Phone Success";
export const CREATE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL =
  "[Organisational Profile] Create Organisation Role Address Phone Fail";

export class CreateOrganisationRoleAddressPhone implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_PHONE;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
  ) {}
}

export class CreateOrganisationRoleAddressPhoneSuccess implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
  ) {}
}

export class CreateOrganisationRoleAddressPhoneFail implements Action {
  readonly type = CREATE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL;
  constructor(public payload: any) {}
}

// Update Organisation Role Address Phone
export const UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE =
  "[Organisational Profile] Update Organisation Role Address Phone";
export const UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS =
  "[Organisational Profile] Update Organisation Role Address Phone Success";
export const UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL =
  "[Organisational Profile] Update Organisation Role Address Phone Fail";

export class UpdateOrganisationRoleAddressPhone implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public phoneId: string,
  ) {}
}

export class UpdateOrganisationRoleAddressPhoneSuccess implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS;
  constructor(
    public payload: SuiteCrmPartnerAddressPhone,
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public phoneId: string,
  ) {}
}

export class UpdateOrganisationRoleAddressPhoneFail implements Action {
  readonly type = UPDATE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL;
  constructor(public payload: any) {}
}

// Delete Organisation Role Address Phone
export const DELETE_ORGANISATION_ROLE_ADDRESS_PHONE =
  "[Organisational Profile] Delete Organisation Role Address Phone";
export const DELETE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS =
  "[Organisational Profile] Delete Organisation Role Address Phone Success";
export const DELETE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL =
  "[Organisational Profile] Delete Organisation Role Address Phone Fail";

export class DeleteOrganisationRoleAddressPhone implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_PHONE;
  constructor(
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public phoneId: string,
  ) {}
}

export class DeleteOrganisationRoleAddressPhoneSuccess implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_PHONE_SUCCESS;
  constructor(
    public orgId: string,
    public orgRoleId: string,
    public addressId: string,
    public phoneId: string,
  ) {}
}

export class DeleteOrganisationRoleAddressPhoneFail implements Action {
  readonly type = DELETE_ORGANISATION_ROLE_ADDRESS_PHONE_FAIL;
  constructor(public payload: any) {}
}

export type OrganisationProfileActions =
  | LoadOrganisations
  | LoadOrganisationsSuccess
  | LoadOrganisationsFail
  | UpdateOrganisation
  | UpdateOrganisationSuccess
  | UpdateOrganisationFail
  | LoadOrganisationRoles
  | LoadOrganisationRolesSuccess
  | LoadOrganisationRolesFail
  | LoadOrganisationRoleAddresses
  | LoadOrganisationRoleAddressesSuccess
  | LoadOrganisationRoleAddressesFail
  | DeleteOrganisationRoleAddress
  | DeleteOrganisationRoleAddressSuccess
  | DeleteOrganisationRoleAddressFail
  | CreateOrganisationRoleAddress
  | CreateOrganisationRoleAddressSuccess
  | CreateOrganisationRoleAddressFail
  | UpdateOrganisationRoleAddress
  | UpdateOrganisationRoleAddressSuccess
  | UpdateOrganisationRoleAddressFail
  | CreateOrganisationRoleAddressMail
  | CreateOrganisationRoleAddressMailSuccess
  | CreateOrganisationRoleAddressMailFail
  | UpdateOrganisationRoleAddressMail
  | UpdateOrganisationRoleAddressMailSuccess
  | UpdateOrganisationRoleAddressMailFail
  | DeleteOrganisationRoleAddressMail
  | DeleteOrganisationRoleAddressMailSuccess
  | DeleteOrganisationRoleAddressMailFail
  | CreateOrganisationRoleAddressPhone
  | CreateOrganisationRoleAddressPhoneSuccess
  | CreateOrganisationRoleAddressPhoneFail
  | UpdateOrganisationRoleAddressPhone
  | UpdateOrganisationRoleAddressPhoneSuccess
  | UpdateOrganisationRoleAddressPhoneFail
  | DeleteOrganisationRoleAddressPhone
  | DeleteOrganisationRoleAddressPhoneSuccess
  | DeleteOrganisationRoleAddressPhoneFail;
