import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromMyService from "../reducers/my-services.reducer";

export const getMyServicesState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.myServices,
);

// My Services
export const getMyServicesEntities = createSelector(
  getMyServicesState,
  fromMyService.getMyServicesEntities,
);

export const getMyEnabledServices = createSelector(getMyServicesEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .filter((myService) => !myService.disabled)
    .sort((a, b) => a.index - b.index);
});

export const getMyServices = createSelector(getMyServicesEntities, (entities) => {
  return Object.keys(entities)
    .map((id) => entities[id])
    .sort((a, b) => a.index - b.index);
});
