import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromProcessTotals from "../reducers/process-totals.reducer";

export const getProcessTotalsState = createFeatureSelector<fromProcessTotals.ProcessTotalsState>(
  fromProcessTotals.FEATURE_KEY,
);

export const getProcessTotals = createSelector(
  getProcessTotalsState,
  fromProcessTotals.getProcessTotals,
);

export const getMyProcessTotals = createSelector(getProcessTotals, (totals) => {
  return {
    open: totals.open.my,
    due: totals.due.my,
    completed: totals.completed.my,
  };
});

export const getObservableProcessTotals = createSelector(getProcessTotals, (totals) => {
  return {
    open: totals.open.group,
    due: totals.due.group,
    completed: totals.completed.group,
  };
});
