import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from "@angular/forms";
import { FormHelperService } from "gematik-form-library";
import { ITemplate } from "../../../services";

export enum TemplateStateType {
  "CREATE" = "CREATE",
  "UPDATE" = "UPDATE",
  "DELETE" = "DELETE",
}

@Component({
  selector: "templates-form",
  templateUrl: "./templates-form.component.html",
  styleUrls: ["./templates-form.component.scss"],
})
export class TemplatesFormComponent implements OnInit {
  entityForm: UntypedFormGroup;
  state: TemplateStateType;

  @Output() entityCreatedEdited = new EventEmitter<{
    fg: UntypedFormGroup;
    state: TemplateStateType;
  }>();

  @Input() set entity(entity: ITemplate) {
    this.entityForm = this.createForm(entity);
  }

  constructor(private formHelper: FormHelperService, private fb: FormBuilder) {}

  ngOnInit() {}

  createForm(entity: ITemplate) {
    this.state = TemplateStateType.CREATE;
    const fg = this.mapTemplateToForm(entity) as FormGroup;
    if (fg.get("name").value) {
      this.state = TemplateStateType.UPDATE;
      fg.get("name").disable();
    }
    return fg;
  }

  createEditEntity() {
    this.formHelper.isFormValidElseShowErrors(this.entityForm, "createEntityErrorTitle", () => {
      this.entityCreatedEdited.emit({ fg: this.entityForm, state: this.state });
    });
  }

  cancel() {
    this.entityCreatedEdited.emit(null);
  }

  private mapTemplateToForm(template: ITemplate) {
    if (template !== null && template.name) {
      return this.fb.group({
        name: [{ value: template.name, disabled: true }, Validators.required],
        content: [template.content, Validators.required],
      });
    } else {
      return this.fb.group({
        name: ["", Validators.required],
        content: ["", Validators.required],
      });
    }
  }
}
