<div class="mt-3 ms-3 me-3">
  <div class="d-flex justify-content-end">
    <mat-icon
      class="d-block nav-icons"
      style="cursor: pointer"
      svgIcon="gem-close"
      (click)="cancelAction()"
    ></mat-icon>
  </div>
  <h1 mat-dialog-title class="text-start ps-0">
    <strong>{{ title }}</strong>
  </h1>
  <div class="row info-box p-2">
    <div class="col-sm-12">
      <strong>{{ "buid" | translate }} {{ data.task.buid }}</strong>
    </div>
    <div class="col-sm-12 col-md-4">
      <span class="text-muted"> {{ "partner" | translate }}: </span>{{ data.task.partner }}
    </div>
    <div class="col-sm-12 col-md-4">
      <span class="text-muted"> {{ "partnerType" | translate }}: </span>{{ data.task.partnerType }}
    </div>
    <div class="col-sm-12 col-md-4">
      <span class="text-muted"> {{ "partnerRole" | translate }}: </span>{{ data.task.role }}
    </div>
    <div class="col-sm-12 col-md-4">
      <span class="text-muted">
        {{
          data.task.customTaskContentOneLabel
            ? data.task.customTaskContentOneLabel
            : ("customContentOne" | translate)
        }}: </span
      >{{ data.task.customTaskContentOne }}
    </div>
    <div class="col-sm-12 col-md-4">
      <span class="text-muted">
        {{
          data.task.customTaskContentTwoLabel
            ? data.task.customTaskContentTwoLabel
            : ("customContentTwo" | translate)
        }}: </span
      >{{ data.task.customTaskContentTwo }}
    </div>
    <div class="col-sm-12 col-md-4">
      <span class="text-muted">
        {{
          data.task.customTaskContentThreeLabel
            ? data.task.customTaskContentThreeLabel
            : ("customContentThree" | translate)
        }}: </span
      >{{ data.task.customTaskContentThree }}
    </div>
  </div>
</div>
<div mat-dialog-content>
  <p class="fw-bold" style="color: initial" [innerHTML]="text"></p>
  <div #scroll class="scrollable-content">
    <p *ngIf="data.task.htmlContent" [innerHTML]="data.task.htmlContent"></p>
  </div>
  <div *ngIf="control">
    <br />
    <gem-textarea
      [placeholder]="data.task.inputFieldName | translate"
      [control]="control"
    ></gem-textarea>
    <br />
  </div>
  <br />
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary" (click)="approveAction()" [disabled]="hasFieldData()">
    {{ "APPROVE" | translate }}
  </button>
  <button class="btn btn-primary" (click)="rejectAction()" [disabled]="hasFieldData()">
    {{ "REJECT" | translate }}
  </button>
</div>
