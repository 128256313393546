import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import * as RouterActions from "../actions/router.actions";

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private router: Router) {}
  routerNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.ROUTER_NAVIGATE),
        tap((action) => {
          this.router.navigate([action["url"]]);
        }),
      ),
    { dispatch: false },
  );
}
