import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";

export interface RedisCacheLogDto {
  key: string;
  invocations: number;
  lastTimestampInSeconds: number;
  priority: number;
}

@Injectable({
  providedIn: "root",
})
export class RedisCacheService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getStatistics(): Observable<RedisCacheLogDto[]> {
    return this.http.get<RedisCacheLogDto[]>(this.env.backendUrl + "/redis/statistics", {});
  }

  clearCacheEntry(entry: string): Observable<any> {
    return this.http.post(`${this.env.backendUrl}/redis/clear/${entry}`, {});
  }

  clear(): Observable<any> {
    return this.http.post(`${this.env.backendUrl}/redis/clear`, {});
  }
}
