import { createSelector } from "@ngrx/store";

import * as fromFeature from "../reducers";
import * as fromQuickActions from "../reducers/quick-action.reducer";

export const getQuickActionsState = createSelector(
  fromFeature.getUwlStore,
  (state: fromFeature.UwlState) => state.quickActions,
);

export const getQuickActionsEntities = createSelector(
  getQuickActionsState,
  fromQuickActions.getQuickActionsEntities,
);

export const getQuickActions = createSelector(getQuickActionsEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
