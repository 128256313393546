import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TemplateStateType } from "../components/templates-form/templates-form.component";
import { Observable } from "rxjs";
import { ITemplate } from "../../services";

import { Store } from "@ngrx/store";
import * as fromStore from "../../store";
import { filter, switchMap, take, tap } from "rxjs/operators";

@Component({
  selector: "app-templates-page",
  templateUrl: "./templates-page.component.html",
  styleUrls: ["./templates-page.component.scss"],
})
export class TemplatesPageComponent implements OnInit {
  templates$: Observable<ITemplate[]>;
  selectedEntity: ITemplate;

  constructor(private store: Store<fromStore.UwlState>) {}

  ngOnInit(): void {
    this.templates$ = this.store.select(fromStore.getTemplatesLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(new fromStore.LoadTemplates());
        }
      }),
      filter((loaded) => loaded),
      take(1),
      switchMap(() => {
        return this.store.select(fromStore.getTemplates);
      }),
    );
  }

  createEditEntity(event) {
    if (event) {
      const entityForm: UntypedFormGroup = event.fg;
      const state: TemplateStateType = event.state;
      const entity = entityForm.getRawValue();
      if (state === TemplateStateType.CREATE) {
        this.store.dispatch(new fromStore.CreateTemplate(entity));
      } else if (state === TemplateStateType.UPDATE) {
        this.store.dispatch(new fromStore.UpdateTemplate(entity));
      }
      this.selectedEntity = null;
    } else {
      this.selectedEntity = null;
    }
  }

  deleteEntity(entity: ITemplate) {
    this.store.dispatch(new fromStore.DeleteTemplate(entity));
  }

  selectEntity(entity: ITemplate) {
    this.selectedEntity = entity;
  }

  addEntityClicked() {
    this.selectedEntity = {
      name: undefined,
      content: undefined,
    };
  }
}
