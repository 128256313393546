import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { SuiteCrmPartnerAddress } from "gematik-shared";

@Component({
  selector: "communication-addresses",
  styleUrls: ["communication-addresses.component.scss"],
  template: `
    <div class="row">
      <div class="col-sm-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>
                <div class="d-flex">
                  <mat-icon class="nav-icons me-1" svgIcon="gem-report"></mat-icon>
                  <strong> Addresses: {{ title }} </strong>
                </div>
              </mat-card-title>
              <mat-card-subtitle class="mt-2"> </mat-card-subtitle>
              <button data-testid="add" mat-mini-fab class="fab-button" (click)="onAddAddress()">
                <mat-icon>add</mat-icon>
              </button>
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content class="mt-4">
            <div class="col-sm-12">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Id</th>
                    <th scope="col">Scenario</th>
                    <th scope="col">Address</th>
                    <th scope="col">Street</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Postal Code</th>
                    <th scope="col">Country</th>
                    <th scope="col">Description</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  @for (address of addresses; track address.id; let i = $index) {
                  <tr>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{ address.id }}
                    </td>
                    <td>{{ address.scenario }}</td>
                    <td>{{ address.address }}</td>
                    <td>{{ address.street }}</td>
                    <td>{{ address.city }}</td>
                    <td>{{ address.state }}</td>
                    <td>{{ address.postalCode }}</td>
                    <td>{{ address.country }}</td>
                    <td>{{ address.description }}</td>
                    <td>
                      <span
                        data-testid="edit"
                        class="material-icons"
                        style="cursor: pointer;"
                        (click)="onEdit(address)"
                      >
                        edit
                      </span>
                    </td>
                    <td>
                      <span
                        data-testid="delete"
                        class="material-icons"
                        style="cursor: pointer;"
                        (click)="onDelete(address)"
                      >
                        delete
                      </span>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <router-outlet></router-outlet>
  `,
})
export class CommunicationAddressesComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() orgRoleId: string;
  @Input() addresses: SuiteCrmPartnerAddress[];

  @Output() delete: EventEmitter<SuiteCrmPartnerAddress> = new EventEmitter();

  orgId: string;

  subscriptions: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  onAddAddress(): void {
    if (this.orgRoleId) {
      this.router.navigate(["roles", this.orgRoleId, "addresses", "new"], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(["addresses", "new"], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  onEdit(address: SuiteCrmPartnerAddress): void {
    if (this.orgRoleId) {
      this.router.navigate(["roles", this.orgRoleId, "addresses", address.id], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(["addresses", address.id], { relativeTo: this.activatedRoute });
    }
  }

  onDelete(address: SuiteCrmPartnerAddress): void {
    if (confirm("Are you sure to delete this address?")) {
      this.delete.emit(address);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
