import { Action } from "@ngrx/store";
import { Help } from "../../services/uwl/help.service";

// Load Help
export const LOAD_HELP = "[Help] Load Help";
export const LOAD_HELP_SUCCESS = "[Help] Load Help Success";
export const LOAD_HELP_FAIL = "[Help] Load Help Fail";

export class LoadHelp implements Action {
  readonly type = LOAD_HELP;
}

export class LoadHelpSuccess implements Action {
  readonly type = LOAD_HELP_SUCCESS;
  constructor(public payload: Help[]) {}
}

export class LoadHelpFail implements Action {
  readonly type = LOAD_HELP_FAIL;
  constructor(public payload: any) {}
}

// Create Help
export const CREATE_HELP = "[Help] Create Help";
export const CREATE_HELP_SUCCESS = "[Help] Create Help Success";
export const CREATE_HELP_FAIL = "[Help] Create Help Fail";

export class CreateHelp implements Action {
  readonly type = CREATE_HELP;
  constructor(public payload: Help) {}
}

export class CreateHelpSuccess implements Action {
  readonly type = CREATE_HELP_SUCCESS;
  constructor(public payload: Help) {}
}

export class CreateHelpFail implements Action {
  readonly type = CREATE_HELP_FAIL;
  constructor(public payload: any) {}
}

// Update Help
export const UPDATE_HELP = "[Help] Update Help";
export const UPDATE_HELP_SUCCESS = "[Help] Update Help Success";
export const UPDATE_HELP_FAIL = "[Help] Update Help Fail";

export class UpdateHelp implements Action {
  readonly type = UPDATE_HELP;
  constructor(public payload: Help) {}
}

export class UpdateHelpSuccess implements Action {
  readonly type = UPDATE_HELP_SUCCESS;
  constructor(public payload: Help) {}
}

export class UpdateHelpFail implements Action {
  readonly type = UPDATE_HELP_FAIL;
  constructor(public payload: any) {}
}

// Delete Help
export const DELETE_HELP = "[Help] Delete Help";
export const DELETE_HELP_SUCCESS = "[Help] Delete Help Success";
export const DELETE_HELP_FAIL = "[Help] Delete Help Fail";

export class DeleteHelp implements Action {
  readonly type = DELETE_HELP;
  constructor(public payload: Help) {}
}

export class DeleteHelpSuccess implements Action {
  readonly type = DELETE_HELP_SUCCESS;
  constructor(public payload: Help) {}
}

export class DeleteHelpFail implements Action {
  readonly type = DELETE_HELP_FAIL;
  constructor(public payload: any) {}
}

export type HelpActions =
  | LoadHelp
  | LoadHelpSuccess
  | LoadHelpFail
  | CreateHelp
  | CreateHelpSuccess
  | CreateHelpFail
  | UpdateHelp
  | UpdateHelpSuccess
  | UpdateHelpFail
  | DeleteHelp
  | DeleteHelpSuccess
  | DeleteHelpFail;
