import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromEndpoints from "../reducers/endpoint.reducer";

export const getEndpointsState = createFeatureSelector<fromEndpoints.EndpointState>(
  fromEndpoints.FEATURE_KEY,
);

export const getEndpointEntities = createSelector(
  getEndpointsState,
  fromEndpoints.getEndpointEntities,
);

export const getEndpointCount = createSelector(
  getEndpointEntities,
  (entities) => Object.keys(entities).length,
);

export const getEndpoints = createSelector(getEndpointEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
