import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as taskActions from "../actions/task.actions";
import * as fromServices from "../../services";

@Injectable()
export class TaskEffects {
  constructor(private actions$: Actions, private taskService: fromServices.TaskService) {}

  loadAllOpenTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskActions.LOAD_ALL_OPEN_TASKS),
      switchMap((payload: any) => {
        return this.taskService.getAllOpen().pipe(
          map((response) => new taskActions.LoadAllOpenTasksSuccess(response)),
          catchError((error) => of(new taskActions.LoadAllOpenTasksFail(error))),
        );
      }),
    );
  });

  loadOpenTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskActions.LOAD_OPEN_TASKS),
      map((action: taskActions.LoadOpenTasks) => action.payload),
      switchMap((backendUrl) => {
        return this.taskService.getOpen(backendUrl).pipe(
          map((response) => new taskActions.LoadOpenTasksSuccess(response, backendUrl)),
          catchError((error) => of(new taskActions.LoadOpenTasksFail(error))),
        );
      }),
    );
  });

  loadMyCompletedTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskActions.LOAD_MY_COMPLETED_TASKS),
      switchMap((payload: any) => {
        return this.taskService.getAllMyCompleted().pipe(
          map((response) => new taskActions.LoadMyCompletedTasksSuccess(response)),
          catchError((error) => of(new taskActions.LoadMyCompletedTasksFail(error))),
        );
      }),
    );
  });

  loadGroupCompletedTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskActions.LOAD_GROUP_COMPLETED_TASKS),
      switchMap((payload: any) => {
        return this.taskService.getAllGroupCompleted().pipe(
          map((response) => new taskActions.LoadGroupCompletedTasksSuccess(response)),
          catchError((error) => of(new taskActions.LoadGroupCompletedTasksFail(error))),
        );
      }),
    );
  });

  loadTaskTotals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskActions.LOAD_TASK_TOTALS),
      switchMap((payload: any) => {
        return this.taskService.getTaskTotals().pipe(
          map((response) => new taskActions.LoadTaskTotalsSuccess(response)),
          catchError((error) => of(new taskActions.LoadTaskTotalsFail(error))),
        );
      }),
    );
  });
}
