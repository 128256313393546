import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromProfile from "../reducers/profile.reducer";

export const getProfileState = createFeatureSelector<fromProfile.ProfileState>(
  fromProfile.FEATURE_KEY,
);

export const isPartnerManager = createSelector(
  getProfileState,
  (state) => state.profile?.isPartnerManager,
);

export const getProfile = createSelector(getProfileState, fromProfile.getProfile);
export const getProfileLoaded = createSelector(getProfileState, fromProfile.getProfileLoaded);
