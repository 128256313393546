<h1 mat-dialog-title>
  <b>
    {{ "incident" | translate }}
  </b>
  {{ data.dto?.processInstanceId }}
</h1>
<h1 mat-dialog-title></h1>
<div class="row">
  <div class="col-sm-12 center">
    <gem-bpmn-diagram [diagram]="data?.diagram" [isIncident]="true"></gem-bpmn-diagram>
  </div>
</div>
<div class="row"><div class="col-sm-12 center">&nbsp;</div></div>
<div class="row">
  <div class="col-sm-12 center">
    <h1 mat-dialog-title>
      {{ data.dto?.incidentMessage }}
    </h1>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 left">
    <img class="bpmn-icon-legend" src="/assets/images/bpmn/bpmn-activity.png" />
    {{ "current_process_step" | translate }}
  </div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-primary" (click)="confirmAction()" cdkFocusInitial>Ok</button>
</div>
