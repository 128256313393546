import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as endpointActions from "../actions/endpoint.actions";
import * as fromServices from "../../services";

@Injectable()
export class EndpointEffects {
  constructor(private actions$: Actions, private endpointService: fromServices.UwlService) {}

  loadEndpoints$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(endpointActions.LOAD_ENDPOINTS),
      switchMap((payload: any) => {
        return this.endpointService.findAllEndpoints().pipe(
          map((response) => new endpointActions.LoadEndpointsSuccess(response.body)),
          catchError((error) => of(new endpointActions.LoadEndpointsFail(error))),
        );
      }),
    );
  });

  createEndpoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(endpointActions.CREATE_ENDPOINT),
      map((action: endpointActions.CreateEndpoint) => action.payload),
      switchMap((endpoint) => {
        return this.endpointService.createUpdateEndpoint(endpoint).pipe(
          map((response) => new endpointActions.CreateEndpointSuccess(response.body)),
          catchError((error) => of(new endpointActions.CreateEndpointFail(error))),
        );
      }),
    );
  });

  updateEndpoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(endpointActions.UPDATE_ENDPOINT),
      map((action: endpointActions.UpdateEndpoint) => action.payload),
      switchMap((endpoint) => {
        return this.endpointService.createUpdateEndpoint(endpoint).pipe(
          map((response) => new endpointActions.UpdateEndpointSuccess(response.body)),
          catchError((error) => of(new endpointActions.UpdateEndpointFail(error))),
        );
      }),
    );
  });

  deleteEndpoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(endpointActions.DELETE_ENDPOINT),
      map((action: endpointActions.DeleteEndpoint) => action.payload),
      switchMap((endpoint) => {
        return this.endpointService.deleteEndpoint(endpoint.id).pipe(
          map((response) => new endpointActions.DeleteEndpointSuccess(endpoint)),
          catchError((error) => of(new endpointActions.DeleteEndpointFail(error))),
        );
      }),
    );
  });
}
