import { Action } from "@ngrx/store";
import { QuickAction } from "gematik-shared";

// Load Quick Actions
export const LOAD_QUICK_ACTIONS = "[Quick Actions] Load Quick Actions";
export const LOAD_QUICK_ACTIONS_SUCCESS = "[Quick Actions] Load Quick Actions Success";
export const LOAD_QUICK_ACTIONS_FAIL = "[Quick Actions] Load Quick Actions Fail";

export class LoadQuickActions implements Action {
  readonly type = LOAD_QUICK_ACTIONS;
}

export class LoadQuickActionsSuccess implements Action {
  readonly type = LOAD_QUICK_ACTIONS_SUCCESS;
  constructor(public payload: QuickAction[]) {}
}

export class LoadQuickActionsFail implements Action {
  readonly type = LOAD_QUICK_ACTIONS_FAIL;
  constructor(public payload: any) {}
}

export type QuickActionsActions = LoadQuickActions | LoadQuickActionsSuccess | LoadQuickActionsFail;
