<h1 mat-dialog-title>HTML Editor</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 center">
      <angular-editor [(ngModel)]="data.htmlContent" [config]="editorConfig"></angular-editor>
    </div>
  </div>
  <div class="row"><div class="col-sm-12 center">&nbsp;</div></div>
  <div class="row"><div class="col-sm-12 center">&nbsp;</div></div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary" (click)="confirmAction()" cdkFocusInitial>Ok</button>
</div>
