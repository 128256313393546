import * as fromQuickActions from "../actions/quick-action.actions";
import { QuickAction } from "gematik-shared";

export const FEATURE_KEY = "quickActions";

export interface QuickActionState {
  entities: { [id: string]: QuickAction };
  loading: boolean;
  loaded: boolean;
}

export const initialState: QuickActionState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromQuickActions.QuickActionsActions,
): QuickActionState {
  switch (action.type) {
    case fromQuickActions.LOAD_QUICK_ACTIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromQuickActions.LOAD_QUICK_ACTIONS_SUCCESS: {
      let entities: { [id: number]: QuickAction } = {};
      action.payload.forEach((e) => {
        entities = {
          ...entities,
          [e.applicationId]: e,
        };
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
    case fromQuickActions.LOAD_QUICK_ACTIONS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getQuickActionsEntities = (state: QuickActionState) => state.entities;
export const getQuickActionsLoading = (state: QuickActionState) => state.loading;
export const getQuickActionsLoaded = (state: QuickActionState) => state.loaded;
