import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";

import { TasksPageComponent } from "./tasks-page/tasks-page.component";
import { ComponentsModule } from "./components/components.module";
import { ProcessesPageComponent } from "./processes-page/processes-page.component";
import { EndpointConfigPageComponent } from "./endpoint-config-page/endpoint-config-page.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { GemComponentsModule, UtilsModule } from "gematik-form-library";
import { OauthRedirectComponent } from "./oauth-redirect/oauth-redirect.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { HelpConfigPageComponent } from "./help-config-page/help-config-page.component";
import { TemplatesPageComponent } from "./templates-page/templates-page.component";
import { EntityDataService, EntityDefinitionService, EntityMetadataMap } from "@ngrx/data";
import { sortTasksByCreatedDate } from "../models/gematik-task.model";
import { IncidentsPageComponent } from "./incidents-page/incidents-page.component";
import { LegalNoticeComponent } from "./legal-notice/legal-notice.component";
import { GematikSharedModule } from "gematik-shared";

const entityMetadata: EntityMetadataMap = {
  Endpoint: {
    entityDispatcherOptions: {
      optimisticUpdate: true,
    },
  },
  GematikTask: {
    sortComparer: sortTasksByCreatedDate,
  },
  GematikProcess: {
    sortComparer: sortTasksByCreatedDate,
  },
  GematikIncident: {},
  CrmRight: {},
};

@NgModule({
  declarations: [
    TasksPageComponent,
    ProcessesPageComponent,
    EndpointConfigPageComponent,
    NotFoundPageComponent,
    OauthRedirectComponent,
    AccessDeniedComponent,
    HelpConfigPageComponent,
    TemplatesPageComponent,
    IncidentsPageComponent,
    LegalNoticeComponent,
  ],
  imports: [CommonModule, ComponentsModule, GemComponentsModule, UtilsModule, GematikSharedModule],
  providers: [DatePipe],
})
export class PagesModule {
  constructor(
    private entityDefinitionService: EntityDefinitionService,
    private entityDataService: EntityDataService,
  ) {
    entityDefinitionService.registerMetadataMap(entityMetadata);
  }
}
