import * as fromTasks from "../actions/task.actions";
import { GematikTaskTotals } from "../../models/gematik-task-totals.model";

export interface TaskTotalsState {
  totals: GematikTaskTotals;
  loading: boolean;
  loaded: boolean;
}

export const initialState: TaskTotalsState = {
  totals: {
    open: {
      my: 0,
      group: 0,
    },
    due: {
      my: 0,
      group: 0,
    },
    completed: {
      my: 0,
      group: 0,
    },
  },
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromTasks.TaskActions): TaskTotalsState {
  switch (action.type) {
    case fromTasks.LOAD_TASK_TOTALS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromTasks.LOAD_TASK_TOTALS_SUCCESS: {
      const totals = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        totals,
      };
    }
    case fromTasks.LOAD_TASK_TOTALS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getTaskTotals = (state: TaskTotalsState) => state.totals;
export const getTaskTotalsLoading = (state: TaskTotalsState) => state.loading;
export const getTaskTotalsLoaded = (state: TaskTotalsState) => state.loaded;
