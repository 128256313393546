<!-- <mat-card class="sticky"> -->
<mat-card appearance="outlined" style="margin-top: 15px">
  <mat-card-content>
    <form [formGroup]="entityForm">
      <div class="row">
        <div class="col-sm-12">
          <gem-input [placeholder]="'name'" [control]="entityForm.get('name')"></gem-input>
          <gem-textarea
            [placeholder]="'content'"
            [maxChars]="100000"
            [control]="entityForm.get('content')"
          ></gem-textarea>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="createEditEntity()">
      {{ (entityForm.get("name").value ? "save" : "ADD") | translate }}
    </button>
  </mat-card-footer>
</mat-card>
