import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { GematikProfile } from "../../models/gematik-profile.model";
import {
  SuiteCrmAssignmentGroupIdentity,
  SuiteCrmPartnerAddress,
  SuiteCrmPartnerAddressMail,
  SuiteCrmPartnerAddressPhone,
} from "gematik-shared";

@Injectable({
  providedIn: "root",
})
export class PersonalProfileService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getProfile(): Observable<GematikProfile> {
    return this.http.get<GematikProfile>(`${this.env.backendUrl}/portal/profile/personal`);
  }

  updateProfile(payload: GematikProfile): Observable<GematikProfile> {
    return this.http.post<GematikProfile>(
      `${this.env.backendUrl}/portal/profile/personal`,
      payload,
    );
  }

  getAssignmentGroupIdentities(): Observable<SuiteCrmAssignmentGroupIdentity[]> {
    return this.http.get<SuiteCrmAssignmentGroupIdentity[]>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities`,
    );
  }

  getAssignmentGroupIdentityAddresses(agiId: string): Observable<SuiteCrmPartnerAddress[]> {
    return this.http.get<SuiteCrmPartnerAddress[]>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses`,
    );
  }

  createAssignmentGroupIdentityAddress(
    agiId: string,
    payload: SuiteCrmPartnerAddress,
  ): Observable<SuiteCrmPartnerAddress> {
    return this.http.post<SuiteCrmPartnerAddress>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses`,
      payload,
    );
  }

  updateAssignmentGroupIdentityAddress(
    agiId: string,
    payload: SuiteCrmPartnerAddress,
  ): Observable<SuiteCrmPartnerAddress> {
    return this.http.put<SuiteCrmPartnerAddress>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses/${payload.id}`,
      payload,
    );
  }

  deleteAssignmentGroupIdentityAddress(agiId: string, addressId: string): Observable<any> {
    return this.http.delete(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses/${addressId}`,
    );
  }

  createAssignmentGroupIdentityAddressMail(
    agiId: string,
    addressId: string,
    payload: SuiteCrmPartnerAddressMail,
  ): Observable<SuiteCrmPartnerAddressMail> {
    return this.http.post<SuiteCrmPartnerAddressMail>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses/${addressId}/mails`,
      payload,
    );
  }

  updateAssignmentGroupIdentityAddressMail(
    orgId: string,
    addressId: string,
    mailId: string,
    payload: SuiteCrmPartnerAddressMail,
  ): Observable<SuiteCrmPartnerAddressMail> {
    return this.http.put<SuiteCrmPartnerAddressMail>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${orgId}/addresses/${addressId}/mails/${mailId}`,
      payload,
    );
  }

  deleteAssignmentGroupIdentityAddressMail(
    orgId: string,
    addressId: string,
    mailId: string,
  ): Observable<any> {
    return this.http.delete(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${orgId}/addresses/${addressId}/mails/${mailId}`,
    );
  }

  createAssignmentGroupIdentityAddressPhone(
    agiId: string,
    addressId: string,
    payload: SuiteCrmPartnerAddressPhone,
  ): Observable<SuiteCrmPartnerAddressPhone> {
    return this.http.post<SuiteCrmPartnerAddressPhone>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses/${addressId}/phones`,
      payload,
    );
  }

  updateAssignmentGroupIdentityAddressPhone(
    agiId: string,
    addressId: string,
    phoneId: string,
    payload: SuiteCrmPartnerAddressPhone,
  ): Observable<SuiteCrmPartnerAddressPhone> {
    return this.http.put<SuiteCrmPartnerAddressPhone>(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses/${addressId}/phones/${phoneId}`,
      payload,
    );
  }

  deleteAssignmentGroupIdentityAddressPhone(
    agiId: string,
    addressId: string,
    phoneId: string,
  ): Observable<any> {
    return this.http.delete(
      `${this.env.backendUrl}/portal/profile/personal/assignment-group-identities/${agiId}/addresses/${addressId}/phones/${phoneId}`,
    );
  }
}
