import { Action } from "@ngrx/store";
import { GematikIncident } from "../../models/gematik-incident.model";

// Load All Incidents
export const LOAD_ALL_INCIDENTS = "[Incidents] Load All Incidents";
export const LOAD_ALL_INCIDENTS_SUCCESS = "[Incidents] Load All Incidents Success";
export const LOAD_ALL_INCIDENTS_FAIL = "[Incidents] Load All Incidents Fail";

export class LoadAllIncidents implements Action {
  readonly type = LOAD_ALL_INCIDENTS;
}

export class LoadAllIncidentsSuccess implements Action {
  readonly type = LOAD_ALL_INCIDENTS_SUCCESS;
  constructor(public payload: GematikIncident[]) {}
}

export class LoadAllIncidentsFail implements Action {
  readonly type = LOAD_ALL_INCIDENTS_FAIL;
  constructor(public payload: any) {}
}

// Load Specific Incidents
export const LOAD_INCIDENTS = "[Incidents] Load Incidents";
export const LOAD_INCIDENTS_SUCCESS = "[Incidents] Load Incidents Success";
export const LOAD_INCIDENTS_FAIL = "[Incidents] Load Incidents Fail";

export class LoadIncidents implements Action {
  readonly type = LOAD_INCIDENTS;
  constructor(public payload: string) {}
}

export class LoadIncidentsSuccess implements Action {
  readonly type = LOAD_INCIDENTS_SUCCESS;
  constructor(public payload: GematikIncident[]) {}
}

export class LoadIncidentsFail implements Action {
  readonly type = LOAD_INCIDENTS_FAIL;
  constructor(public payload: any) {}
}

// Retry Failed Incident
export const RETRY_FAILED_INCIDENT = "[Incidents] Retry Failed Incident";
export const RETRY_FAILED_INCIDENT_SUCCESS = "[Incidents] Retry Failed Incident Success";
export const RETRY_FAILED_INCIDENT_FAIL = "[Incidents] Retry Failed Incident Fail";

export class RetryFailedIncident {
  readonly type = RETRY_FAILED_INCIDENT;
  constructor(public payload: GematikIncident) {}
}

export class RetryFailedIncidentSuccess {
  readonly type = RETRY_FAILED_INCIDENT_SUCCESS;
}

export class RetryFailedIncidentFail {
  readonly type = RETRY_FAILED_INCIDENT_FAIL;
  constructor(public payload: any) {}
}

export type IncidentActions =
  | LoadAllIncidents
  | LoadAllIncidentsSuccess
  | LoadAllIncidentsFail
  | LoadIncidents
  | LoadIncidentsSuccess
  | LoadIncidentsFail
  | RetryFailedIncident
  | RetryFailedIncidentSuccess
  | RetryFailedIncidentFail;
