import * as fromHelp from "../actions/help.actions";
import { Help } from "../../services/uwl/help.service";

export const FEATURE_KEY = "help";

export interface HelpState {
  entities: { [id: number]: Help };
  loading: boolean;
  loaded: boolean;
}

export const initialState: HelpState = {
  entities: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: fromHelp.HelpActions): HelpState {
  switch (action.type) {
    case fromHelp.DELETE_HELP:
    case fromHelp.UPDATE_HELP:
    case fromHelp.CREATE_HELP:
    case fromHelp.LOAD_HELP: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromHelp.LOAD_HELP_SUCCESS: {
      const help = action.payload;
      const entities = help.reduce(
        (entities: { [id: number]: Help }, help: Help) => {
          return {
            ...entities,
            [help.id]: help,
          };
        },
        {
          ...state.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromHelp.UPDATE_HELP_SUCCESS:
    case fromHelp.CREATE_HELP_SUCCESS: {
      const help = action.payload;
      const entities = {
        ...state.entities,
        [help.id]: help,
      };
      return {
        ...state,
        entities,
        loading: false,
        loaded: true,
      };
    }

    case fromHelp.DELETE_HELP_SUCCESS: {
      const help = action.payload;
      const { [help.id]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
      };
    }

    case fromHelp.DELETE_HELP_FAIL:
    case fromHelp.UPDATE_HELP_FAIL:
    case fromHelp.CREATE_HELP_FAIL:
    case fromHelp.LOAD_HELP_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}

export const getHelpEntities = (state: HelpState) => state.entities;
export const getHelpLoading = (state: HelpState) => state.loading;
export const getHelpLoaded = (state: HelpState) => state.loaded;
