import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { QuickAction } from "gematik-shared";

@Injectable({
  providedIn: "root",
})
export class QuickActionsService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getQuickActions(): Observable<QuickAction[]> {
    return this.http.get<QuickAction[]>(this.env.backendUrl + "/quick-actions/my");
  }
}
