import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { MyService } from "../../models/my-service.model";

@Injectable({
  providedIn: "root",
})
export class MyServicesService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getMyServices(): Observable<MyService[]> {
    return this.http.get<MyService[]>(this.env.backendUrl + "/my-services");
  }

  updateMyServices(payload): Observable<MyService[]> {
    return this.http.post<MyService[]>(`${this.env.backendUrl}/my-services`, payload);
  }
}
