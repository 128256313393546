import { Component, Input, OnInit } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "side-navigation-item",
  styleUrls: ["side-navigation-item.component.scss"],
  animations: [
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(0)" })),
      state("rotated", style({ transform: "rotate(90deg)" })),
      transition("rotated => default", animate("300ms ease-out")),
      transition("default => rotated", animate("300ms ease-in")),
    ]),
    trigger("openClose", [
      state("true", style({ height: "*", opacity: 1 })),
      state("false", style({ height: "0px", opacity: 0 })),
      transition("false <=> true", animate(300)),
    ]),
  ],
  template: `<ul>
    <li>
      <div class="d-flex align-items-center">
        <mat-icon
          class="nav-icons"
          style="color: var(--gematik-blue)"
          svgIcon="{{ treeContent.icon }}"
          [routerLink]="treeContent.route"
          [routerLinkActive]="'active-icon'"
        ></mat-icon>
        <a
          data-testid="parentLink"
          (click)="toggleTreeElement()"
          [routerLink]="treeContent.route"
          class="gem-link-blue"
          [routerLinkActive]="'active'"
        >
          <span *ngIf="isExpanded" class="nav-main-item-label">{{
            treeContent.rootLabel | translate
          }}</span>
        </a>
      </div>
      <ul
        *ngIf="isExpanded"
        [@openClose]="treeElementOpened ? true : false"
        class="open-close-container"
      >
        <li *ngFor="let link of treeContent.links" class="nav-line-item">
          <a *ngIf="link.route" [routerLink]="link.route" [routerLinkActive]="'active'">
            <div class="d-flex">
              <div class="flex-grow-1 gem-color-blue">{{ link.label | translate }}</div>
              <div
                *ngIf="link.badge && (link.badge | async) >= 0"
                class="gem-badge"
                [class.gem-color-dark-grey]="(link.badge | async) === 0"
              >
                {{ link.badge | async }}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>`,
})
export class SideNavigationItemComponent implements OnInit {
  @Input() treeContent: {
    rootLabel: string;
    icon: string;
    route: string;
    links: [{ label: string; route: string; badge: string }];
    treeElementOpened: boolean;
  };
  @Input() isExpanded: boolean;

  treeElementOpened: boolean;
  state = "rotated";

  constructor() {}

  ngOnInit(): void {
    this.treeElementOpened = this.treeContent.treeElementOpened;
  }

  toggleTreeElement(): void {
    this.treeElementOpened = !this.treeElementOpened;
    if (this.treeElementOpened) {
      this.state = "rotated";
    } else {
      this.state = "default";
    }
  }
}
