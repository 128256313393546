import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromIncidents from "../reducers/incident.reducer";

export const getIncidentsState = createFeatureSelector<fromIncidents.IncidentState>(
  fromIncidents.FEATURE_KEY,
);

export const getIncidentEntities = createSelector(
  getIncidentsState,
  fromIncidents.getIncidentEntities,
);

export const getIncidentCount = createSelector(
  getIncidentEntities,
  (entities) => Object.keys(entities).length,
);

export const getIncidents = createSelector(getIncidentEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
