import { Action } from "@ngrx/store";

export const ROUTER_NAVIGATE = "[Router] Navigate";

export class RouterNavigate implements Action {
  readonly type = ROUTER_NAVIGATE;
  constructor(public url: string) {}
}

export type RouterActions = RouterNavigate;
