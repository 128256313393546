import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormHelperService, GemValidators } from "gematik-form-library";
import { SuiteCrmPartnerAddressMail } from "gematik-shared";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "communication-address-email-form",
  styleUrls: ["communication-address-email-form.component.scss"],
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>
            <div class="d-flex">
              <mat-icon class="nav-icons me-1" svgIcon="gem-report"></mat-icon>
              <strong> Email </strong>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="mt-2"> </mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content class="mt-4">
        <div class="row">
          <form [formGroup]="form">
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.emails.form.email.label'
                "
                [control]="email"
              ></gem-input>
            </div>
            <div class="col-sm-12">
              <gem-input
                [placeholder]="
                  'portal.profile.organisational.organisationAddress.emails.form.description.label'
                "
                [control]="description"
              ></gem-input>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-end mt-2">
              <button data-testid="save" class="btn btn-primary" color="primary" (click)="onSave()">
                {{ "portal.profile.buttons.save" | translate }}
              </button>
              <button
                data-testid="cancel"
                class="btn btn-secondary"
                color="primary"
                (click)="onCancel()"
              >
                {{ "portal.profile.buttons.cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class CommunicationAddressEmailFormComponent implements OnInit {
  @Input() addressEmail: SuiteCrmPartnerAddressMail;
  @Output() save: EventEmitter<SuiteCrmPartnerAddressMail> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  form: FormGroup = this.fb.group({
    id: [uuidv4()],
    email: [null, [Validators.required, GemValidators.email]],
    description: [],
  });

  constructor(private fb: FormBuilder, private formHelper: FormHelperService) {}

  ngOnInit(): void {
    this.form.patchValue(this.addressEmail);
  }

  onSave(): void {
    this.formHelper.isFormValidElseShowErrors(
      this.form,
      "Form Validation",
      () => {
        this.save.emit(this.form.getRawValue());
      },
      true,
      true,
      "portal.profile.organisational.organisationAddress.emails.form.",
    );
  }

  onCancel(): void {
    this.cancel.emit();
  }

  get email(): FormControl {
    return this.form.get("email") as FormControl;
  }

  get description(): FormControl {
    return this.form.get("description") as FormControl;
  }
}
